import { ModalProps } from "../../ui/modal/modal.types.ts";
import { ApiFilePathTypes } from "../../types/file.types.ts";
import useModals from "../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Modal from "../../ui/modal/modal.tsx";
import Button from "../../ui/button/Button.tsx";
import Box from "../../ui/box/Box.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import { apiFiles } from "../../api/file.api.ts";
import Avatar from "../../ui/avatar/Avatar.tsx";
import { ApiFile } from "../../types/common.types.ts";
import useToasts from "../../hooks/use-toasts.hook.tsx";

export interface ImageUploadModalProps extends ModalProps {
  path: ApiFilePathTypes;
  onSave: (fileId: ApiFile) => Promise<boolean>;
  modalTitle: string;
}

export const ImageUploadModal = ({ path, onSave, modalTitle, ...props }: ImageUploadModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { handleErrors } = useErrorHandle();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [newFileUrl, setNewFileUrl] = useState<string | null>(null);
  const [file, setFile] = useState<ApiFile | null>(null);
  const { showError } = useToasts();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", path);
      formData.append("is_public", "false");
      formData.append("save_original_name", "true");

      setIsUploading(true);

      const { data, error } = await apiFiles.add(formData);

      if (data) {
        setFile(data);
        const { data: signedLink, error } = await apiFiles.getLink(data.uuid, 1);
        if (signedLink) {
          setNewFileUrl(signedLink.download_url);
        } else {
          handleErrors(error);
        }
      } else {
        handleErrors(error);
      }
      setIsUploading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSave = useCallback(async () => {
    if (!file) {
      showError(t("Select file to upload"));
      return;
    }
    setIsLoading(true);
    const success = await onSave(file);
    if (success) {
      close(id);
    }
    setIsLoading(false);
  }, [file]);

  return (
    <Modal
      {...props}
      variant={"side"}
      title={modalTitle}
      layout={"stretch"}
      withCloser={true}
      actions={
        <Stack gap={"sm"}>
          <Button size={"lg"} className={"w-full"} isLoading={isLoading} onClick={handleSave}>
            {t("Save")}
          </Button>
          <Button
            variant={"plain"}
            size={"lg"}
            onClick={() => {
              close(id);
            }}
          >
            {t("Cancel")}
          </Button>
        </Stack>
      }
    >
      <Box
        className={
          "p-8 rounded-2xl border border-dashed border-dark/30 flex flex-grow flex-col h-5/6 mb-8 justify-center"
        }
      >
        <div {...getRootProps()} className="p-8  text-center flex items-center flex-col">
          <input {...getInputProps()} />
          {newFileUrl && <Avatar url={newFileUrl} size={"2xl"} />}
          {!newFileUrl && (
            <Stack items={"center"}>
              <Button variant={"secondary"} className={"mx-auto mb-2"} isLoading={isUploading}>
                {t("Upload file")}
              </Button>
              <Box className={"text-secondary"}>
                {isDragActive
                  ? t("Drop the file here ...")
                  : t("Drag 'n' drop file here, or click to select files")}
              </Box>
            </Stack>
          )}
        </div>
      </Box>
    </Modal>
  );
};
