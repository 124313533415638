import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ApiApplicant } from "../../../../types/recruit/applicant.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { useCallback, useState } from "react";
import { ApplicantMergeProfileCompare } from "../components/ApplicantMergeProfileCompare.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import {
  useApplicantDetails,
  useApplicantsMerge,
} from "../../../../queries/recruit/use-applicants.query.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";

export interface ApplicantsMergeModalProps extends ModalProps {
  applicant: ApiApplicant;
}

export function ApplicantsMergeModal({ applicant, ...props }: ApplicantsMergeModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const [similarApplicantIndex, setSimilarApplicantIndex] = useState<number>(0);

  const { data: similarApplicant } = useApplicantDetails(
    applicant.similar_applicants[similarApplicantIndex].id
  );

  const applicantsMerge = useApplicantsMerge();

  const handleMerge = useCallback(
    (resolveType: string) => {
      if (!similarApplicant) return;

      applicantsMerge.mutate(
        {
          applicantId: applicant.id,
          similarApplicantId: similarApplicant.id,
          resolveType: resolveType,
        },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.applicants.mergeResolved, {
              [analyticProperties.id]: applicant.id,
              [analyticProperties.actionType]: resolveType,
            });
            close(id);
          },
        }
      );
    },
    [applicant, similarApplicant]
  );

  const handleChangeSimilar = useCallback(
    (newIndex: number) => {
      setSimilarApplicantIndex(newIndex);
    },
    [similarApplicantIndex, applicant]
  );

  return (
    <>
      <Modal
        {...props}
        title={t("Compare")}
        withCloser
        closeByEscEnabled
        layout={"stretch"}
        size={"xl"}
        actions={
          <Stack gap={"sm"}>
            <Button
              isLoading={applicantsMerge.isPending}
              onClick={() => handleMerge("merge")}
              size={"lg"}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-check"} />}
            >
              {t("Merge")}
            </Button>
            <Button
              isLoading={applicantsMerge.isPending}
              onClick={() => handleMerge("ignore")}
              size={"lg"}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-xmark"} />}
            >
              {t("No, these are different people")}
            </Button>
            <Button
              variant={"plain"}
              size={"lg"}
              onClick={() => {
                close(id);
              }}
            >
              {t("Cancel")}
            </Button>
          </Stack>
        }
      >
        <Stack gap={"md"} direction={"horizontal"}>
          <ApplicantMergeProfileCompare applicant={applicant} />
          {!similarApplicant && <Skeleton className="w-full h-4 basis-1/2" />}
          {similarApplicant && (
            <ApplicantMergeProfileCompare
              similarApplicants={applicant.similar_applicants}
              similarApplicantIndex={similarApplicantIndex + 1}
              applicant={similarApplicant}
              onChangeSimilarIndex={handleChangeSimilar}
            />
          )}
        </Stack>
      </Modal>
    </>
  );
}
