import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import { useMemo } from "react";
import { SidebarConfigItem } from "../sidebar-service.tsx";
import { useLocation } from "react-router-dom";
import { SidebarItem } from "./SidebarItem.tsx";
import { SidebarHeader } from "./SidebarHeader.tsx";

export const SidebarCompany = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const menuConfig = useMemo(() => {
    const items = [
      {
        title: t("Employees"),
        to: "/employees",
        isActive: false,
      },
    ] as SidebarConfigItem[];
    for (let i = 0; i < items.length; i++) {
      items[i].isActive = pathname.startsWith(items[i].to);
    }
    return items;
  }, [pathname]);

  return (
    <>
      <SidebarHeader title={t("Team")} />
      <Stack className={"px-4"}>
        <Stack gap={"xs"}>
          {menuConfig.map((item, index) => (
            <SidebarItem
              to={item.to || "/"}
              key={"sidebar-" + index}
              isActive={item.isActive}
              title={item.title}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};
