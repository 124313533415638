import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next"; // Import your translation files
import translationEN from "./locales/en/app.json";
import translationRU from "./locales/ru/app.json";
import errorsEn from "./locales/en/apiErrors.json";
import errorsRu from "./locales/ru/apiErrors.json";

// the translations
const resources = {
  en: {
    translation: { ...translationEN, ...errorsEn },
  },
  ru: {
    translation: { ...translationRU, ...errorsRu },
  },
  // Add other languages here
};

i18n
  .use(ICU) // special formats for plural and etc
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: "en", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
