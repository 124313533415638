import { api } from "../axios.ts";
import {
  ApiAnalyticsRecruitingClosingTimeReport,
  ApiAnalyticsRecruitingFilter,
  ApiAnalyticsRecruitingSourceEfficiencyReport,
  ApiAnalyticsRecruitingStageFlowFilter,
  ApiAnalyticsRecruitingStageFlowReport,
  ApiAnalyticsRecruitingStagesFlowReport,
} from "../../types/analytics/analytics.recruiting.types.ts";

const recruiting = {
  stagesFlowReport: function (filter: ApiAnalyticsRecruitingStageFlowFilter, signal?: AbortSignal) {
    return api.post<ApiAnalyticsRecruitingStagesFlowReport>(
      `/api/v1/analytics/recruiting/stages-flow-report`,
      filter,
      signal
    );
  },
  stageFlowReport: function (filter: ApiAnalyticsRecruitingFilter, signal?: AbortSignal) {
    return api.post<ApiAnalyticsRecruitingStageFlowReport>(
      `/api/v1/analytics/recruiting/stage-flow-report`,
      filter,
      signal
    );
  },
  sourceEfficiencyReport: function (filter: ApiAnalyticsRecruitingFilter, signal?: AbortSignal) {
    return api.post<ApiAnalyticsRecruitingSourceEfficiencyReport>(
      `/api/v1/analytics/recruiting/source-efficiency-report`,
      filter,
      signal
    );
  },

  closingTimeReport: (filter: ApiAnalyticsRecruitingFilter, signal?: AbortSignal) => {
    return api.post<ApiAnalyticsRecruitingClosingTimeReport>(
      `/api/v1/analytics/recruiting/closing-time-report`,
      filter,
      signal
    );
  },
};
export const apiAnalytics = {
  recruiting: recruiting,
};
