import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../../ui/table/Table.tsx";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { LabelEditModal } from "./widgets/LabelEditModal.tsx";
import { ApiLabel } from "../../../../types/recruit/label.types.ts";
import { Page } from "../../../../components/page/Page.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import ColorBlock from "../../../../ui/colorblock/ColorBlock.tsx";
import { useLabelDelete, useLabels } from "../../../../queries/use-labels.query.ts";
import { useEffectOnce } from "react-use";

export default function Labels() {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { data: labels, isLoading, isFetching } = useLabels();
  const labelDelete = useLabelDelete();

  const handleEditOrCreate = useCallback((label: ApiLabel | undefined = undefined) => {
    const labelToEdit = label || {
      id: 0,
      title: "",
      color: "",
    };

    modalsService.openModal(LabelEditModal, {
      label: labelToEdit,
    });
  }, []);

  const handleDelete = useCallback((label: ApiLabel) => {
    labelDelete.mutate(label.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.labels.deleted, {
          [analyticProperties.id]: label.id,
        });
      },
    });
  }, []);

  const columnHelper = createColumnHelper<ApiLabel>();
  const columns = useMemo<ColumnDef<ApiLabel>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => (
          <Stack direction={"horizontal"} className={"items-center"} gap={"sm"}>
            <ColorBlock color={row.row.original.color} />
            <Text className={"text-title"}>{row.row.original.title}</Text>
          </Stack>
        ),
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDelete(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [labels]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.labels.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Tags")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={labels || []}
            columns={columns}
            showSkeleton={isLoading}
            isFetching={isFetching || labelDelete.isPending}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
