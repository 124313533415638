import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useForm } from "react-hook-form";
import { EditRoleSchema, EditRoleSchemaObject } from "./role-edit.types.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import { ApiRoleSavePayload, RoleType } from "../../../../types/role.types.ts";
import { useCallback, useEffect, useMemo } from "react";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import Button from "../../../../ui/button/Button.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRoleById,
  useRolesDelete,
  useRolesSave,
} from "../../../../queries/settings/use-roles.query.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { getRoleDescription, getRoleTitle, mockRole } from "../role.types.ts";
import { RoleEditTabs } from "./components/RoleEditTabs.tsx";
import { RoleEditAssignToSection } from "./components/RoleEditAssignToSection.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";

export function RolesEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const modalsService = useModals();
  const navigate = useNavigate();
  const formHook = useForm<EditRoleSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EditRoleSchemaObject),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = formHook;

  const { data: role, isLoading } = useRoleById(id ? parseInt(id, 10) : undefined);

  useEffect(() => {
    if (role) {
      const apiPermissions = { ...mockRole.api_permissions, ...role.api_permissions };
      const employeePermission = { ...mockRole.employee_permissions, ...role.employee_permissions };

      if (role.type != RoleType.custom) {
        role.title = getRoleTitle(role);
        role.description = getRoleDescription(role);
      }

      reset({
        ...role,
        api_permissions: apiPermissions,
        employee_permissions: employeePermission,
      });

      return;
    }

    reset(mockRole);
  }, [role]);

  const handleDelete = useCallback(() => {
    if (!role) return;
    if (roleSave.isPending) return;

    modalsService.openModal(ConfirmationPopup, {
      question: t("Delete"),
      text: t("Are you sure you want to delete?"),
      acceptTitle: t("Delete"),
      onAccept: async function () {
        try {
          await roleDelete.mutateAsync(role.id);

          analyticsService.trackEvent(analyticEvents.roles.deleted, {
            [analyticProperties.id]: role.id,
          });

          navigate("/settings/roles-and-permissions");
        } catch {
          return;
        }
      },
    });
  }, [role]);

  const handleCancel = () => {
    navigate("/settings/roles-and-permissions");
  };

  const onSubmit = handleSubmit((roleData) => {
    if (roleSave.isPending) return;

    const payload: ApiRoleSavePayload = {
      api_permissions: roleData.api_permissions,
      assign_to_list: roleData.assign_to_list,
      apply_to_list: roleData.apply_to_list,
      employee_permissions: roleData.employee_permissions,
      title: roleData.title,
      description: roleData.description,
    };

    roleSave.mutate(
      { id: role?.id || 0, payload },
      {
        onSuccess(createdRole) {
          if (role) {
            analyticsService.trackEvent(analyticEvents.roles.edited, {
              [analyticProperties.id]: createdRole.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.roles.created, {
              [analyticProperties.id]: createdRole.id,
            });
          }

          navigate("/settings/roles-and-permissions");
        },
      }
    );
  });

  const isCustom = useMemo(() => {
    return role && role.type == RoleType.custom;
  }, [role]);

  const roleSave = useRolesSave(control);
  const roleDelete = useRolesDelete();

  console.log(errors);

  return (
    <Page>
      <Page.Header showBack={true} title={t("Role")} />
      <Page.Content>
        {isLoading ? (
          <Stack gap="sm" className={"w-full"}>
            <Skeleton className={"w-1/2 h-6"}></Skeleton>
            <Skeleton className={"w-full h-5"}></Skeleton>
          </Stack>
        ) : (
          <form onSubmit={onSubmit}>
            <Stack className={"px-16"} direction={"vertical"}>
              <Stack>
                <Title header={t("Describe role")} size={"lg"} paddingBottom paddingTop />
                <Input
                  label={t("Title")}
                  required={true}
                  type={"text"}
                  {...register("title", {
                    value: role?.title ?? "",
                  })}
                  readOnly={role?.type != RoleType.custom && role != undefined}
                  error={errors.title?.message}
                />
                <Textarea
                  label={t("Description")}
                  {...register("description", {
                    value: role?.description ?? "",
                  })}
                  readOnly={role?.type != RoleType.custom && role != undefined}
                />
              </Stack>

              {[RoleType.admin, RoleType.custom].indexOf(role?.type || RoleType.custom) !== -1 && (
                <RoleEditAssignToSection formHook={formHook} role={role} />
              )}

              {[RoleType.admin].indexOf(role?.type || RoleType.custom) === -1 && (
                <RoleEditTabs role={role} formHook={formHook} />
              )}

              <Stack className={"pt-md"} gap={"md"}>
                <ServerErrorField errors={errors} />
                <Button
                  size={"lg"}
                  type={"submit"}
                  onClick={onSubmit}
                  isLoading={roleSave.isPending}
                >
                  {t("Save")}
                </Button>
                <Button
                  variant={isCustom ? "danger" : "plain"}
                  size={"lg"}
                  leftIcon={isCustom ? <FontAwesomeIcon icon={"fa-light fa-xmark"} /> : undefined}
                  onClick={() => {
                    isCustom ? handleDelete() : handleCancel();
                  }}
                >
                  {isCustom ? t("Delete") : t("Cancel")}
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Page.Content>
    </Page>
  );
}
