import { ThemeSizes } from "../../ui/themes/theme.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import { AvatarSizes } from "../../ui/avatar/avatar.types.ts";
import { twMerge } from "tailwind-merge";
import { ReactElement } from "react";
import Avatar from "../../ui/avatar/Avatar.tsx";
import Box from "../../ui/box/Box.tsx";

interface UserCardThemeType {
  cover: string;
  title: string;
  caption: string;
  extra: string;
  ellipsis: string;
  variants: {
    sizes: {
      cover: {
        [key in keyof UserCardSizes]?: string;
      };
      title: {
        [key in keyof UserCardSizes]?: string;
      };
    };
  };
}

const userCardTheme: UserCardThemeType = {
  cover: "",
  title: "text-dark/95 font-semibold text-lg px-2",
  caption: "text-md text-dark/60 px-2",
  extra: "text-md text-dark/60 px-2",
  ellipsis: "text-ellipsis overflow-hidden whitespace-nowrap w-40",
  variants: {
    sizes: {
      cover: {
        sm: "text-center items-center",
        md: "w-40",
        lg: "w-57",
      },
      title: {
        sm: "text-md leading-5",
        md: "leading-6",
        lg: "leading-6",
      },
    },
  },
};

export interface UserCardProps {
  title: string;
  caption?: string;
  extra?: string;
  avatarElement?: ReactElement;
  avatarUrl?: string;
  textEllipsis?: boolean;
  className?: string;
  size?: keyof UserCardSizes;
}

export interface UserCardSizes extends Pick<ThemeSizes, "sm" | "md" | "lg"> {
  [key: string]: string;
}

export function UserCard({
  title,
  caption,
  extra,
  avatarElement,
  avatarUrl,
  textEllipsis = false,
  size = "md",
  className,
}: UserCardProps) {
  const avatarMapping: Record<keyof UserCardSizes, keyof AvatarSizes> = {
    sm: "xl",
    md: "2xl",
    lg: "4xl",
  };

  return (
    <Stack
      gap={"sm"}
      direction={"vertical"}
      className={twMerge(userCardTheme.variants.sizes.cover[size], className)}
    >
      {avatarElement ? (
        avatarElement
      ) : (
        <Avatar url={avatarUrl} size={avatarMapping[size]} acronym={title} />
      )}
      <Box>
        <div
          className={twMerge(
            "user-title",
            textEllipsis && userCardTheme.ellipsis,
            userCardTheme.title,
            userCardTheme.variants.sizes.title[size]
          )}
        >
          {title}
        </div>
        {caption && <div className={twMerge(userCardTheme.caption)}>{caption}</div>}
        {extra && <div className={twMerge(userCardTheme.extra)}>{extra}</div>}
      </Box>
    </Stack>
  );
}
