import Modal from "../../../../ui/modal/modal.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { useTranslation } from "react-i18next";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import Select from "../../../../ui/select/Select.tsx";
import {
  ApiApplicantContacts,
  ApiApplicantCreatePayload,
  ApiApplicantPosition,
  ApiApplicantSavePayload,
  ApiApplicantShort,
} from "../../../../types/recruit/applicant.types.ts";
import { pickBy } from "lodash";
import { apiApplicants } from "../../../../api/recruit/applicant.api.ts";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import { useDropzone } from "react-dropzone";
import { ApiFile } from "../../../../types/common.types.ts";
import { Input } from "../../../../ui/input/Input.tsx";
import Box from "../../../../ui/box/Box.tsx";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useErrorHandle, { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import { useSources } from "../../../../queries/use-sources.query.ts";
import {
  useApplicantCreate,
  useApplicantUpdate,
} from "../../../../queries/recruit/use-applicants.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { FileItem } from "../../../../components/file-item/FileItem.tsx";
import { Title } from "../../../../components/title/Title.tsx";

export interface ApplicantEditModalProps extends ModalProps {
  applicant: ApiApplicantShort;
  onSave?: (updatedApplicant: ApiApplicantShort) => void;
}

export const ApplicantEditModal = ({ applicant, onSave, ...props }: ApplicantEditModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { handleErrors } = useErrorHandle();

  const { data: sources, isLoading: isSourceLoading } = useSources();

  const [parsedApplicant, setParsedApplicant] = useState<ApiApplicantShort | null>(null);
  const [files, setFiles] = useState<ApiFile[]>([]);

  const ApplicantSchemaObject = yup.object({
    last_name: yup.string(),
    first_name: yup.string().required(i18n.t("Field is required")),
    middle_name: yup.string(),
    location: yup.string(),
    phone: yup.string(),
    email: yup.string().email(i18n.t("Field should be valid a email")),
    skype_username: yup.string(),
    telegram_username: yup.string(),

    position: yup.string(),
    company: yup.string(),

    salary: yup.string(),
    birth_date: yup.date(),
    source_id: yup.number(),

    resume: yup.string(),

    // костыль, баба яга против
    photo_file_uuid: yup.string(),
  });

  interface ApplicantSchema extends yup.InferType<typeof ApplicantSchemaObject> {}

  useEffect(() => {
    if (applicant.id == 0) {
      sources?.forEach((source) => {
        source.source_type === "manual" && setValue("source_id", source.id);
      });
    }
  }, [sources]);

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplicantSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ApplicantSchemaObject),
    defaultValues: {
      first_name: applicant.first_name,
      last_name: applicant.last_name || undefined,
      middle_name: applicant.middle_name || undefined,
      location: applicant.location || undefined,
      phone: applicant.contacts.phone || undefined,
      email: applicant.contacts.email || undefined,
      skype_username: applicant.contacts.skype_username || undefined,
      telegram_username: applicant.contacts.telegram_username || undefined,

      position: applicant.current_position.position || undefined,
      company: applicant.current_position.company || undefined,

      salary: applicant.salary || undefined,
      birth_date:
        applicant.birth_date &&
        applicant.birth_date.year &&
        applicant.birth_date.month &&
        applicant.birth_date.day
          ? new Date(
              applicant.birth_date.year,
              applicant.birth_date.month,
              applicant.birth_date.day
            )
          : undefined,
      source_id: applicant.source_id,

      resume: applicant.resume || undefined,
    },
  });

  const applicantCreate = useApplicantCreate(control);
  const applicantUpdate = useApplicantUpdate(control);

  const onSubmit = handleSubmit(async (schema) => {
    let candidatePayload: ApiApplicantSavePayload = {
      first_name: schema.first_name,
      last_name: schema.last_name,
      middle_name: schema.middle_name,
      location: schema.location,
      contacts: pickBy({
        phone: schema.phone,
        email: schema.email,
        telegram_username: schema.telegram_username,
        skype_username: schema.skype_username,
      }) as ApiApplicantContacts,
      current_position: pickBy({
        position: schema.position,
        company: schema.company,
      }) as ApiApplicantPosition,
      salary: schema.salary,
      birth_date: {
        year: schema.birth_date?.getFullYear() || null,
        month: schema.birth_date?.getMonth() || null,
        day: schema.birth_date?.getDay() || null,
      },
      source_id: schema.source_id,
      resume: schema.resume,
    };

    if (parsedApplicant) {
      candidatePayload = {
        ...parsedApplicant,
        ...candidatePayload,
      };
    } else {
      candidatePayload = pickBy(candidatePayload) as ApiApplicantSavePayload;
    }
    const savePayload: ApiApplicantCreatePayload = {
      new_applicant: candidatePayload,
      file_ids: files.map((file: ApiFile) => file.id),
    };

    if (applicant.id == 0) {
      try {
        const savedApplicant = await applicantCreate.mutateAsync(savePayload);
        analyticsService.trackEvent(analyticEvents.applicants.created, {
          [analyticProperties.id]: applicant.id,
          [analyticProperties.source]: "App",
        });
        onSave?.(savedApplicant);
        close(id);
      } catch {
        return;
      }
    } else {
      try {
        const savedApplicant = await applicantUpdate.mutateAsync({
          id: applicant.id,
          payload: candidatePayload,
        });
        analyticsService.trackEvent(analyticEvents.applicants.edited, {
          [analyticProperties.id]: applicant.id,
        });
        onSave?.(savedApplicant);
        close(id);
      } catch {
        return;
      }
    }
  });

  const [isFileUploading, setIsFileUploading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        setIsFileUploading(true);
        const { data, error } = await apiApplicants.uploadFile(formData);
        setIsFileUploading(false);
        if (data) {
          setFiles([...files, ...data.files]);
          setValue("first_name", data.applicant.first_name);
          setValue("last_name", data.applicant.last_name);
          setValue("middle_name", data.applicant.middle_name);
          setValue("location", data.applicant.location);
          setValue(
            "birth_date",
            data.applicant.birth_date &&
              data.applicant.birth_date.year &&
              data.applicant.birth_date.month &&
              data.applicant.birth_date.day
              ? new Date(
                  data.applicant.birth_date.year,
                  data.applicant.birth_date.month,
                  data.applicant.birth_date.day
                )
              : undefined
          );
          setValue("email", data.applicant.contacts.email);
          setValue("phone", data.applicant.contacts.phone);
          setValue("telegram_username", data.applicant.contacts.telegram_username);
          setValue("skype_username", data.applicant.contacts.skype_username);

          setValue("position", data.applicant.current_position.position);
          setValue("company", data.applicant.current_position.company);
          setValue("salary", data.applicant.salary);
          // setValue("resume", data.applicant.resume);

          setParsedApplicant(data.applicant);
        } else {
          handleErrors(error);
        }
      }
    },
    [applicant]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Modal
      {...props}
      variant={"side"}
      title={t("Candidate")}
      layout={"stretch"}
      withCloser={true}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />

          <Button
            size={"lg"}
            className={"w-full"}
            type={"submit"}
            isLoading={applicantCreate.isPending || applicantUpdate.isPending}
            onClick={onSubmit}
          >
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Title header={t("Personal Information")} size={"xs"} paddingBottom paddingTop />
        <Input
          label={t("Last name")}
          type={"text"}
          {...register("last_name", {
            value: undefined,
          })}
          error={errors.last_name?.message}
        />
        <Input
          label={t("First name")}
          type={"text"}
          {...register("first_name")}
          required
          error={errors.first_name?.message}
        />

        <Input
          label={t("Middle name")}
          type={"text"}
          {...register("middle_name", {
            value: undefined,
          })}
          error={errors.middle_name?.message}
        />

        <Input
          label={t("Location")}
          type={"text"}
          {...register("location", {
            value: undefined,
          })}
          error={errors.location?.message}
        />

        <Controller
          render={({ field: { value, onChange } }) => (
            <Datepicker
              value={value}
              onChange={onChange}
              label={t("Birth date")}
              error={errors.birth_date?.message}
            />
          )}
          control={control}
          name={"birth_date"}
        />

        <Title header={t("Contact Information")} size={"xs"} paddingBottom paddingTop />

        <Input
          label={t("Phone")}
          type={"text"}
          {...register("phone", {
            value: undefined,
          })}
          error={errors.phone?.message}
        />
        <Input
          label={t("Email")}
          type={"email"}
          {...register("email", {
            value: undefined,
          })}
          error={errors.email?.message}
        />
        <Input
          label={t("Telegram")}
          type={"text"}
          {...register("telegram_username", {
            value: undefined,
          })}
          error={errors.telegram_username?.message}
        />
        <Input
          label={t("Skype")}
          type={"text"}
          {...register("skype_username", {
            value: undefined,
          })}
          error={errors.skype_username?.message}
        />

        <Title header={t("Work Experience")} size={"xs"} paddingBottom paddingTop />
        <Input
          label={t("Position")}
          type={"text"}
          {...register("position", {
            value: undefined,
          })}
          error={errors.position?.message}
        />
        <Input
          label={t("Company")}
          type={"text"}
          {...register("company", {
            value: undefined,
          })}
          error={errors.company?.message}
        />

        <Input
          label={t("Salary")}
          type={"text"}
          {...register("salary", {
            value: undefined,
          })}
          error={errors.salary?.message}
        />

        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              label={t("Source")}
              isLoading={isSourceLoading}
              options={sources}
              emptyTitle={t("Select")}
              value={value}
              onChange={onChange}
              required={true}
              error={errors.source_id?.message}
            />
          )}
          control={control}
          name={"source_id"}
        />
        <Textarea
          label={t("Notes")}
          placeholder={t("Add notes")}
          {...register("resume", {
            value: undefined,
          })}
        />

        {applicant.id == 0 && (
          <>
            <div {...getRootProps()} className={"p-8 border-b border-brand-dark/10 text-center"}>
              <input {...getInputProps()} />
              <Button variant={"secondary"} className={"mx-auto mb-2"} isLoading={isFileUploading}>
                {t("Upload file")}
              </Button>
              <Box className={"text-secondary text-sm"}>
                {isDragActive
                  ? t("Drop the file here ...")
                  : t("Drag 'n' drop file here, or click to select files")}
              </Box>
            </div>
            {files.length > 0 && (
              <Stack className={"mt-md"}>
                {files.map((file, index) => (
                  <Box className={"flex"} key={`applicant-file-${index}`}>
                    <FileItem file={file} />
                  </Box>
                ))}
              </Stack>
            )}
          </>
        )}
      </form>
    </Modal>
  );
};
