import { UIKitThemeBase } from "../themes/theme.types.ts";
import { ComponentProps } from "react";

export interface UIKitDatepickerTheme extends UIKitThemeBase {
  readonly: string;
  picker: string;
}

export const datepickerTheme: UIKitDatepickerTheme = {
  base: "h-14 flex shrink-0",
  picker: "flex w-full",
  readonly: "text-secondary",
  // size: {
  //   md: "text-base h-8",
  // },
};
type onChangeType = (date: Date | null) => void;

export interface DatePickerProps extends Omit<ComponentProps<"input">, "value" | "onChange"> {
  value: Date | null | undefined;
  onChange?: onChangeType;
  format?: string;
  readOnly?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
}
