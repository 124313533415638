import { SelectItem, SelectProps, SelectValueType } from "./select.types.ts";
import Multiselect from "./Multiselect.tsx";

// https://codesandbox.io/p/sandbox/gallant-sea-rcg43b?file=%2Fsrc%2FApp.tsx
// https://codesandbox.io/p/sandbox/floating-ui-react-select-with-composable-children-qsuw76?file=%2Fsrc%2FApp.tsx%3A14%2C1-15%2C1
// https://codesandbox.io/p/sandbox/shy-snowflake-kp6479?file=%2Fsrc%2FSelect.tsx%3A41%2C1-43%2C1

export default function Select<T extends SelectItem>({
  options,
  emptyTitle,
  onChange,
  value,
  isLoading,
  searchable,
  loadOptionsOnOpen,
  readOnly,
  disabled,
  label,
  required,
  variant,
  searchOptions,
  onSearchOptions,
  error,
  isClearable,
}: SelectProps<T>) {
  const handleSelect = (value: SelectValueType[]) => {
    onChange?.(value.length > 0 ? value[0] : undefined);
  };

  return (
    <Multiselect
      isMulti={false}
      options={options}
      emptyTitle={emptyTitle}
      values={value ? [value] : []}
      isLoading={isLoading}
      loadOptionsOnOpen={loadOptionsOnOpen}
      searchable={searchable}
      onChange={handleSelect}
      disabled={disabled}
      readOnly={readOnly}
      label={label}
      required={required}
      variant={variant}
      searchOptions={searchOptions}
      onSearchOptions={onSearchOptions}
      error={error}
      isClearable={isClearable}
    />
  );
}
