import Stack from "../../ui/stack/Stack.tsx";
import { Textarea } from "../../ui/textarea/Textarea.tsx";
import { FileItem } from "../file-item/FileItem.tsx";
import Box from "../../ui/box/Box.tsx";
import { FileUpload } from "../file-upload/FileUpload.tsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiEntityTypes, ApiFile } from "../../types/common.types.ts";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { apiFiles } from "../../api/file.api.ts";

export interface CommentInputProps {
  comment?: string;
  files?: ApiFile[];
  onChangeComment: (comment: string) => void;
  onChangeFiles: (files: ApiFile[]) => void;
  className?: string;
}

export const CommentInput = ({
  comment = "",
  files = [],
  onChangeComment,
  onChangeFiles,
  className,
}: CommentInputProps) => {
  const { t } = useTranslation();
  const [text, setText] = useState(comment);
  const [filesInner, setFilesInner] = useState<ApiFile[]>(files);
  const { handleErrors } = useErrorHandle();

  async function removeFile(file: ApiFile) {
    setFilesInner(filesInner.filter((f) => f.id !== file.id));
    onChangeFiles(filesInner.filter((f) => f.id !== file.id));

    const { error } = await apiFiles.delete(file.uuid);
    if (error) handleErrors(error);
  }

  return (
    <Stack gap={"sm"} className={className}>
      <Textarea
        className={"w-full"}
        onChange={(e) => {
          setText(e.target.value);
          onChangeComment(e.target.value);
        }}
        value={text}
        label={t("Add comment")}
      />
      {filesInner.length > 0 && (
        <Stack gap={"sm"}>
          {filesInner.map((f) => (
            <FileItem
              key={`file-${f.id}`}
              file={f}
              onRemove={() => {
                removeFile(f);
              }}
            />
          ))}
        </Stack>
      )}
      <Stack items={"center"} justify={"between"} direction={"horizontal"} gap={"sm"}>
        <Box>
          <FileUpload
            entityId={undefined}
            entityType={ApiEntityTypes.employee}
            afterSend={(file) => {
              file && setFilesInner([...filesInner, file]);
              file && onChangeFiles([...filesInner, file]);
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
