import useModals from "../../../../../ui/modal/modal.store.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Modal from "../../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiSource, ApiSourceSavePayload } from "../../../../../types/recruit/source.types.ts";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import i18n from "../../../../../i18n.ts";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import { useSourceSave } from "../../../../../queries/use-sources.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";

export interface SourceEditModalProps extends ModalProps {
  source: ApiSource;
}

export function SourceEditModal({ source, ...props }: SourceEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const SourceSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
  });

  interface SourceSchema extends yup.InferType<typeof SourceSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SourceSchema>({
    mode: "onSubmit",
    resolver: yupResolver(SourceSchemaObject),
    defaultValues: {
      title: source.title,
    },
  });

  const sourceSave = useSourceSave(control);

  const onSubmit = handleSubmit(async (sourceData) => {
    const payload: ApiSourceSavePayload = {
      title: sourceData.title,
    };

    sourceSave.mutate(
      { id: source.id, payload },
      {
        onSuccess: (data) => {
          if (source.id == 0) {
            analyticsService.trackEvent(analyticEvents.sources.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.sources.edited, {
              [analyticProperties.id]: source.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <Modal
      {...props}
      title={t("Source")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} isLoading={sourceSave.isPending} onClick={onSubmit}>
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Input
          label={t("Title")}
          required={true}
          autoFocus={true}
          type="text"
          {...register("title")}
          error={errors.title?.message}
        />
      </form>
    </Modal>
  );
}
