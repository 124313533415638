import { api } from "./axios.ts";
import { ApiPagedData } from "../types/filter.types.ts";
import { ApiEvent, ApiEventFilter, ApiEventPayload } from "../types/event.types.ts";

export const apiEvents = {
  filter: async function (filter: ApiEventFilter, signal: AbortSignal) {
    return api.post<ApiEvent[]>("/api/v1/events/filter", { ...filter }, signal);
  },
  create: async function (event: ApiEventPayload) {
    return api.post<ApiPagedData<ApiEvent>>("/api/v1/events", { ...event });
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/events/${id}`);
  },
  update: async function (id: number, payload: ApiEventPayload) {
    return api.put<ApiEvent>(`/api/v1/events/${id}`, payload);
  },
};
