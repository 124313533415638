import { ComponentPropsWithoutRef, ElementType, forwardRef, ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonSizes } from "./button.types.ts";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { Link } from "react-router-dom";
import { PolymorphicComponentPropWithRef, PolymorphicRef } from "../../helpers/generic-as-prop.ts";
import Box from "../box/Box.tsx";

export type ButtonLinkProps<T extends ElementType = "button"> = PolymorphicComponentPropWithRef<
  T,
  {
    children: ReactNode;
    leftIcon?: ReactElement;
    size?: keyof ButtonSizes;
    isLoading?: boolean;
    href?: string;
    blank?: boolean;
    variant?: "primary" | "secondary";
  }
>;

const ButtonLinkComponentFN = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  <T extends ElementType = "button">(
    {
      size = "md",
      href,
      blank,
      className,
      isLoading = false,
      children,
      variant = "primary",
      leftIcon,
      ...props
    }: ButtonLinkProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theirProps = { type: "button", ...props } as ComponentPropsWithoutRef<T>;

    const sizeTheme: ButtonSizes = {
      sm: "text-sm font-semibold",
      md: "text-base",
      lg: "text-lg",
    };

    const renderInner = () => {
      return (
        <>
          {isLoading && <FontAwesomeIcon icon="fa-light fa-spinner fa-spin mr-2" />}
          {leftIcon}
          <Box
            className={twMerge(
              " inline underline-offset-2",
              variant === "primary" ? "underline " : "hover:underline ",
              sizeTheme[size],
              leftIcon ? "pl-sm" : "",

              className
            )}
          >
            {children}
          </Box>
          {/*{rightIcon}*/}
        </>
      );
    };

    return (
      <>
        {href ? (
          <Link
            to={href}
            ref={ref}
            target={blank ? "_blank" : undefined}
            className={twMerge(
              "outline-0 transition-all ease-in inline ",
              sizeTheme[size],
              className
            )}
            disabled={isLoading}
            {...theirProps}
          >
            {renderInner()}
          </Link>
        ) : (
          <button
            ref={ref}
            className={twMerge(
              "outline-0 transition-all ease-in inline ",
              sizeTheme[size],
              className
            )}
            disabled={isLoading}
            {...theirProps}
          >
            {renderInner()}
          </button>
        )}
      </>
    );
  }
);

const ButtonLink = ButtonLinkComponentFN;
export default ButtonLink;
