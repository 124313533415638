import React, { CSSProperties, ReactElement } from "react";

import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface ModalLayout {
  base: ModalLayoutStructure;
  stretch: ModalLayoutStructure;
  lightbox: ModalLayoutStructure;
  page: ModalLayoutStructure;
}

export interface ModalLayoutStructure {
  wrapper: string;
  content?: string;
  actions?: string;
}

export interface ModalVariant {
  side: string;
  middle: string;
}

export interface ModalSizes extends Pick<ThemeSizes, "md" | "lg" | "xl"> {
  [key: string]: string;
}

export interface UIKitModalTheme extends UIKitThemeBase {
  overlay: string;
  wrapper: string;
  header: string;
  closer: string;
  variants: {
    size: ModalSizes;
    variant: ModalVariant;
    layout: ModalLayout;
  };
  container: ModalSizes;
}

export interface ModalProps {
  children?: ReactElement | ReactElement[];
  actions?: ReactElement | ReactElement[];
  background?: ReactElement | ReactElement[];
  id: string;
  open: boolean;
  title?: string;
  withCloser?: boolean | false;
  onBack?: () => void;
  onClose?: () => void;
  size?: keyof ModalSizes;
  variant?: keyof ModalVariant;
  layout?: keyof ModalLayout;
  closeByEscEnabled?: boolean | false;
  onModalContentScroll?: (scrollTop: number, scrollHeight: number, height: number) => void;
}

export interface ModalComponent {
  component: React.ComponentType;
  props: ModalProps;
}

export const modalTheme: UIKitModalTheme = {
  overlay: "z-overlay bg-black/10 bg-opacity-75 flex",
  wrapper: "flex w-full justify-between p-3 sm:p-6 flex-col",
  base: "transition-all relative",
  header: "p-6", //"mb-lg relative",
  closer: "-mr-2",
  variants: {
    size: {
      md: "w-full sm:max-w-[440px]",
      lg: "w-full sm:max-w-2xl",
      xl: "w-full sm:max-w-5xl",
    },
    variant: {
      side: "items-end",
      middle: "items-center",
    },
    layout: {
      base: {
        wrapper: "overflow-y-auto",
        content: "rounded-md bg-light text-left shadow-md",
        actions: "px-6 pb-6 pt-smd",
      },
      stretch: {
        wrapper: "overflow-hidden",
        content: "rounded-md bg-light text-left shadow-md " + "h-full flex flex-col",
        actions: "px-6 pb-6 pt-smd",
      },
      lightbox: {
        wrapper: "overflow-hidden gap-4 bg-brand-light",
        content: "flex flex-col -mt-4 overflow-y-auto",
        actions: "px-6 pb-6 pt-smd",
      },
      page: {
        wrapper: "gap-4 bg-brand-light p-0 overflow-y-auto sm:p-0",
        content: "flex flex-col -mt-4",
        actions: "px-6 pb-6 pt-smd",
      },
    },
  },
  container: {
    md: "px-6 pb-6",
    lg: "px-6 pb-6",
    xl: "px-6 pb-6",
  },
};

export interface ModalHeaderProps {
  title?: string;
  id: string;
  withCloser?: boolean | false;
  onBack?: () => void;
}

export interface ModalLayoutProps {
  title?: string;
  withCloser?: boolean | false;
  size?: keyof ModalSizes;
  id: string;
  layout?: keyof ModalLayout;
  variant?: keyof ModalVariant;
  floatingProps: Record<string, unknown>;
  children?: ReactElement | ReactElement[];
  actions?: ReactElement | ReactElement[];
  background?: ReactElement | ReactElement[];
  refFromModal: (node: HTMLElement | null) => void;
  style?: CSSProperties;
  onBack?: () => void;
  onModalContentScroll?: (scrollTop: number, scrollHeight: number, height: number) => void;
}
