import { ElementType, forwardRef } from "react";
import { PolymorphicRef } from "../../helpers/generic-as-prop.ts";
import { useAppTheme } from "../../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { PageContentComponentType, PageContentProps } from "./page.types.ts";

const PageContent: PageContentComponentType = forwardRef(
  <T extends ElementType = "div">(
    { children, className }: PageContentProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theme = useAppTheme("page");

    return (
      <div ref={ref} className={twMerge(theme.content, className)}>
        {children}
      </div>
    );
  }
);

export default PageContent;
