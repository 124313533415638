import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import {
  ApiAnalyticsAggregationDate,
  ApiAnalyticsRecruitingClosingTimeReportRow,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import Box from "../../../../ui/box/Box.tsx";
import { useRecruitAnalyticsClosingTimeReport } from "../../../../queries/recruit/use-recruit-analytics.query.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { twMerge } from "tailwind-merge";
import { RecruitingReportingButtonChoosePeriod } from "../components/RecruitingReportingButtonChoosePeriod.tsx";
import { useSearchParams } from "react-router-dom";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { RecruitingClosingTimeReportTable } from "./RecruitingClosingTimeReportTable.tsx";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { RecruitingReportingRecruitersSelector } from "../components/RecruitingReportingRecruitersSelector.tsx";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import { RecruitingClosingTimeReportHiredTable } from "./RecruitingClosingTimeReportHiredTable.tsx";
import { RecruitingReportingButtonFilters } from "../components/RecruitingReportingButtonFilters.tsx";

export interface VMRecruitingClosingTimeReportRow {
  row: ApiAnalyticsRecruitingClosingTimeReportRow;
  width: number;
}

export const RecruitingClosingTimeReport = () => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();
  const { filter } = useRecruitingReports(AnalyticReportTypes.recruitingCloseTime);

  const { data: report, isLoading } = useRecruitAnalyticsClosingTimeReport(filter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.reporting.viewed, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingCloseTime,
    });
  });

  return (
    <>
      <Box className={"bg-light h-full overflow-y-auto"}>
        <Page.Header title={t("Closing time report")}>
          <Stack direction={"horizontal"} gap={"sm"} className={"overflow-hidden"}>
            <RecruitingReportingButtonChoosePeriod
              aggregationType={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.month}
              dateFrom={filter.date_start}
              dateTo={filter.date_end}
            />
            <RecruitingReportingButtonFilters
              filter={filter}
              reportType={AnalyticReportTypes.recruitingSourceEfficiency}
            />
          </Stack>
        </Page.Header>

        <Page.Content>
          <Box>
            <RecruitingReportingRecruitersSelector
              recruiterIds={filter.recruiter_ids || []}
              onChange={(recruiterIds) => {
                setSearchParams((searchParams) => {
                  searchParams.set("recruiter_ids", recruiterIds.join(","));
                  return searchParams;
                });
              }}
            />
          </Box>
          {isLoading && <Skeleton className={"w-full h-10"} />}
          {report && (
            <Stack>
              <Box className={"grid md:grid-cols-3"}>
                <Stack className={twMerge("pt-10 pb-4")}>
                  <span className={"text-[40px] leading-[40px]  font-semibold"}>
                    {Math.round(report.report.average_days_for_closing).toString()}
                  </span>
                  <Text className={"text-lg text-secondary"}>{t("Average days to close")}</Text>
                </Stack>
                <Stack className={twMerge("pt-10 pb-4")}>
                  <Box>
                    <span className={"text-[40px] leading-[40px] font-semibold"}>
                      {" "}
                      {Math.round(report.report.hired_candidate_count).toString()}
                    </span>
                    <span className={"text-[24px] leading-[32px] text-secondary  font-semibold"}>
                      {" / "}
                      {Math.round(report.report.hired_from_jobs_count).toString()}
                    </span>
                  </Box>
                  <Text className={"text-lg text-secondary"}>{t("Jobs closed with hire")}</Text>
                </Stack>
              </Box>
              <Title header={t("Average on stage time")} size={"md"} paddingTop paddingBottom />

              <RecruitingClosingTimeReportTable report={report} />
            </Stack>
          )}
          <RecruitingClosingTimeReportHiredTable />
        </Page.Content>
      </Box>
    </>
  );
};
