import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import {
  ApiEmployeeAssignPositionPayload,
  ApiEmployeePosition,
} from "../../../../types/employee-position.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEmployeePositionSave } from "../../../../queries/use-employee-positions.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useDivisionsSearchInline } from "../../../../queries/use-divisitions.query.ts";
import { useDepartmentsSearchInline } from "../../../../queries/use-departments.query.ts";
import useEmployeeSearchInline from "../../../../queries/use-employees.query.ts";
import { useEffect, useState } from "react";
import { ApiLevel } from "../../../../types/position.types.ts";
import { usePositions } from "../../../../queries/use-positions.query.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Select from "../../../../ui/select/Select.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import { ApiEmployee } from "../../../../types/employee.types.ts";
import usePermissions from "../../../../hooks/use-permissions.hook.ts";
import { EmployeeFieldPermissions } from "../../../../types/role.types.ts";
import i18n from "../../../../i18n.ts";
import { useLocations } from "../../../../queries/use-locations.query.ts";
import { useLegalEntities } from "../../../../queries/use-legal-entities.query.ts";

export interface EmployeePositionModalProps extends ModalProps {
  employee: ApiEmployee;
  employeePosition: ApiEmployeePosition;
}

export const EmployeePositionModal = ({
  employeePosition,
  employee,
  ...props
}: EmployeePositionModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { renderEmployeeName } = useFormatter();

  const { canEditField } = usePermissions();

  const employeePositionSchema = yup.object({
    position_id: yup.number(), //.required(i18n.t("Field is required")),
    position_level_id: yup.number(),
    date_effective_from: canEditField(employee, EmployeeFieldPermissions.employee_position_date)
      ? yup.date().required(i18n.t("Field is required"))
      : yup.date(),
    reporting_to_id: yup.number(),
    department_id: yup.number(),
    division_id: yup.number(),
    location_id: yup.number(),
    legal_entity_id: yup.number(),
  });

  interface EmployeePositionSchema extends yup.InferType<typeof employeePositionSchema> {}

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EmployeePositionSchema>({
    mode: "onSubmit",
    resolver: yupResolver(employeePositionSchema),
    defaultValues: {
      position_id: employeePosition.position?.id,
      position_level_id: employeePosition.position_level?.id,
      date_effective_from: employeePosition.date_effective_from,
      reporting_to_id: employeePosition.manager?.id,
      department_id: employeePosition.department?.id,
      division_id: employeePosition.division?.id,
      location_id: employeePosition.location?.id,
      legal_entity_id: employeePosition.legal_entity?.id,
    },
  });

  const employeePositionSave = useEmployeePositionSave(control);

  const onSubmit = handleSubmit(async (employeeData) => {
    const payload: ApiEmployeeAssignPositionPayload = {
      date_effective_from: employeeData.date_effective_from,
      department_id: employeeData.department_id,
      division_id: employeeData.division_id,
      position_id: employeeData.position_id,
      position_level_id: employeeData.position_level_id,
      reporting_to_id: employeeData.reporting_to_id,
      location_id: employeeData.location_id,
      legal_entity_id: employeeData.legal_entity_id,
    };

    try {
      await employeePositionSave.mutateAsync({
        employeeId: employee.id,
        positionId: employeePosition.id,
        payload: payload,
      });
      if (employeePosition.id == 0) {
        analyticsService.trackEvent(analyticEvents.employeePositions.created, {
          [analyticProperties.id]: employee.id,
        });
      } else {
        analyticsService.trackEvent(analyticEvents.employeePositions.created, {
          [analyticProperties.id]: employee.id,
        });
      }
      close(id);
    } catch {
      return;
    }
  });

  const { data: positions, isLoading: isPositionsLoading } = usePositions(
    canEditField(employee, EmployeeFieldPermissions.employee_position_job_title)
  );
  const departments = useDepartmentsSearchInline(
    canEditField(employee, EmployeeFieldPermissions.employee_position_department)
  );
  const divisions = useDivisionsSearchInline(
    canEditField(employee, EmployeeFieldPermissions.employee_position_division)
  );
  const managerSearch = useEmployeeSearchInline(
    canEditField(employee, EmployeeFieldPermissions.employee_position_reporting_to)
  );
  const [levels, setLevels] = useState<ApiLevel[]>([]);
  const { data: locations, isLoading: isLocationsLoading } = useLocations(
    canEditField(employee, EmployeeFieldPermissions.employee_position_location)
  );
  const { data: legalEntities, isLoading: isLegalEntitiesLoading } = useLegalEntities(
    canEditField(employee, EmployeeFieldPermissions.employee_position_legal_entity)
  );

  useEffect(() => {
    if (employeePosition.position) {
      const position = positions?.find((x) => x.id == employeePosition.position?.id);
      if (position) {
        setLevels(position.levels);
      }
    }
  }, [positions]);

  return (
    <>
      <Modal
        {...props}
        title={t("Assign position")}
        withCloser={true}
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={employeePositionSave.isPending}
              onClick={onSubmit}
            >
              {t("Save")}
            </Button>
            <Button
              variant={"plain"}
              size={"lg"}
              onClick={() => {
                close(id);
              }}
            >
              {t("Cancel")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            {canEditField(employee, EmployeeFieldPermissions.employee_position_job_title) && (
              <>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Select
                      label={t("Position")}
                      options={positions}
                      emptyTitle={t("Select")}
                      isLoading={isPositionsLoading}
                      value={value}
                      searchable
                      onChange={(value) => {
                        const position = positions?.find((x) => x.id == value);
                        if (position) {
                          setLevels(position.levels);
                        }
                        setValue("position_level_id", undefined);
                        onChange(value);
                      }}
                      error={errors.position_id?.message}
                    />
                  )}
                  control={control}
                  name={"position_id"}
                />
                {levels.length > 0 && (
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Select
                        label={t("Level")}
                        options={levels}
                        emptyTitle={t("Select")}
                        isLoading={isPositionsLoading}
                        value={value}
                        onChange={onChange}
                        error={errors.position_level_id?.message}
                      />
                    )}
                    control={control}
                    name={"position_level_id"}
                  />
                )}
              </>
            )}

            <Title header={t("Details")} paddingTop paddingBottom size={"xs"} />
            {canEditField(employee, EmployeeFieldPermissions.employee_position_date) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Datepicker
                    value={value}
                    onChange={onChange}
                    required={canEditField(
                      employee,
                      EmployeeFieldPermissions.employee_position_date
                    )}
                    label={t("Date effective from")}
                    error={errors.date_effective_from?.message}
                  />
                )}
                control={control}
                name={"date_effective_from"}
              />
            )}

            {canEditField(employee, EmployeeFieldPermissions.employee_position_reporting_to) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Manager")}
                    emptyTitle={t("Select")}
                    isLoading={managerSearch.isLoading}
                    value={value}
                    options={
                      employeePosition.manager
                        ? [
                            {
                              id: employeePosition.manager.id,
                              title: renderEmployeeName(employeePosition.manager),
                            },
                          ]
                        : []
                    }
                    searchable
                    onSearchOptions={(text) => managerSearch.search(text)}
                    searchOptions={managerSearch.options}
                    loadOptionsOnOpen
                    onChange={onChange}
                    error={errors.reporting_to_id?.message}
                  />
                )}
                control={control}
                name={"reporting_to_id"}
              />
            )}

            {canEditField(employee, EmployeeFieldPermissions.employee_position_department) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Department")}
                    options={employeePosition.department ? [employeePosition.department] : []}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    searchable
                    loadOptionsOnOpen
                    onSearchOptions={(text) => departments.search(text)}
                    searchOptions={departments.options}
                    error={errors.department_id?.message}
                  />
                )}
                control={control}
                name={"department_id"}
              />
            )}

            {canEditField(employee, EmployeeFieldPermissions.employee_position_division) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Division")}
                    options={employeePosition.division ? [employeePosition.division] : []}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    searchable
                    loadOptionsOnOpen
                    onSearchOptions={(text) => divisions.search(text)}
                    searchOptions={divisions.options}
                    error={errors.division_id?.message}
                  />
                )}
                control={control}
                name={"division_id"}
              />
            )}

            {canEditField(employee, EmployeeFieldPermissions.employee_position_location) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Location")}
                    options={locations}
                    emptyTitle={t("Select")}
                    isLoading={isLocationsLoading}
                    value={value}
                    searchable
                    onChange={onChange}
                    error={errors.location_id?.message}
                  />
                )}
                control={control}
                name={"location_id"}
              />
            )}
            {canEditField(employee, EmployeeFieldPermissions.employee_position_legal_entity) && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Legal entity")}
                    options={legalEntities}
                    emptyTitle={t("Select")}
                    isLoading={isLegalEntitiesLoading}
                    value={value}
                    searchable
                    onChange={onChange}
                    error={errors.legal_entity_id?.message}
                  />
                )}
                control={control}
                name={"legal_entity_id"}
              />
            )}
          </Stack>
        </form>
      </Modal>
    </>
  );
};
