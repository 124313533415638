import Box from "../../ui/box/Box.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";

export interface UserListItemProps {
  avatarUrl?: string;
  primaryText: string;
  text2?: string;
  text3?: string;
}

export const UserListItem = ({ avatarUrl, primaryText, text2, text3 }: UserListItemProps) => {
  return (
    <Stack className={"overflow-hidden"} direction={"horizontal"} gap={"smd"} items={"center"}>
      <Box className={"mt-1 relative"}>
        <Avatar size={"md"} url={avatarUrl} acronym={primaryText} />
      </Box>
      <Stack className={"overflow-hidden"}>
        <Text className={"text-title overflow-hidden overflow-ellipsis whitespace-nowrap"}>
          {primaryText}
        </Text>

        {text2 && (
          <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
            {text2}
          </Text>
        )}
        {text3 && (
          <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
            {text3}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
