import { Page } from "../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Text from "../../ui/typography/Text.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { Title } from "../../components/title/Title.tsx";

export default function EmailConfirmed() {
  const { t } = useTranslation();
  return (
    <>
      <Page>
        <Page.Content>
          <Stack className={"container-sm mx-auto py-16"}>
            <Title
              header={t("Thank You for Confirming Your Email!")}
              size={"lg"}
              paddingBottom
              paddingTop
            />
            <Title header={t("Welcome to Skailer!")} size={"md"} paddingBottom paddingTop />
            <Text>
              {t(
                "We're thrilled to have you join Skailer, the ultimate tool for making your hiring process smoother and more efficient. You’re all set to transform how you find, engage, and hire top talent."
              )}
            </Text>
            <Title header={t("What’s Next?")} size={"md"} paddingTop />
            <Title header={t("Invite Your Team")} size={"sm"} paddingBottom paddingTop />
            <Text>
              {t("Collaborate effectively by inviting your colleagues to join Skailer.")}{" "}
              <LinkTo to={"/employees"}>{t("Invite Team Members")}</LinkTo>
            </Text>
            <Title
              header={t("Install Our Chrome Extension")}
              size={"sm"}
              paddingBottom
              paddingTop
            />
            <Text>
              {t(
                "Enhance your hiring process with our Google Chrome extension, designed to help you source candidates effortlessly as you browse the web."
              )}{" "}
              <LinkTo
                onClick={() => {
                  analyticsService.trackEvent(analyticEvents.pageAction, {
                    [analyticProperties.actionType]: "Extension Clicked",
                    [analyticProperties.source]: "Email Confirmed",
                  });
                }}
                target={"_blank"}
                to={
                  "https://chromewebstore.google.com/detail/skailer-sourcing-extensio/iifbgblakgpljhhnejbmfoajbbfoiocl"
                }
              >
                {t("Install Chrome Extension")}
              </LinkTo>
            </Text>
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
