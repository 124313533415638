import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useCallback, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import Text from "../typography/Text.tsx";
import Stack from "../stack/Stack.tsx";
import { InnerOption, MultiselectProps, SelectItem } from "./select.types.ts";
import { useTheme } from "../themes/provider.tsx";
import { SelectOption } from "./SelectOption.tsx";
import Box from "../box/Box.tsx";
import { Input } from "../input/Input.tsx";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import useAbortedEffect from "../../hooks/use-aborted-effect.hook.ts";
import { uniqBy } from "lodash";
import Button from "../button/Button.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx"; // https://ameykhoje.medium.com/custom-select-dropdown-component-with-pure-react-with-multiselect-search-single-select-71c78f4259b5

// https://ameykhoje.medium.com/custom-select-dropdown-component-with-pure-react-with-multiselect-search-single-select-71c78f4259b5
// https://dev.to/wlytle/implementing-a-searchable-async-dropdown-in-react-5hce

export default function Multiselect<T extends SelectItem>({
  options,
  values,
  emptyTitle,
  onChange,
  isLoading,
  loadOptionsOnOpen,
  label,
  isMulti = true,
  readOnly = false,
  disabled = false,
  required = false,
  variant = "default",
  //
  searchable,
  searchOptions,
  onSearchOptions,
  error,
  isClearable,
}: MultiselectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [openKey, setOpenKey] = useState(0);

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchText);
  const theme = useTheme("select");
  const inputTheme = useTheme("input");
  const { t } = useTranslation();

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchText);
    },
    300,
    [searchText]
  );

  const optionsInner = useMemo(() => {
    const optionsInnerMapped =
      options?.map((option) => {
        return {
          id: option.id,
          title: option.title,
          item: option,
          isMulti: isMulti,
          selected: values.find((x) => x == option.id) != undefined,
        };
      }) || [];
    const passedSearchOptions =
      searchOptions?.map((option) => {
        return {
          id: option.id,
          title: option.title,
          item: option,
          isMulti: isMulti,
          selected: values.find((x) => x == option.id) != undefined,
        };
      }) || [];

    if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
      // Сюда попадаем когда есть поиск
      if (onSearchOptions) {
        return passedSearchOptions;
      } else {
        const regex = new RegExp(debouncedSearchTerm || "", "i");
        return optionsInnerMapped.filter((option) => regex.test(option.title));
      }
    } else {
      const result = [...optionsInnerMapped, ...passedSearchOptions];
      return uniqBy(result, "id");
    }
  }, [options, values, searchOptions, debouncedSearchTerm]);

  const optionsInnerWithSearch = useMemo(() => {
    const optionsInnerMapped =
      options?.map((option) => {
        return {
          id: option.id,
          title: option.title,
          item: option,
          isMulti: isMulti,
          selected: values.find((x) => x == option.id) != undefined,
        };
      }) || [];
    const passedSearchOptions =
      searchOptions?.map((option) => {
        return {
          id: option.id,
          title: option.title,
          item: option,
          isMulti: isMulti,
          selected: values.find((x) => x == option.id) != undefined,
        };
      }) || [];
    const result = [...optionsInnerMapped, ...passedSearchOptions];
    const resultUniq = uniqBy(result, "id");
    return resultUniq;
  }, [options, values, debouncedSearchTerm]);

  const handleOpen = useCallback(
    (open: boolean) => {
      if (open) {
        setOpenKey(openKey + 1);
        setDebouncedSearchTerm(undefined);
      }

      setIsOpen(open);
    },
    [optionsInner]
  );

  const handleSearch = useCallback((text: string | undefined) => {
    if (onSearchOptions) {
      setSearchText(text);
    } else {
      setDebouncedSearchTerm(text);
    }
  }, []);

  useAbortedEffect(() => {
    const isOnOpen = loadOptionsOnOpen && searchText == undefined;
    const isOnSearch = searchable && searchText != undefined && onSearchOptions;

    if (isOnOpen || isOnSearch) {
      if (onSearchOptions) {
        onSearchOptions(searchText);
      }
    }
  }, [debouncedSearchTerm, openKey]);

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: handleOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${Math.min(rects.reference.width, 450)}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    click,
  ]);

  const handleSelect = (item: InnerOption<T>) => {
    const newValues = isMulti
      ? values.find((x) => x == item.id)
        ? values.filter((x) => x != item.id)
        : [...values, item.id]
      : [item.id];

    onChange?.(newValues);

    if (isMulti == false) setIsOpen(false);
  };

  const selectedItemsTitle = useMemo(() => {
    if (values.length == 0) return "";

    return optionsInnerWithSearch.reduce((acc, item) => {
      if (!item.selected) return acc;

      return acc + (acc.length ? `, ${item.title}` : item.title);
    }, "");
  }, [values, options]);

  return (
    <>
      <Box>
        <div
          tabIndex={0}
          ref={refs.setReference}
          aria-labelledby="select-label"
          aria-autocomplete="none"
          className={twMerge(
            theme.base,
            theme.variants.variant[variant],
            theme.variants.size["md"],
            !readOnly ? theme.hover : undefined,
            readOnly ? theme.readonly : undefined,
            disabled ? theme.disabled : undefined
          )}
          {...getReferenceProps()}
        >
          <Text
            className={twMerge(
              inputTheme.label.base,
              selectedItemsTitle.length ? inputTheme.label.focused : undefined,
              readOnly ? inputTheme.label.readonly : undefined,
              disabled ? inputTheme.label.disabled : undefined
            )}
          >
            {label ?? emptyTitle} {required && <span className={"text-danger"}>*</span>}
          </Text>
          <Box
            className={twMerge(
              theme.value.base,
              !readOnly ? theme.value.hover : theme.value.readonly,
              disabled ? theme.value.disabled : undefined
            )}
          >
            {selectedItemsTitle}
          </Box>

          {isLoading ? (
            <FontAwesomeIcon
              className={twMerge(theme.pointer.base, disabled ? theme.pointer.disabled : undefined)}
              icon="fa-light fa-spinner fa-spin"
            />
          ) : values.length > 0 && isClearable ? (
            <ButtonIcon
              variant={"plain"}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                e.preventDefault();
                onChange?.([]);
              }}
              icon={<FontAwesomeIcon icon={"fa-light fa-xmark"} />}
            />
          ) : (
            <FontAwesomeIcon
              className={twMerge(theme.pointer.base, disabled ? theme.pointer.disabled : undefined)}
              icon={"fa-light fa-chevron-down"}
            />
          )}
        </div>
        {!readOnly && !disabled && isOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <div
                ref={refs.setFloating}
                className={twMerge(theme.dropdown.base, "z-popover")}
                {...getFloatingProps()}
                style={floatingStyles}
              >
                {searchable && (
                  // <Stack className={"px-4 py-2"}>
                  <Input
                    type={"text"}
                    label={t("Search")}
                    placeholder={`${t("Start typing")}...`}
                    className={"px-4"}
                    onChange={(e) => {
                      handleSearch(e.currentTarget.value);
                    }}
                  />
                  // </Stack>
                )}
                <Stack className={theme.dropdown.itemsContainer}>
                  {optionsInner.map((item, index) => (
                    <SelectOption
                      key={index}
                      item={item}
                      isMulti={item.isMulti}
                      handleSelect={handleSelect}
                      {...getItemProps()}
                    />
                  ))}
                  {isMulti && (
                    <Box>
                      <Button
                        className={"w-full"}
                        size={"sm"}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        {t("Apply")}
                      </Button>
                    </Box>
                  )}
                </Stack>
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
        {error && (
          <Box className={"pt-2 pb-4"}>
            <Text className={"text-danger"}>{error}</Text>
          </Box>
        )}
      </Box>
    </>
  );
}
