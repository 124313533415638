import { SelectItem } from "../ui/select/select.types.ts";
import timeZonesV2 from "../assets/timezones.json";

export interface TimezoneItem extends SelectItem {
  id: string;
  title: string;
  // utc: string[];
}

export default function getTimezones(): TimezoneItem[] {
  // https://gist.github.com/ykessler/3349960
  const result: TimezoneItem[] = [];
  for (const timeZoneGroup of timeZonesV2) {
    for (const timeZone of timeZoneGroup.zones) {
      result.push({
        id: timeZone.value,
        title: timeZone.value,
        // utc: timeZone.utc,
      });
    }
  }
  result.sort((a, b) => a.title.localeCompare(b.title));
  return result;
}

export const DefaultTimezone = "Europe/London";
