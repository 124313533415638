import { ApiDepartment, ApiDepartmentSavePayload } from "../../../../types/department.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import Select from "../../../../ui/select/Select.tsx";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { Input } from "../../../../ui/input/Input.tsx";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import { useDepartments, useDepartmentSave } from "../../../../queries/use-departments.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useEmployeeSearchInline from "../../../../queries/use-employees.query.ts";

export interface DepartmentEditModalProps extends ModalProps {
  department: ApiDepartment;
}

export const DepartmentEditModal = ({ department, ...props }: DepartmentEditModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { renderEmployeeName } = useFormatter();
  const managerSearch = useEmployeeSearchInline();

  const departmentSchema = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
    manager_id: yup.number(),
    parent_id: yup.number(),
  });

  interface DepartmentSchema extends yup.InferType<typeof departmentSchema> {}

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DepartmentSchema>({
    mode: "onSubmit",
    resolver: yupResolver(departmentSchema),
    defaultValues: {
      parent_id: department.parent_id || undefined,
      manager_id: department.manager?.id,
    },
  });

  const { data: allDepartments } = useDepartments();
  const departmentSave = useDepartmentSave(control);

  const departments = useMemo(() => {
    if (allDepartments) return allDepartments.filter((x) => x.id != department.id);
    return [];
  }, [allDepartments]);

  const onSubmit = handleSubmit(async (schema) => {
    const savePayload: ApiDepartmentSavePayload = {
      title: schema.title,
      manager_id: schema.manager_id,
      parent_id: schema.parent_id,
    };
    departmentSave.mutate(
      { id: department.id, payload: savePayload },
      {
        onSuccess: (data) => {
          if (department.id == 0) {
            analyticsService.trackEvent(analyticEvents.departments.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.departments.edited, {
              [analyticProperties.id]: data.id,
            });
          }
          close(id);
        },
      }
    );
  });

  const managerOptions = useMemo(() => {
    if (department.manager) {
      return [
        {
          id: department.manager.id,
          title: renderEmployeeName(department.manager),
        },
      ] as SelectItem[];
    }
    return [];
  }, [department.manager]);

  return (
    <>
      <Modal
        {...props}
        title={t("Department")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={departmentSave.isPending}
              onClick={onSubmit}
            >
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            <Input
              label={t("Title")}
              required={true}
              autoFocus={true}
              type="text"
              {...register("title", {
                value: department.title,
              })}
              error={errors.title?.message}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Parent department")}
                  options={departments}
                  emptyTitle={t("Select")}
                  value={value}
                  onChange={onChange}
                  error={errors.parent_id?.message}
                />
              )}
              control={control}
              name={"parent_id"}
            />

            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Manager")}
                  emptyTitle={t("Select")}
                  value={value}
                  options={managerOptions}
                  searchable
                  onSearchOptions={(text) => managerSearch.search(text)}
                  searchOptions={managerSearch.options}
                  loadOptionsOnOpen
                  onChange={onChange}
                  error={errors.manager_id?.message}
                />
              )}
              control={control}
              name={"manager_id"}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
};
