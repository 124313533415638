import { PaginationBlockProps } from "./pagination.types.ts";
import Pagination from "./Pagination.tsx";
import { useTranslation } from "react-i18next";

export const PaginationBlock = ({ metadata, onPageChange }: PaginationBlockProps) => {
  const { t } = useTranslation();
  return (
    <>
      {metadata && metadata.total_items > 0 && (
        <Pagination
          showingLabel={t("Showing {from} to {to} of {total} in total", {
            from: (metadata.page_number - 1) * metadata.items_per_page + 1,
            to: (metadata.page_number - 1) * metadata.items_per_page + metadata.items,
            total: metadata.total_items,
          })}
          onPageChange={onPageChange}
          currentPage={metadata.page_number}
          totalPages={metadata.total_pages}
        />
      )}
    </>
  );
};
