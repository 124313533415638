import { UseFormReturn } from "react-hook-form";
import { EditRoleSchema } from "../role-edit.types.ts";
import Box from "../../../../../ui/box/Box.tsx";
import { Tabs } from "../../../../../ui/tabs/Tabs.tsx";
import { ApiRole, RoleType } from "../../../../../types/role.types.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { RoleEditPermissions } from "./RoleEditPermissions.tsx";
import { useTranslation } from "react-i18next";
import useRoleEditService from "../role-edit-service.ts";
import { RoleEditEmployeePermissions } from "./RoleEditEmployeePermissions.tsx";
import { Title } from "../../../../../components/title/Title.tsx";

export interface RoleEditTabsProps {
  role?: ApiRole | undefined;
  formHook: UseFormReturn<EditRoleSchema>;
}

export function RoleEditTabs({ role, formHook }: RoleEditTabsProps) {
  const { t } = useTranslation();
  const { control } = formHook;
  const {
    getPermissionRecruitmentSection,
    getPermissionSettingsSection,
    getPermissionGeneralSection,
  } = useRoleEditService();

  return (
    <Stack direction={"vertical"} gap={"md"}>
      <Title header={t("Set permissions")} size={"lg"} paddingBottom paddingTop />
      <Box className={"border border-dark/10 rounded-md"}>
        <Tabs>
          {[
            RoleType.manager,
            RoleType.team_lead,
            RoleType.all_employees,
            RoleType.myself,
            RoleType.custom,
          ].indexOf(role?.type || RoleType.custom) > -1 && (
            <Tabs.Item title={t("Employee")}>
              <Stack className={"px-10 pb-10 pt-6"}>
                <RoleEditEmployeePermissions role={role} formHook={formHook} />
              </Stack>
            </Tabs.Item>
          )}
          {[RoleType.custom].indexOf(role?.type || RoleType.custom) > -1 && (
            <Tabs.Item title={t("General")}>
              <Stack className={"px-10 pb-10 pt-6"}>
                <RoleEditPermissions control={control} section={getPermissionGeneralSection()} />
              </Stack>
            </Tabs.Item>
          )}
          {[RoleType.custom].indexOf(role?.type || RoleType.custom) > -1 && (
            <Tabs.Item title={t("Recruit")}>
              <Stack className={"px-10 pb-10 pt-6"}>
                <RoleEditPermissions
                  control={control}
                  section={getPermissionRecruitmentSection()}
                />
              </Stack>
            </Tabs.Item>
          )}
          {[RoleType.custom].indexOf(role?.type || RoleType.custom) > -1 && (
            <Tabs.Item title={t("Settings")}>
              <Stack className={"px-10 pb-10 pt-6"}>
                <RoleEditPermissions control={control} section={getPermissionSettingsSection()} />
              </Stack>
            </Tabs.Item>
          )}
        </Tabs>
      </Box>
    </Stack>
  );
}
