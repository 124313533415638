import Modal from "../../../../../ui/modal/modal";
import useModals from "../../../../../ui/modal/modal.store";
import Button from "../../../../../ui/button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import { ApiCandidateDisqualificationReason } from "../../../../../types/recruit/disqualification-reason.types.ts";
import * as yup from "yup";
import i18n from "../../../../../i18n.ts";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import { useDisqualificationSave } from "../../../../../queries/recruit/use-disqualifications.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";

export interface CandidateDisqualificationReasonEditModelProps extends ModalProps {
  disqualificationReason: ApiCandidateDisqualificationReason;
}

export function CandidateDisqualificationReasonEditModal({
  disqualificationReason,
  ...props
}: CandidateDisqualificationReasonEditModelProps) {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  const CandidateDisqualificationReasonSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
  });

  interface CandidateDisqualificationReasonSchema
    extends yup.InferType<typeof CandidateDisqualificationReasonSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CandidateDisqualificationReasonSchema>({
    mode: "onSubmit",
    resolver: yupResolver(CandidateDisqualificationReasonSchemaObject),
    defaultValues: {
      title: disqualificationReason.title,
    },
  });

  const disqualificationSave = useDisqualificationSave(control);

  const onSubmit = handleSubmit((data) => {
    disqualificationSave.mutate(
      {
        id: disqualificationReason.id,
        title: data.title,
      },
      {
        onSuccess: (data) => {
          if (disqualificationReason.id == 0) {
            analyticsService.trackEvent(analyticEvents.disqualificationReasons.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.disqualificationReasons.edited, {
              [analyticProperties.id]: disqualificationReason.id,
            });
          }

          close(id);
        },
      }
    );
  });

  return (
    <Modal
      {...props}
      title={t("Disqualification reason")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            type={"submit"}
            size={"lg"}
            isLoading={disqualificationSave.isPending}
            onClick={onSubmit}
          >
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Input
          label={t("Title")}
          required={true}
          type={"text"}
          autoFocus={true}
          {...register("title")}
          error={errors.title?.message}
        />
      </form>
    </Modal>
  );
}
