import { api } from "./axios";
import {
  ApiDocument,
  ApiDocumentSavePayload,
  ApiDocumentsFilter,
  ApiFolder,
  ApiFolderPayload,
} from "../types/document.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiDocuments = {
  getFoldersByEmployeeId: (id: number, signal: AbortSignal) => {
    return api.get<ApiFolder[]>(`/api/v1/documents/folders/${id}`, undefined, signal);
  },
  createFolder: (employeeId: number, payload: ApiFolderPayload) => {
    return api.post<ApiFolder>(`/api/v1/documents/folders/${employeeId}`, payload);
  },
  updateFolder: (employeeId: number, id: number, payload: ApiFolderPayload) => {
    return api.put<ApiFolder>(`/api/v1/documents/folders/${employeeId}/${id}`, payload);
  },
  deleteFolder: (employeeId: number, id: number) => {
    return api.delete(`/api/v1/documents/folders/${employeeId}/${id}`);
  },

  updateDocument: (id: number, payload: ApiDocumentSavePayload) => {
    return api.put<ApiDocument>(`/api/v1/documents/${id}`, payload);
  },
  deleteDocument: (id: number) => {
    return api.delete(`/api/v1/documents/${id}`);
  },
  searchDocuments: (filter: ApiDocumentsFilter, signal: AbortSignal) => {
    return api.post<ApiPagedData<ApiDocument>>(
      `/api/v1/documents/search/${filter.employee_id}`,
      filter,
      signal
    );
  },
  createDocument: (employeeId: number, payload: ApiDocumentSavePayload) => {
    return api.post<ApiDocument>(`/api/v1/documents/${employeeId}`, payload);
  },
};
