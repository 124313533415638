import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { useCallback, useState } from "react";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import { EmployeeNoteEditModal } from "../widgets/EmployeeNoteEditModal.tsx";
import {
  ApiNote,
  ApiNoteEdit,
  ApiNotesFilter,
  ApiNoteVisibility,
} from "../../../../types/notes.types.ts";
import { useNoteDelete, useNotesSearch } from "../../../../queries/use-notes.query.ts";
import { EmptyStateBase } from "../../../../components/empty-state/EmptyStateBase.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { EmployeeAvatar } from "../../../../components/employee-avatar/EmployeeAvatar.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";

export const EmployeeNotes = ({ employee }: EmployeePageComponentProps) => {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { renderEmployeeName, renderDateAndTime, renderRelativeDate } = useFormatter();
  const { employee: me } = useEmployeeStore();

  const handleEditOrCreate = useCallback((note: ApiNote | undefined = undefined) => {
    const noteToEdit: ApiNoteEdit = note || {
      text: "",
      visibility: ApiNoteVisibility.myself,
    };
    modalsService.openModal(EmployeeNoteEditModal, {
      note: noteToEdit,
      noteId: note?.id || 0,
      employeeId: employee.id,
    });
  }, []);

  const [notesFilter, setNotesFilter] = useState<ApiNotesFilter>({
    page_number: 1,
  });

  const { data: notes } = useNotesSearch(employee.id, notesFilter);
  const deleteNote = useNoteDelete();

  const handleDeleteNoteAction = useCallback((note: ApiNote) => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("Delete"),
      text: t("Are you sure you want to delete?"),
      acceptTitle: t("Delete"),
      onAccept: async function () {
        try {
          await deleteNote.mutateAsync({ employeeId: employee.id, id: note.id });
          analyticsService.trackEvent(analyticEvents.notes.deleted, {
            [analyticProperties.id]: note.id,
          });
        } catch {
          return;
        }
      },
    });
  }, []);

  return (
    <>
      <Stack gap={"lg"}>
        <Stack className={"justify-between"} direction={"horizontal"} items={"start"}>
          <Title header={t("Notes")} size={"md"} />

          <Button
            variant={"primary"}
            leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
            size={"sm"}
            onClick={() => {
              handleEditOrCreate();
            }}
          >
            {t("Add note")}
          </Button>
        </Stack>
        {notes && notes.items.length > 0 ? (
          <Stack gap={"lg"}>
            {notes.items.map((note) => (
              <Stack key={note.id} gap={"sm"}>
                <Stack
                  gap={"sm"}
                  direction={"horizontal"}
                  items={"center"}
                  className={"overflow-hidden"}
                >
                  <Box className={"flex shrink-0"}>
                    <EmployeeAvatar size={"xs"} employee={note.author} />
                  </Box>

                  <Stack
                    direction={"horizontal"}
                    gap={"xs"}
                    items={"center"}
                    className={"overflow-hidden"}
                  >
                    <Text className={"text-title text-nowrap overflow-ellipsis overflow-hidden"}>
                      {renderEmployeeName(note.author)}
                    </Text>
                    <Text className={"text-secondary "}>·</Text>
                    {note.datetime_created_at && (
                      <Text
                        title={renderDateAndTime(note.datetime_created_at)}
                        className={"text-secondary text-nowrap  overflow-ellipsis overflow-hidden"}
                      >
                        {renderRelativeDate(note.datetime_created_at)}
                      </Text>
                    )}
                    <Text className={"text-secondary "}>·</Text>
                    {note.visibility == ApiNoteVisibility.myself && (
                      <>
                        <FontAwesomeIcon icon={"fa-light fa-lock text-secondary text-sm"} />
                        <Text className={"text-secondary "}>{t("Visible only to you")}</Text>
                      </>
                    )}
                    {note.visibility == ApiNoteVisibility.my_and_employee && (
                      <>
                        <FontAwesomeIcon icon={"fa-light fa-eye text-secondary text-sm"} />
                        <Text
                          className={"text-secondary "}
                        >{`${t("Visible only to you")} ${t("and")} ${renderEmployeeName(employee)}`}</Text>
                      </>
                    )}
                    {note.visibility == ApiNoteVisibility.public && (
                      <>
                        <FontAwesomeIcon icon={"fa-light fa-eye text-secondary text-sm"} />
                        <Text className={"text-secondary "}>{`${t("Visible to everyone")}`}</Text>
                      </>
                    )}
                  </Stack>
                  {me && note.author.id == me.id && (
                    <Box className={"shrink-0"}>
                      <DropdownMenu
                        trigger={
                          <FontAwesomeIcon
                            icon={"fa-light fa-ellipsis"}
                            className={"cursor-pointer"}
                          />
                        }
                      >
                        <Button
                          onClick={() => handleEditOrCreate(note)}
                          leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                          variant={"menu"}
                        >
                          {t("Edit")}
                        </Button>

                        <Button
                          onClick={() => handleDeleteNoteAction(note)}
                          leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                          variant={"menu"}
                        >
                          {t("Delete")}
                        </Button>
                      </DropdownMenu>
                    </Box>
                  )}
                </Stack>
                <Text
                  className={"text-default whitespace-pre-wrap overflow-ellipsis overflow-hidden"}
                >
                  {note.text}
                </Text>
              </Stack>
            ))}
          </Stack>
        ) : (
          <EmptyStateBase
            emoji={"🗒️"}
            title={t("No notes added")}
            description={t("Store notes, agreements, reminders etc.")}
            primaryButton={
              <Button
                onClick={() => {
                  handleEditOrCreate();
                }}
                size={"sm"}
                variant={"secondary"}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-upload"} />}
              >
                {t("Add note")}
              </Button>
            }
          />
        )}

        <PaginationBlock
          metadata={notes?.metadata}
          onPageChange={(page) => {
            setNotesFilter({ ...notesFilter, page_number: page });
          }}
        />
      </Stack>
    </>
  );
};
