import { EmployeeInfoItem, EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Link } from "react-router-dom";
import { UserListItem } from "../../../../components/user-list/UserListItem.tsx";
import { useTranslation } from "react-i18next";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { useMemo } from "react";

export function EmployeePersonalCard({ employee }: EmployeePageComponentProps) {
  const { t } = useTranslation();
  const { renderEmployeeName, renderDate, formatDurationPartialDate, transformDateToPartialDate } =
    useFormatter();

  const contacts: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.email) {
      result.push({
        title: t("Email"),
        caption: employee.email,
      });
    }
    if (employee.email_personal) {
      result.push({
        title: t("Email personal"),
        caption: employee.email_personal,
      });
    }
    if (employee.phone) {
      result.push({
        title: t("Phone"),
        caption: employee.phone,
      });
    }
    if (employee.phone_personal) {
      result.push({
        title: t("Phone personal"),
        caption: employee.phone_personal,
      });
    }

    return result;
  }, [employee]);

  const personal: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.personnel_number) {
      result.push({
        title: t("Employee #"),
        caption: employee.personnel_number,
      });
    }
    if (employee.date_hired_on) {
      result.push({
        title: t("Employee since"),
        caption: renderDate(employee.date_hired_on),
        value: formatDurationPartialDate(
          transformDateToPartialDate(employee.date_hired_on),
          undefined
        ),
      });
    }

    if (employee.employee_position.position) {
      const level = employee.employee_position.position_level
        ? ", " + employee.employee_position.position_level.title
        : "";
      result.push({
        title: t("Position"),
        caption: employee.employee_position.position.title + level,
      });
    }

    if (employee.employee_position.department) {
      result.push({
        title: t("Department"),
        caption: employee.employee_position.department.title,
      });
    }

    if (employee.employee_position.division) {
      result.push({
        title: t("Division"),
        caption: employee.employee_position.division.title,
      });
    }

    if (employee.date_birth) {
      result.push({
        title: t("Birth"),
        caption: renderDate(employee.date_birth),
        value: formatDurationPartialDate(
          transformDateToPartialDate(employee.date_birth),
          undefined
        ),
      });
    }

    if (employee.employee_position.location) {
      result.push({
        title: t("Location"),
        caption: employee.employee_position.location.title,
      });
    }

    if (employee.employee_position.legal_entity) {
      result.push({
        title: t("Legal entity"),
        caption: employee.employee_position.legal_entity.title,
      });
    }

    return result;
  }, [employee]);

  const address: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.country) {
      result.push({
        title: t("Country"),
        caption: employee.country.title,
      });
    }
    if (employee.city) {
      result.push({
        title: t("City"),
        caption: employee.city,
      });
    }
    if (employee.state) {
      result.push({
        title: t("State"),
        caption: employee.state,
      });
    }
    if (employee.zip) {
      result.push({
        title: t("Zip code"),
        caption: employee.zip,
      });
    }
    if (employee.address_1) {
      result.push({
        title: t("Address line 1"),
        caption: employee.address_1,
      });
    }
    if (employee.address_2) {
      result.push({
        title: t("Address line 2"),
        caption: employee.address_2,
      });
    }

    return result;
  }, [employee]);

  return (
    <Stack direction={"vertical"}>
      {employee.employee_position.manager && (
        <>
          <Title size={"xs"} header={t("Manager")} paddingTop={true} paddingBottom={true} />
          <Link to={`/employees/${employee.employee_position.manager.id}`}>
            <UserListItem
              primaryText={renderEmployeeName(employee.employee_position.manager)}
              text2={employee.employee_position.manager.position?.title ?? ""}
              avatarUrl={employee.employee_position.manager.avatar_url}
            />
          </Link>
        </>
      )}
      {personal.length > 0 && (
        <>
          <Title size={"xs"} header={t("Personal")} paddingTop={true} paddingBottom={true} />
          {personal.map((info, index) => (
            <ListItem
              title={info.title}
              caption={info.caption}
              value={info.value}
              key={`personal-${index}`}
            />
          ))}
        </>
      )}
      {contacts.length > 0 && (
        <>
          <Title size={"xs"} header={t("Contacts")} paddingTop={true} paddingBottom={true} />
          {contacts.map((contact, index) => (
            <ListItem title={contact.title} caption={contact.caption} key={`contact-${index}`} />
          ))}
        </>
      )}
      {address.length > 0 && (
        <>
          <Title size={"xs"} header={t("Address")} paddingTop={true} paddingBottom={true} />
          {address.map((item, index) => (
            <ListItem title={item.title} caption={item.caption} key={`address-${index}`} />
          ))}
        </>
      )}
    </Stack>
  );
}
