import Stack from "../../../ui/stack/Stack.tsx";
import { EventContentArg } from "@fullcalendar/core";
import { ApiEvent } from "../../../types/event.types.ts";
import { useMemo } from "react";
import { EventPreviewModal } from "../../../components/event/EventPreviewModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";

export interface EventInCalendarProps {
  eventInfo: EventContentArg;
}

export function EventInCalendar({ eventInfo }: EventInCalendarProps) {
  const modalsService = useModals();
  const myEvent = useMemo(() => {
    return eventInfo.event.extendedProps as ApiEvent;
  }, [eventInfo]);

  const handleClick = () => {
    myEvent.owner &&
      modalsService.openModal(EventPreviewModal, {
        event: myEvent,
        organizer: myEvent.owner,
      });
  };

  return (
    <Stack
      onClick={handleClick}
      direction={"vertical"}
      className={"flex h-full py-1 px-2 bg-brand-dark rounded-md cursor-pointer"}
    >
      <div className={"text-sm text-light"}>{eventInfo.event.title}</div>
      {myEvent && <div className={"text-sm text-light/60"}>{myEvent.comment}</div>}
    </Stack>
  );
}
