import { api } from "./axios.ts";
import { ApiEmailIntegration, ApiEmailLoginUrl } from "../types/email.types.ts";

export const apiEmails = {
  disconnectIntegration: (email: string) => {
    return api.delete(`/api/v1/email/disconnect/${encodeURIComponent(email)}`);
  },
  /*
   * Api could be called without query.  This function is used to get the login URL for the email integration
   */
  getIntegrationLoginUrl: () => {
    return api.get<ApiEmailLoginUrl>(`/api/v1/email/login`);
  },
  checkIntegration: () => {
    return api.get<ApiEmailIntegration[]>(`/api/v1/email/check`);
  },
};
