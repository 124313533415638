import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui/table/Table.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { useEffectOnce } from "react-use";
import { useLocationDelete, useLocations } from "../../../queries/use-locations.query.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import { ApiLocation } from "../../../types/location.types.ts";
import { LocationEditModal } from "./widgets/LocationEditModal.tsx";

export default function Locations() {
  const { t } = useTranslation();
  const { renderLocationAddress } = useFormatter();

  const modalsService = useModals();

  const { data: locations, isLoading, isFetching } = useLocations();
  const locationDelete = useLocationDelete();

  const columnHelper = createColumnHelper<ApiLocation>();
  const columns = useMemo<ColumnDef<ApiLocation>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("Address"),
        cell: (row) => (
          <Text className={"text-secondary whitespace-pre-wrap"}>
            {renderLocationAddress(row.row.original)}
          </Text>
        ),
        accessorKey: "address",
        size: "auto" as unknown as number,
      },
      {
        header: t("Timezone"),
        cell: (row) => <Text className={"text-secondary"}>{row.row.original.timezone}</Text>,
        accessorKey: "timezone",
        size: "auto" as unknown as number,
      },
      {
        header: t("Employees"),
        cell: (row) => <div className={"text-right px-2"}>{row.row.original.employees_count}</div>,
        accessorKey: "employees_count",
        size: 100,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [locations]
  );

  const handleEditOrCreate = useCallback((location: ApiLocation | undefined = undefined) => {
    const locationEdit = location || {
      id: 0,
      title: "",

      address_line_1: undefined,
      address_line_2: undefined,
      city: undefined,
      country: {
        id: 0,
        title: "",
      },
      state: undefined,
      zip_code: undefined,
      employees_count: 0,
    };
    modalsService.openModal(LocationEditModal, {
      location: locationEdit,
    });
  }, []);

  const handleDeleteAction = useCallback((location: ApiLocation) => {
    locationDelete.mutate(location.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.locations.deleted, {
          [analyticProperties.id]: location.id,
        });
      },
    });
  }, []);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.locations.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack title={t("Locations")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={locations || []}
            columns={columns}
            isFetching={isFetching || locationDelete.isPending}
            showSkeleton={isLoading}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
