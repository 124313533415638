import { ComponentProps } from "react";
import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface TextareaProps extends ComponentProps<"textarea"> {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
  error?: string;
}

export interface UIKitTextareaTheme extends UIKitThemeBase {
  readonly: string;
  disabled: string;
  hover: string;
  cover: {
    base: string;
    hover: string;
    disabled: string;
    readonly: string;
  };
  label: {
    base: string;
    focused: string;
    usual: string;
    disabled: string;
    readonly: string;
  };
  placeholder: {
    base: string;
    shown: string;
  };
  textarea: {
    base: string;
    hover: string;
    disabled: string;
    readonly: string;
  };
}

export const textareaTheme: UIKitTextareaTheme = {
  base: "py-2 px-2 rounded-md bg-light ",
  readonly: "text-secondary",
  disabled: "",
  hover: "",
  cover: {
    base: "relative border-b border-dark/10 pt-6 pb-2",
    disabled: "bg--dark/5",
    hover: "",
    readonly: "",
  },
  placeholder: {
    base: "absolute left-2 top-6 h-5 leading-5 ml-1 text-dark/70 opacity-0 transition-opacity select-none pointer-events-none",
    shown: "opacity-100",
  },
  label: {
    base: "absolute transition-all text-dark/70 select-none pointer-events-none",
    focused: "top-2 text-sm",
    usual: "top-4 text-md leading-5",
    disabled: "text-dark/50",
    readonly: "",
  },
  textarea: {
    base: "w-full bg-none border-none bg-transparent outline-0 font-semibold text-dark box-content h-4 text-md",
    disabled: "text-dark/50 pointer-events-none",
    readonly: "text-dark/70 pointer-events-none",
    hover: "",
  },
};
