import Login from "./pages/security/Login.tsx";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import AnonymousLayout from "./layouts/AnonymousLayout.tsx";
import AppLayout from "./layouts/AppLayout.tsx";
import { FunctionComponent, ReactElement } from "react";
import { useAuthStore } from "./stores/employee.store.ts";
import Settings from "./pages/settings/index/Settings.tsx";
import { Toaster } from "react-hot-toast";
import Divisions from "./pages/settings/divisions/Divisions.tsx";
import Departments from "./pages/settings/departments/Departments.tsx";
import Positions from "./pages/settings/positions/Positions.tsx";
import SettingsOrganization from "./pages/settings/organization/SettingsOrganization.tsx";
import ModalService from "./ui/modal/modal-service.tsx";
import Signup from "./pages/security/Signup.tsx";
import JobsIndex from "./pages/recruit/jobs/JobsIndex.tsx";
import Applicants from "./pages/recruit/candidates/Applicants.tsx";
import Employees from "./pages/employees/index/Employees.tsx";
import NotFound from "./pages/NotFound.tsx";
import { AccountSettings } from "./pages/me/AccountSettings.tsx";
import Pipelines from "./pages/settings/recruit/pipelines/Pipelines.tsx";
import Sources from "./pages/settings/recruit/sources/Sources.tsx";
import { CandidateDisqualificationReasons } from "./pages/settings/recruit/disqualification-reasons/CandidateDisqualificationReasons.tsx";
import EmailConfirmed from "./pages/security/EmailConfirmed.tsx";
import ForgotPassword from "./pages/security/ForgotPassword.tsx";
import ResetPassword from "./pages/security/ResetPassword.tsx";
import Invite from "./pages/security/Invite.tsx";
import Labels from "./pages/settings/recruit/labels/Labels.tsx";
import { RecruitingStageFlowReport } from "./pages/recruit/reporting/stage-flow/RecruitingStageFlowReport.tsx";
import RolesV2 from "./pages/settings/roles/index/Roles.tsx";
import { RolesEdit } from "./pages/settings/roles/edit/RoleEdit.tsx";
import { useEmployeeMe } from "./queries/use-employees.query.ts";
import ScreenLoader from "./components/screen-loader/ScreenLoader.tsx";
import { EmployeePage } from "./pages/employees/employee/EmployeePage.tsx";
import { RecruitingStagesFlowReport } from "./pages/recruit/reporting/stage-flow/RecruitingStagesFlowReport.tsx";
import { RecruitingClosingTimeReport } from "./pages/recruit/reporting/closing-time/RecruitingClosingTimeReport.tsx";
import { EmployeesChart } from "./pages/employees/chart/EmployeesChart.tsx";
import { Calendar } from "./pages/calendar/Calendar.tsx";
import { RecruitingSourceEfficiencyReport } from "./pages/recruit/reporting/source-efficiency/RecruitingSourceEfficiencyReport.tsx";
import Locations from "./pages/settings/locations/Locations.tsx";
import LegalEntities from "./pages/settings/legal-entities/LegalEntities.tsx";
import { DepartmentsChart } from "./pages/departments/chart/DepartmentsChart.tsx";

const RequireAuth: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
  const { token } = useAuthStore();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export interface ProtectedRouteProps {
  isAllowed: boolean;
  // redirectTo?: string;
  children: ReactElement;
}

export function ProtectedRoute({
  isAllowed,
  // redirectTo = "/login",
  children,
}: ProtectedRouteProps) {
  if (!isAllowed) {
    //return <Navigate to={redirectTo} />;
    return <NotFound />;
  }
  return children ? children : <Outlet />;
}

function App() {
  const { isLoading } = useEmployeeMe();

  if (isLoading) {
    return <ScreenLoader />;
  }

  const authenticatedRoutes = (
    <Route
      element={
        <RequireAuth>
          <AppLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Navigate to="/employees" />} />

      <Route path={"/recruitment"}>
        <Route path="jobs" element={<JobsIndex />} />
        <Route path="candidates">
          <Route index element={<Applicants />} />
          <Route path={":id"} element={<Applicants />} />
        </Route>
        <Route path="reporting">
          <Route path={"stages-flow-report"} element={<RecruitingStagesFlowReport />} />
          <Route path={"stage-flow-report"} element={<RecruitingStageFlowReport />} />
          <Route path={"closing-time-report"} element={<RecruitingClosingTimeReport />} />
          <Route path={"source-efficiency-report"} element={<RecruitingSourceEfficiencyReport />} />
        </Route>
      </Route>

      <Route path={"/employees"}>
        <Route index element={<Employees />} />
        <Route path={"chart"} element={<EmployeesChart />} />
        <Route path={":id"} element={<EmployeePage />} />
        <Route path={":id/:section"} element={<EmployeePage />} />
      </Route>

      <Route path={"/departments"}>
        <Route path={"chart"} element={<DepartmentsChart />} />
      </Route>

      <Route path={"/calendar"}>
        <Route index element={<Calendar />} />
      </Route>

      <Route path="/settings">
        <Route index element={<Settings />} />
        <Route path="divisions" element={<Divisions />} />
        <Route path="departments" element={<Departments />} />
        <Route path="organization" element={<SettingsOrganization />} />
        <Route path="positions" element={<Positions />} />
        <Route path="pipelines" element={<Pipelines />} />
        <Route path="sources" element={<Sources />} />
        <Route path="labels" element={<Labels />} />
        <Route path="disqualification-reasons" element={<CandidateDisqualificationReasons />} />
        <Route path="locations" element={<Locations />} />
        <Route path="legal-entities" element={<LegalEntities />} />
        <Route path="roles-and-permissions">
          <Route index element={<RolesV2 />} />
          <Route path="new" element={<RolesEdit />} />
          <Route path=":id" element={<RolesEdit />} />
          <Route path="edit/:id" element={<RolesEdit />} />
          <Route path="employees" element={<RolesV2 tab={"employees"} />} />
        </Route>
      </Route>

      <Route path="account-settings" element={<AccountSettings />} />
      <Route path="email-confirmed" element={<EmailConfirmed />} />
      <Route path="/*" element={<NotFound />} />
    </Route>
  );

  return (
    <>
      <Routes>
        <Route element={<AnonymousLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/invite/:token" element={<Invite />} />
        </Route>
        {authenticatedRoutes}
      </Routes>
      <Toaster
        position={"top-center"}
        containerClassName=""
        toastOptions={{
          // Define default options
          duration: 4000,
        }}
      />
      <ModalService />
    </>
  );
}

export default App;
