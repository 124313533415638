import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { sendTokenToExtension } from "../../../helpers/send-token-to-extension.ts";
import { useAuthStore } from "../../../stores/employee.store.ts";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import { ModalProps } from "../../../ui/modal/modal.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import Modal from "../../../ui/modal/modal.tsx";
import { Input } from "../../../ui/input/Input.tsx";
import Button from "../../../ui/button/Button.tsx";
import useErrorHandle, { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import { useEmployeeMeInvalidate } from "../../../queries/use-employees.query.ts";
import { apiEmployees } from "../../../api";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { useState } from "react";

export function ChangePasswordModal({ ...props }: ModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { setToken } = useAuthStore();
  const { showInfo } = useToasts();
  const { handleErrors } = useErrorHandle();

  const ChangePasswordSchemaObject = yup.object({
    password: yup.string().required(t("Password is required")),
  });

  interface ChangePasswordSchema extends yup.InferType<typeof ChangePasswordSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ChangePasswordSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ChangePasswordSchemaObject),
  });
  const employeeMeInvalidate = useEmployeeMeInvalidate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (loginData) => {
    setLoading(true);
    const { data, error } = await apiEmployees.changePassword(loginData.password);
    setLoading(false);
    if (data) {
      setToken(data.access_token);
      sendTokenToExtension(data.access_token);
      employeeMeInvalidate();
      showInfo(t("Your password has been successfully updated"));
      analyticsService.trackEvent(analyticEvents.passwordChanged);
      close(id);
    } else {
      handleErrors(error, control);
    }
  });

  return (
    <>
      <Modal
        {...props}
        title={t("Change password")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button type={"submit"} size={"lg"} isLoading={isLoading} onClick={onSubmit}>
              {t("Change")}
            </Button>
          </Stack>
        }
      >
        <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
          <Input
            label={t("Password")}
            type="password"
            {...register("password")}
            placeholder="••••••••"
            error={errors.password?.message}
          />
        </form>
      </Modal>
    </>
  );
}
