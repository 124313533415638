import Avatar from "../../ui/avatar/Avatar.tsx";
import { ApiEmployeeShort } from "../../types/employee.types.ts";
import { AvatarSizes } from "../../ui/avatar/avatar.types.ts";
import { useMemo } from "react";
import useFormatter from "../../hooks/use-formatter.hook.ts";

export interface EmployeeAvatarProps {
  employee: ApiEmployeeShort;
  size?: keyof AvatarSizes;
  onLoad?: () => void;
}

export const EmployeeAvatar = ({ employee, size, onLoad }: EmployeeAvatarProps) => {
  const { renderEmployeeName } = useFormatter();

  const acronym = useMemo(() => {
    const renderedName = renderEmployeeName(employee);
    return renderedName;
  }, [employee]);

  return <Avatar url={employee.avatar_url} size={size} onLoad={onLoad} acronym={acronym} />;
};
