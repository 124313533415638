import { api } from "./axios.ts";
import {
  ApiOrganizationSettings,
  ApiOrganizationSettingsSavePayload,
} from "../types/organization.types.ts";

export const apiOrganization = {
  getSettings: async function () {
    return api.get<ApiOrganizationSettings>(`/api/v1/settings/general`);
  },
  updateSettings: async function (settings: ApiOrganizationSettingsSavePayload) {
    return api.put<ApiOrganizationSettings>(`/api/v1/settings/general`, settings);
  },
  setLogo: async function (fileId: number) {
    return api.put<ApiOrganizationSettings>(`/api/v1/settings/general/logo`, {
      file_id: fileId,
    });
  },
  deleteLogo: async function () {
    return api.delete<ApiOrganizationSettings>(`/api/v1/settings/general/logo`);
  },
};
