import { ReactNode } from "react";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import { Link } from "react-router-dom";

export interface ListItemProps {
  preTitle?: string;
  title?: string;
  caption?: string;
  value?: string;

  preTitleSlot?: ReactNode;
  titleSlot?: ReactNode;
  captionSlot?: ReactNode;
  valueSlot?: ReactNode;

  to?: string;
}

export const ListItem = ({
  preTitle,
  preTitleSlot,
  title,
  titleSlot,
  caption,
  captionSlot,
  value,
  valueSlot,
  to,
}: ListItemProps) => {
  const listItem = (
    <Stack
      justify={"between"}
      className={"py-2 w-full border-b border-dark/10 min-h-14"}
      items={"center"}
      direction={"horizontal"}
    >
      <Stack direction={"vertical"}>
        {preTitle && <Text className={"text-secondary"}>{preTitle}</Text>}
        {title && <Text className={"text-title"}>{title}</Text>}
        {caption && <Text className={"text-secondary"}>{caption}</Text>}
        {preTitleSlot && preTitleSlot}
        {titleSlot && titleSlot}
        {captionSlot && captionSlot}
      </Stack>
      {value && <Text className={"text-default"}>{value}</Text>}
      {valueSlot && valueSlot}
    </Stack>
  );

  return (
    <>
      {to ? (
        <Link to={to} target="_blank">
          {listItem}
        </Link>
      ) : (
        listItem
      )}
    </>
  );
};
