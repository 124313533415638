import { ChartNodeData } from "./Chart.tsx";
import { UserCard } from "../user-card/UserCard.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Button from "../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { twMerge } from "tailwind-merge";

export interface ChartNodeProps {
  expanded: boolean;
  data: ChartNodeData;
}

// Don't use any relative styles for children nodes
// https://stackoverflow.com/questions/60980732/position-relative-is-not-working-in-safari-11-dagre-d3-svg
export function ChartNode({ data, expanded = false }: ChartNodeProps) {
  return (
    <Stack
      className={twMerge(
        "rounded-2xl p-4 bg-light h-full structure-item border-2 border-transparent transition-all",
        "hover:border-dark/10",
        expanded && "border-dark hover:border-dark"
      )}
      direction={"vertical"}
      justify={"between"}
      items={"center"}
      gap={"sm"}
      id={`structure-node__${data.id}`}
      data-id={data.id}
    >
      <UserCard
        title={data.title}
        size={"sm"}
        caption={data.caption}
        avatarUrl={data.img_url ?? ""}
        textEllipsis={true}
      />
      {data.count ? (
        <Button
          className={"chart-expand-button"}
          data-id={data.id}
          variant={expanded ? "primary" : "secondary"}
          rightIcon={
            expanded ? (
              <FontAwesomeIcon icon="fa-light fa-chevron-down" />
            ) : (
              <FontAwesomeIcon icon="fa-light fa-chevron-right" />
            )
          }
        >
          {data.count}
        </Button>
      ) : (
        ""
      )}
    </Stack>
  );
}
