import React from "react";

import { create } from "zustand";
import { ModalComponent, ModalProps } from "./modal.types.ts";
import { generateGuid } from "../utils/guid.ts";

export interface ModalStore {
  modals: ModalComponent[];
  open: (modal: ModalComponent) => void;
  close: (id: string) => void;
}

export const useModalStore = create<ModalStore>((set, get) => ({
  modals: [],
  open: (modal: ModalComponent) => set((state) => ({ modals: [...state.modals, modal] })),
  close: (id: string) => {
    const modal = get().modals.find((x) => x.props.id === id);
    if (modal) modal.props.onClose?.();
    set((state) => ({ modals: state.modals.filter((x) => x.props.id !== id) }));
  },
}));

export default function useModals() {
  // https://github.com/mattjennings/react-modal-stack/
  const { open, close } = useModalStore();

  function openModal<T extends ModalProps, P extends T>(
    component: React.ComponentType<T>,
    props?: Omit<P, keyof ModalProps> & { onClose?: () => void }
  ) {
    const newProps: ModalProps = {
      ...props,
      id: generateGuid(),
      open: true,
    };

    open({
      component: component,
      props: newProps,
    } as ModalComponent);
  }

  return {
    openModal,
    close,
  };
}
