import Button from "../../ui/button/Button.tsx";
import Stack from "../../ui/stack/Stack.tsx";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { apiEmployees } from "../../api";
import { useTranslation } from "react-i18next";
import { Input } from "../../ui/input/Input.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../../stores/employee.store.ts";
import { sendTokenToExtension } from "../../helpers/send-token-to-extension.ts";
import analyticsService, { analyticEvents } from "../../services/analytics-service.ts";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import { Title } from "../../components/title/Title.tsx";
import { useEmployeeMeInvalidate } from "../../queries/use-employees.query.ts";
import Box from "../../ui/box/Box.tsx";
import Text from "../../ui/typography/Text.tsx";

export default function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();
  const { handleErrors } = useErrorHandle();

  const { setToken } = useAuthStore();

  const ResetPasswordSchemaObject = yup.object({
    password: yup.string().required(t("Password is required")),
  });

  interface ResetPasswordSchema extends yup.InferType<typeof ResetPasswordSchemaObject> {
    // using interface instead of type generally gives nicer editor feedback
  }

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ResetPasswordSchemaObject),
  });

  const employeeMeInvalidate = useEmployeeMeInvalidate();
  const onSubmit = handleSubmit(async (loginData) => {
    if (!token) return;

    setLoading(true);
    const { data, error } = await apiEmployees.resetPassword(token);
    if (data) {
      setToken(data.access_token);
      const { data: changePasswordData, error: changePasswordError } =
        await apiEmployees.changePassword(loginData.password);
      if (changePasswordData) {
        setToken(changePasswordData.access_token);
        sendTokenToExtension(changePasswordData.access_token);
        employeeMeInvalidate();
        analyticsService.trackEvent(analyticEvents.passwordReset);
        navigate("/");
      } else {
        handleErrors(changePasswordError, control);
      }
    } else {
      handleErrors(error, control);
    }
    setLoading(false);
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <FullScreenCard>
      <Stack gap={"sm"} className={"w-full sm:max-w-md "}>
        <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
          <Stack gap={"2xl"}>
            <a href="https://skailer.com" className="flex items-center mb-6 text-2xl font-semibold">
              <img className="w-8 h-8 mr-2  " src="/skailer-dark-solid.svg" alt="logo" />
              <Text size="3xl">skailer</Text>
            </a>
            <Stack gap={"lg"}>
              <Title header={t("Reset password")} size={"lg"} />
              <form onSubmit={onSubmit}>
                <Input
                  label={t("Password")}
                  type="password"
                  {...register("password")}
                  placeholder="••••••••"
                  error={errors.password?.message}
                />
              </form>
              <ServerErrorField errors={errors} />
              <Button
                type={"submit"}
                size="lg"
                onClick={onSubmit}
                className="w-full"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {t("Reset")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </FullScreenCard>
  );
}
