import { useEffect, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import { HierarchyNode, select } from "d3";
import { renderToStaticMarkup } from "react-dom/server";
import { ChartNode } from "./ChartNode.tsx";

export interface ChartNodeData {
  id: string;
  parentId: string | null;
  title: string;
  caption?: string;
  img_url?: string | null;
  count: number;
  isRoot: boolean;
}

export interface ChartProps {
  data: ChartNodeData[];
  expandedSet?: Set<string>;
  onTitleClick?: (nodeId: string) => void;
  onExpandClick?: (nodeId: string, needLoadData: boolean) => void;
}

export function Chart({ data, onTitleClick, onExpandClick, expandedSet }: ChartProps) {
  const d3Container = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<OrgChart<ChartNodeData> | null>(null);
  const [expanded, setExpanded] = useState<Set<string>>(expandedSet ?? new Set([]));
  const [collapsed, setCollapsed] = useState<Set<string>>(new Set([]));
  const [rootCollapsed, setRootCollapsed] = useState<boolean>(false);

  useEffect(() => {
    setChart(() => {
      return new OrgChart<ChartNodeData>();
    });
  }, []);

  useEffect(() => {
    if (!d3Container.current || !chart) return;

    d3Container.current?.querySelectorAll(".structure-item .user-title").forEach((button) => {
      button instanceof HTMLElement && button.removeEventListener("click", onTitleClickAction);
    });

    chart
      .container(d3Container.current as unknown as string)
      .svgHeight(d3Container.current.getBoundingClientRect().height)
      .data(
        data.filter((item) => {
          const isCollapsed = !collapsed.has(item.parentId ?? "") && !rootCollapsed;

          return isCollapsed || item.isRoot;
        })
      )
      .linkUpdate((_node, _index, nodes) => {
        nodes.forEach((nodeChild) => {
          select(nodeChild as unknown as SVGPathElement).attr("stroke", "#1B1D2133");
        });
      })
      .compact(false)
      .nodeWidth(() => 164)
      // .nodeHeight(() => 198)
      .nodeHeight((node) => {
        return node.data.count > 0 ? 198 : 168;
      })
      .buttonContent(() => "")
      .nodeButtonHeight(() => 0)
      .nodeContent((node) => {
        return renderToStaticMarkup(
          <ChartNode expanded={node.data.isRoot || expanded.has(node.data.id)} data={node.data} />
        );
      })
      .onNodeClick((node: HierarchyNode<ChartNodeData>) => {
        node.id && clickOnExpand(node.id, node.data.isRoot);
      })
      .render();

    chart.expandAll().render();

    d3Container.current?.querySelectorAll(".structure-item .user-title").forEach((button) => {
      button instanceof HTMLElement && button.addEventListener("click", onTitleClickAction);
    });

    updateDOMElementsAfterRender();
  }, [data, chart, collapsed, rootCollapsed]);

  const updateDOMElementsAfterRender = () => {
    const rootNode = document.querySelector("#structure-node__root");

    rootNode && updateDOMElement(rootNode, rootCollapsed);

    expanded.forEach((id) => {
      const node = document.querySelector("#structure-node__" + id);

      node && updateDOMElement(node, collapsed.has(id));
    });
  };

  const updateDOMElement = (element: Element, isCollapsed: boolean) => {
    const btn = element.querySelector(".chart-expand-button");
    const icon = btn?.querySelector(".fa-light");

    if (isCollapsed) {
      element.classList.remove("border-dark", "hover:border-dark");
      element.classList.add("border-transparent", "hover:border-dark/10");

      btn?.classList.remove("bg-brand-dark", "text-light");
      btn?.classList.add("bg-brand-dark/5", "text-brand-dark", "hover:bg-brand-dark/10");

      icon?.classList.remove("fa-chevron-down");
      icon?.classList.add("fa-chevron-right");
    } else {
      element.classList.remove("hover:border-dark/10", "border-transparent");
      element.classList.add("border-dark", "hover:border-dark");

      btn?.classList.add("bg-brand-dark", "text-light");
      btn?.classList.remove("bg-brand-dark/5", "text-brand-dark", "hover:bg-brand-dark/10");

      icon?.classList.add("fa-chevron-down");
      icon?.classList.remove("fa-chevron-right");
    }
  };

  const onTitleClickAction = (event: MouseEvent) => {
    event.stopPropagation();

    const target: HTMLElement = event.currentTarget as HTMLElement;
    const item = target.closest(".structure-item");
    const dataId = item?.getAttribute("data-id");

    onTitleClick && dataId && onTitleClick(dataId);
  };

  const clickOnExpand = (dataId: string, isRoot: boolean) => {
    let needLoadBatch = false;

    if (!dataId) return;

    if (isRoot) {
      needLoadBatch = false;

      setRootCollapsed((prevState) => !prevState);

      if (rootCollapsed) {
        chart?.expandAll().render();
      } else {
        chart?.collapseAll().render();
      }
    } else {
      const newCollapsed = new Set(collapsed);
      const newExpanded = new Set(expanded);

      if (collapsed.has(dataId)) {
        newCollapsed.delete(dataId);
      } else if (!expanded.has(dataId) && !collapsed.has(dataId)) {
        needLoadBatch = true;

        newExpanded.add(dataId);
      } else if (expanded.has(dataId) && !collapsed.has(dataId)) {
        // здесь надо скрыть все узлы связанные с dataId
        newCollapsed.add(dataId);

        getChildrenIds(dataId).forEach((id) => {
          newCollapsed.add(id);
        });
      }

      setExpanded(newExpanded);
      setCollapsed(newCollapsed);
    }

    onExpandClick && onExpandClick(dataId, needLoadBatch);
  };

  const getChildrenIds = function getChildrenIds(targetId: string): string[] {
    const childrenIds: string[] = [];

    function findChildren(parentId: string) {
      for (const node of data) {
        if (node.parentId != parentId) continue;

        childrenIds.push(node.id);

        findChildren(node.id);
      }
    }

    findChildren(targetId);

    return childrenIds;
  };

  return <div className={"h-full"} ref={d3Container} />;
}
