import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/employee.store.ts";
import Button from "../../ui/button/Button.tsx";
import getTimezones from "../../services/timezone-service.ts";
import { ApiUserSignupPayload } from "../../types/employee.types.ts";
import { sendTokenToExtension } from "../../helpers/send-token-to-extension.ts";
import { Input } from "../../ui/input/Input.tsx";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { useEmployeeMeInvalidate } from "../../queries/use-employees.query.ts";
import { Title } from "../../components/title/Title.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import { apiEmployees } from "../../api";
import { useEffectOnce } from "react-use";
import Box from "../../ui/box/Box.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";

export default function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken } = useAuthStore();
  const { handleErrors } = useErrorHandle();

  const signupSchema = yup.object({
    email: yup.string().email(t("Must be a valid email")).required(t("Email is required")),
    password: yup.string().required(t("Password is required")),
    firstName: yup.string().required(t("First name is required")),
    lastName: yup.string().required(t("Last name is required")),
    organizationTitle: yup.string().required(t("Organization title is required")),
    jobTitle: yup.string().required(t("Job title is required")),
  });

  interface SignupSchema extends yup.InferType<typeof signupSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignupSchema>({
    resolver: yupResolver(signupSchema),
    mode: "onSubmit",
  });

  const [timezoneAbbreviation, setTimezone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const employeeMeInvalidate = useEmployeeMeInvalidate();

  useEffectOnce(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezones = getTimezones();
    // const matchedTimezone = timezones.find((tz) => tz.utc.includes(userTimezone));
    const matchedTimezone = timezones.find((tz) => tz.id == userTimezone);
    setTimezone(matchedTimezone ? matchedTimezone.id : "(UTC) Coordinated Universal Time");
  });

  const onSubmit = handleSubmit(async (data) => {
    const enhancedData: ApiUserSignupPayload = {
      username: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      organization_title: data.organizationTitle,
      job_title: data.jobTitle,
      language: navigator.language.slice(0, 2),
      timezone: timezoneAbbreviation,
    };

    setIsLoading(true);
    const { data: employee, error } = await apiEmployees.signUp(enhancedData);
    setIsLoading(false);
    if (employee) {
      setToken(employee.access_token);
      sendTokenToExtension(employee.access_token);
      employeeMeInvalidate();

      navigate("/");
    } else {
      handleErrors(error, control);
    }
  });
  return (
    <FullScreenCard>
      <Stack gap={"sm"} className={"w-full sm:max-w-md "}>
        <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
          <Stack gap={"2xl"}>
            <a href="https://skailer.com" className="flex items-center mb-6 text-2xl font-semibold">
              <img className="w-8 h-8 mr-2  " src="/skailer-dark-solid.svg" alt="logo" />
              <Text size="3xl">skailer</Text>
            </a>
            <Stack gap={"lg"}>
              <Title
                header={t("Create new account")}
                size={"lg"}
                caption={`${t("Just one form and you're in")}`}
              />
              <form className="" onSubmit={onSubmit}>
                <Input
                  label={t("Your email")}
                  type="email"
                  {...register("email")}
                  error={errors.email?.message}
                />

                <Input
                  label={t("Password")}
                  type="password"
                  {...register("password")}
                  error={errors.password?.message}
                />

                <Input
                  label={t("First name")}
                  type="text"
                  {...register("firstName")}
                  error={errors.firstName?.message}
                />

                <Input
                  label={t("Last name")}
                  type="text"
                  {...register("lastName")}
                  error={errors.lastName?.message}
                />
                <Input
                  label={t("Organization Title")}
                  type="text"
                  {...register("organizationTitle")}
                  error={errors.organizationTitle?.message}
                />
                <Input
                  label={t("Job Title")}
                  type="text"
                  {...register("jobTitle")}
                  error={errors.jobTitle?.message}
                />
              </form>
            </Stack>
          </Stack>
        </Box>
        <ServerErrorField errors={errors} />
        <Button
          type={"submit"}
          size="lg"
          className="w-full"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
        >
          {t("Sign up")}
        </Button>
        <Box className={"text-secondary"}>
          <Text>{t("By signing up, you agree with Skailer's")}</Text>{" "}
          <LinkTo to={"https://skailer.com/terms"} target={"_blank"}>
            {t("Terms of Service")}
          </LinkTo>{" "}
          <Text>{t("and")}</Text>{" "}
          <LinkTo to={"https://skailer.com/privacy"} target={"_blank"}>
            {t("Privacy Policy")}
          </LinkTo>
        </Box>
      </Stack>
    </FullScreenCard>
  );
}
