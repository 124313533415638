import { ApiRole, RoleApplyRule } from "../../../../../types/role.types.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import { useCallback } from "react";
import { RuleEditModal } from "../widgets/RuleEditModal.tsx";
import { Control, useFieldArray, UseFormGetValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { EditRoleSchema, RoleApplyToConditionsItems } from "../role-edit.types.ts";
import useRoleEditService from "../role-edit-service.ts";
import { Title } from "../../../../../components/title/Title.tsx";

export interface RoleEditApplyToSectionProps {
  role?: ApiRole | undefined;
  register: UseFormRegister<EditRoleSchema>;
  control: Control<EditRoleSchema>;
  getValues: UseFormGetValues<EditRoleSchema>;
}

export function RoleEditApplyToSection({ role, control, getValues }: RoleEditApplyToSectionProps) {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { getApplyCardTitle, getAssignCardDescription } = useRoleEditService();

  const {
    append: applyToAppend,
    remove: applyToRemove,
    update: applyToUpdate,
  } = useFieldArray({
    control,
    name: "apply_to_list",
  });

  const handleEditApplyTo = useCallback(
    (rule?: RoleApplyRule | undefined, index?: number | undefined) => {
      modalsService.openModal(RuleEditModal, {
        rule,
        conditionTypes: RoleApplyToConditionsItems(),
        onModified(modifiedRule: RoleApplyRule) {
          if (index !== undefined) {
            role && applyToUpdate(index, modifiedRule);
          } else {
            applyToAppend(modifiedRule);
          }
        },
        onDelete() {
          index !== undefined && applyToRemove(index);
        },
      });
    },
    [role]
  );

  return (
    <Stack direction={"vertical"} gap={"md"}>
      <Title
        header={t("Apply to employees")}
        caption={t(
          "Set rules to specify which employees the role will have access to. For example, configure rules to grant access to specific employee fields using criteria such as name, surname, position, or employee ID"
        )}
        size={"xs"}
        paddingBottom
        paddingTop
      />
      <Stack
        direction={"horizontal"}
        gap={"md"}
        className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
      >
        {getValues("apply_to_list")?.map((rule, index) => (
          <SettingsCard
            key={`role-card-${index}`}
            title={getApplyCardTitle(rule.condition_type)}
            description={getAssignCardDescription(rule.sign, rule.condition_entities)}
            onClick={() => {
              handleEditApplyTo(rule as RoleApplyRule, index);
            }}
          />
        ))}

        <SettingsCard
          icon={"fa-plus"}
          title={t("Create new rule")}
          description={""}
          onClick={handleEditApplyTo}
          variant={"action"}
        />
      </Stack>

      {/*<Stack direction={"horizontal"} gap={"md"} items={"center"}>*/}
      {/*  <Button>{t("View 3 people")}</Button>*/}
      {/*  <Stack direction={"horizontal"} items={"center"} gap={"sm"}>*/}
      {/*    <FontAwesomeIcon*/}
      {/*      icon="fa-thin fa-circle-exclamation"*/}
      {/*      className={"text-warning"}*/}
      {/*    />*/}
      {/*    -*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
    </Stack>
  );
}
