import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Box from "../box/Box.tsx";
import Text from "../typography/Text.tsx";
import { RichEditorToolbar } from "./RichEditorToolbar.tsx";
import { Underline } from "@tiptap/extension-underline";
import { Link } from "@tiptap/extension-link";
import "./rich-editor.css";

export interface RichEditorProps {
  value: string;
  onChange?: (value: string) => void;
  error?: string;
  placeholder?: string;
}

// https://codesandbox.io/s/tiptap-0sqm3i?file=/src/components/Toolbar.tsx:676-718
// https://codesandbox.io/s/tiptap-react-jvstn?file=/src/tiptap.tsx

export const RichEditor = ({ value, placeholder, onChange, error }: RichEditorProps) => {
  const extensions = [
    StarterKit,
    Underline,
    Placeholder.configure({
      // Use a placeholder:
      placeholder: placeholder || "",
      // Use different placeholders depending on the node type:
      // placeholder: ({ node }) => {
      //   if (node.type.name === 'heading') {
      //     return 'What’s the title?'
      //   }

      //   return 'Can you add some further context?'
      // },
    }),
    Link.configure({
      linkOnPaste: false,
      openOnClick: false,
    }),
  ];

  const editor = useEditor({
    content: value,
    extensions: extensions,

    // ...
    onUpdate({ editor }) {
      onChange?.(editor.getHTML());
    },
  });

  if (!editor) {
    return null;
  }

  // const isCursorOverLink = editor.getAttributes("link").href;

  return (
    <>
      <Box className={"border border-dark/5 rounded-t-md"}>
        <RichEditorToolbar editor={editor} />
        <EditorContent editor={editor} className={"p-4 rich-editor"} />
        {error && (
          <Box className={"pt-2 pb-4"}>
            <Text className={"text-danger"}>{error}</Text>
          </Box>
        )}
      </Box>
    </>
  );
};
