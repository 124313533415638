import Modal from "../../ui/modal/modal.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import { ConfirmationPopupProps } from "./confirmation-popup.types.ts";
import Text from "../../ui/typography/Text.tsx";
import Button from "../../ui/button/Button.tsx";
import useModals from "../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export function ConfirmationPopup({
  question,
  text,
  acceptTitle,
  onAccept,
  ...props
}: ConfirmationPopupProps) {
  const { id } = { ...props };
  const { close } = useModals();
  const { t } = useTranslation();
  const [acceptingProcess, setAcceptingProcess] = useState<boolean>(false);

  async function onAcceptClick() {
    setAcceptingProcess(true);
    await onAccept?.();
    close(id);
    setAcceptingProcess(false);
  }

  return (
    <Modal
      {...props}
      title={question ?? "Confirmation"}
      withCloser
      actions={
        <Stack gap={"smd"}>
          <Button isLoading={acceptingProcess} onClick={onAcceptClick} size={"lg"}>
            {acceptTitle ?? t("Accept")}
          </Button>
          <Button
            onClick={() => {
              close(id);
            }}
            variant={"plain"}
            size={"lg"}
          >
            {t("Cancel")}
          </Button>
        </Stack>
      }
    >
      <Text size={"text-sm"}>{text}</Text>
    </Modal>
  );
}
