import useModals from "../../../../ui/modal/modal.store.ts";

import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ApiDivision } from "../../../../types/division.types.ts";
import * as yup from "yup";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import { useDivisionSave } from "../../../../queries/use-divisitions.query.ts";
import i18n from "../../../../i18n.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";

export interface DivisionEditModalProps extends ModalProps {
  division: ApiDivision;
}

export function DivisionEditModal({ division, ...props }: DivisionEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const divisionSchema = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
  });

  interface DivisionSchema extends yup.InferType<typeof divisionSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DivisionSchema>({
    mode: "onSubmit",
    resolver: yupResolver(divisionSchema),
  });

  const divisionSave = useDivisionSave(control);

  const onSubmit = handleSubmit((divisionData) => {
    divisionSave.mutate(
      {
        id: division.id,
        title: divisionData.title,
      },
      {
        onSuccess: (data) => {
          if (division.id == 0) {
            analyticsService.trackEvent(analyticEvents.divisions.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.divisions.edited, {
              [analyticProperties.id]: division.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <>
      <Modal
        {...props}
        title={t("Division")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={divisionSave.isPending}
              onClick={onSubmit}
            >
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Input
            required={true}
            label={t("Title")}
            autoFocus={true}
            type="text"
            {...register("title", {
              value: division.title,
            })}
            error={errors.title?.message}
          />
        </form>
      </Modal>
    </>
  );
}
