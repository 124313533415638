import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { SidebarConfigItem } from "../sidebar-service.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import Box from "../../../ui/box/Box.tsx";
import { SidebarItem } from "../components/SidebarItem.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { useJobDetails, useJobsSearch } from "../../../queries/recruit/use-jobs.query.ts";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { ApiJobsFilterDefault, getJobEmoji, JobStatus } from "../../../types/recruit/job.types.ts";
import Avatar from "../../../ui/avatar/Avatar.tsx";
import PageHeader from "../../page/PageHeader.tsx";
import { Title } from "../../title/Title.tsx";
import { SidebarHeader } from "../components/SidebarHeader.tsx";
import { SidebarControls } from "../components/SidebarControls.tsx";
import {
  getStageEmoji,
  StageDisqualifiedEmoji,
  StageReservedEmoji,
} from "../../../types/recruit/stages.types.ts";
import { parseUrlHelper } from "../../../helpers/parse-url.helper.ts";
import { SidebarRecruitmentBaseMenu } from "./SidebarRecruitmentBaseMenu.tsx";

export const SidebarRecruitmentCandidates = () => {
  const { t } = useTranslation();

  const { employee } = useEmployeeStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stageIds = parseUrlHelper.getNumberArray(searchParams, "stage_id");
  const jobId = parseUrlHelper.getNumberArray(searchParams, "job_id");
  const disqualified = searchParams.get("disqualified") == "true";
  const reserved = searchParams.get("reserved") == "true";

  const jobDetails = useJobDetails(jobId && jobId.length == 1 ? jobId[0] : undefined);

  const { data: jobs, isLoading: jobsIsLoading } = useJobsSearch({
    ...ApiJobsFilterDefault,
    status_list: [JobStatus.published],
    my_jobs: true,
  });

  const currentJobPipelines = useMemo(() => {
    if (!jobDetails.data) return undefined;

    const result: SidebarConfigItem[] = [];
    result.push({
      title: t("All active"),
      to: `/recruitment/candidates/?job_id=${jobDetails.data.id}`,
      isActive: (!stageIds || stageIds.length != 1) && !disqualified && !reserved,
      count:
        jobDetails.data.pipeline.stages.reduce((acc, stage) => {
          acc = acc + stage.applicants_count;
          return acc;
        }, 0) || 0,
    });

    for (let i = 0; i < jobDetails.data.pipeline.stages.length; i++) {
      const stage = jobDetails.data.pipeline.stages[i];
      result.push({
        title: stage.title,
        to: `/recruitment/candidates/?job_id=${jobDetails.data.id}&stage_id=${stage.id}`,
        isActive: (stageIds.length == 1 && stageIds.indexOf(stage.id) !== -1) || false,
        count: stage.applicants_count,
        emoji: getStageEmoji(stage),
      });
    }

    if (jobDetails.data.pipeline.disqualified_candidates_count > 0) {
      result.push({
        title: t("Disqualified"),
        to: `/recruitment/candidates/?job_id=${jobDetails.data.id}&disqualified=true`,
        isActive: disqualified || false,
        count: jobDetails.data.pipeline.disqualified_candidates_count,
        emoji: StageDisqualifiedEmoji,
      });
    }

    if (jobDetails.data.pipeline.reserved_candidates_count > 0) {
      result.push({
        title: t("Reserved"),
        to: `/recruitment/candidates/?job_id=${jobDetails.data.id}&reserved=true`,
        isActive: reserved || false,
        count: jobDetails.data.pipeline.reserved_candidates_count,
        emoji: StageReservedEmoji,
      });
    }

    return result;
  }, [jobDetails.data, stageIds, employee]);

  return (
    <>
      {jobDetails.data ? (
        <>
          <Stack
            className={"h-16 flex items-center px-6"}
            direction={"horizontal"}
            gap={"sm"}
            justify={"between"}
            items={"center"}
          >
            <Button
              onClick={() => {
                navigate("/recruitment/candidates");
              }}
              variant="secondary"
              leftIcon={<FontAwesomeIcon icon="fa-light fa-arrow-left" className={"text-lg"} />}
            >
              {t("Candidates")}
            </Button>
            <SidebarControls />
          </Stack>
          <Box className={"px-6"}>
            <Avatar emoji={getJobEmoji(jobDetails.data)} size={"xl"} variant={"opaque"} />
          </Box>
          <PageHeader title={`${jobDetails.data.title}`} />
        </>
      ) : (
        <>
          <SidebarHeader title={t("Recruit")} />
          <SidebarRecruitmentBaseMenu />
        </>
      )}

      <Stack className={"px-4"}>
        {currentJobPipelines && (
          <>
            <Box className={"px-2"}>
              <Title header={t("Pipeline")} size={"xs"} paddingBottom paddingTop />
            </Box>
            <Stack gap={"xs"}>
              {currentJobPipelines.map((item, index) => (
                <SidebarItem
                  key={`'secondary-menu-pipeline-stage-${index}`}
                  to={item.to}
                  isActive={item.isActive}
                  title={item.title}
                  count={item.count}
                />
              ))}
            </Stack>
          </>
        )}

        {jobsIsLoading && <Skeleton className={"w-full h-5"}></Skeleton>}
        {jobs && (
          <>
            <Box className={"px-2"}>
              <Title header={t("My jobs")} size={"xs"} paddingBottom paddingTop />
            </Box>
            <Stack gap={"xs"}>
              {jobs.items.map((jobItem) => (
                <SidebarItem
                  icon={<Avatar variant={"plain"} emoji={getJobEmoji(jobItem)} size={"xs"} />}
                  key={`'secondary-menu-job-${jobItem.id}`}
                  to={`/recruitment/candidates/?job_id=${jobItem.id}`}
                  isActive={false}
                  title={jobItem.title}
                  count={jobItem.candidates_count}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
