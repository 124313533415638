import useModals from "../../../../ui/modal/modal.store.ts";

import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import i18n from "../../../../i18n.ts";
import { ApiLocation, ApiLocationSavePayload } from "../../../../types/location.types.ts";
import { useCountries, useLocationSave } from "../../../../queries/use-locations.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Select from "../../../../ui/select/Select.tsx";
import getTimezones from "../../../../services/timezone-service.ts";

export interface LocationEditModalProps extends ModalProps {
  location: ApiLocation;
}

export function LocationEditModal({ location, ...props }: LocationEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const locationSchema = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
    address_1: yup.string().nullable(),
    address_2: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    zip: yup.string().nullable(),
    country_id: yup.number().required(i18n.t("Field is required")),
    timezone: yup.string().required(i18n.t("Field is required")),
  });

  interface LocationSchema extends yup.InferType<typeof locationSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LocationSchema>({
    mode: "onSubmit",
    resolver: yupResolver(locationSchema),
    defaultValues: {
      ...location,
      country_id: location.country.id,
    },
  });

  const locationSave = useLocationSave(control);
  const { data: countries } = useCountries();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload: ApiLocationSavePayload = {
        title: data.title,
        address_1: data.address_1,
        address_2: data.address_2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country_id: data.country_id,
        timezone: data.timezone,
      };
      const savedData = await locationSave.mutateAsync({ id: location.id, payload: payload });
      if (location.id == 0) {
        analyticsService.trackEvent(analyticEvents.locations.created, {
          [analyticProperties.id]: savedData.id,
        });
      } else {
        analyticsService.trackEvent(analyticEvents.locations.edited, {
          [analyticProperties.id]: location.id,
        });
      }
      close(id);
    } catch {
      return;
    }
  });
  return (
    <>
      <Modal
        {...props}
        title={t("Location")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={locationSave.isPending}
              onClick={onSubmit}
            >
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Input
            required={true}
            label={t("Title")}
            autoFocus={true}
            type="text"
            {...register("title", {
              value: location.title,
            })}
            error={errors.title?.message}
          />
          <Input
            label={t("Address line 1")}
            type={"text"}
            {...register("address_1", {
              value: undefined,
            })}
            error={errors.address_1?.message}
          />
          <Input
            label={t("Address line 2")}
            type={"text"}
            {...register("address_2", {
              value: undefined,
            })}
            error={errors.address_2?.message}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                options={countries || []}
                emptyTitle={t("Country")}
                searchable
                required
                value={value}
                onChange={onChange}
                error={errors.country_id?.message}
              />
            )}
            control={control}
            name={"country_id"}
          />
          <Input
            label={t("State")}
            type={"text"}
            {...register("state", {
              value: undefined,
            })}
            error={errors.state?.message}
          />
          <Input
            label={t("City")}
            type={"text"}
            {...register("city", {
              value: undefined,
            })}
            error={errors.city?.message}
          />
          <Input
            label={t("Zip code")}
            type={"text"}
            {...register("zip", {
              value: undefined,
            })}
            error={errors.zip?.message}
          />
          <Controller
            control={control}
            name="timezone"
            render={({ field: { value, onChange } }) => (
              <Select
                required
                label={t("Timezone")}
                options={getTimezones()}
                emptyTitle={t("Select")}
                value={value}
                onChange={onChange}
                searchable
                error={errors.timezone?.message}
              />
            )}
          />
        </form>
      </Modal>
    </>
  );
}
