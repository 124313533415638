import { ComponentPropsWithoutRef, ElementType, forwardRef } from "react";
import { PolymorphicRef } from "../../helpers/generic-as-prop.ts";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ButtonIconComponentType, ButtonIconProps, ButtonSizes } from "./button.types.ts";

const ButtonIconComponentFN: ButtonIconComponentType = forwardRef(
  <T extends ElementType = "button">(
    { variant = "primary", size = "md", className, icon, ...props }: ButtonIconProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theirProps = { type: "button", ...props } as ComponentPropsWithoutRef<T>;

    const theme = useTheme("button");
    const sizeTheme: ButtonSizes = {
      sm: "w-6 h-6",
      md: "w-7 h-7",
      lg: "w-10 h-10",
    };

    return (
      <>
        <button
          ref={ref}
          className={twMerge(
            theme.base,
            theme.variants.variant[variant],
            //"shrink-0",
            sizeTheme[size],
            className
          )}
          {...theirProps}
        >
          {icon}
        </button>
      </>
    );
  }
);

const ButtonIcon = ButtonIconComponentFN;
export default ButtonIcon;
