import useModals from "../../../../../ui/modal/modal.store.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Modal from "../../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ApiPipeline,
  ApiPipelineSavePayload,
  ApiPipelineStagePayload,
} from "../../../../../types/recruit/pipeline.types.ts";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import i18n from "../../../../../i18n.ts";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import { useStages } from "../../../../../queries/recruit/use-stages.query.ts";
import { usePipelineSave } from "../../../../../queries/recruit/use-pipelines.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import Text from "../../../../../ui/typography/Text.tsx";
import Table from "../../../../../ui/table/Table.tsx";
import EmptyState from "../../../../../components/empty-state/EmptyState.tsx";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { CheckBox } from "../../../../../ui/checkbox/CheckBox.tsx";
import Select from "../../../../../ui/select/Select.tsx";
import { ApiStageInPipelineEdit, PipelineStageTimeLimits } from "./pipeline-edit-modal.types.ts";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import { Title } from "../../../../../components/title/Title.tsx";

export interface PipelineEditModalProps extends ModalProps {
  pipeline: ApiPipeline;
}

export function PipelineEditModal({ pipeline, ...props }: PipelineEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const pipelineSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
    is_default: yup.boolean().required(),
    stages: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        sla_limit: yup.number().required(),
      })
    ),
  });

  interface PipelineSchema extends yup.InferType<typeof pipelineSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PipelineSchema>({
    mode: "onSubmit",
    resolver: yupResolver(pipelineSchemaObject),
    defaultValues: {
      title: pipeline.title,
      is_default: pipeline.is_default,
      stages:
        pipeline.stages.map((item) => {
          return {
            id: item.id,
            sla_limit: item.sla_limit,
          };
        }) || [],
    },
  });

  const { data: stages } = useStages();
  const pipelineSave = usePipelineSave(control);

  /*
   * This is a helper object to store stages configuration
   */
  const [stagesConfig, setStagesConfig] = useState<ApiStageInPipelineEdit[]>(
    pipeline.stages.map((stage) => {
      return {
        id: stage.id,
        title: stage.title,
        sla_limit: stage.sla_limit.toString(),
        selected: true,
      };
    })
  );

  /*
   * This is a helper object to store selected stages
   */
  const selectedStagesRows = useMemo(() => {
    if (!stages) return {};
    const result: RowSelectionState = {};
    for (let i = 0; i < stages.length; i++) {
      if (stagesConfig.find((stage) => stage.id === stages[i].id)) result[i] = true;
    }
    return result;
  }, [stages]);

  /*
   * This is a helper object to store stages with additional fields
   */
  const stagesInPipelines = useMemo(() => {
    return (
      stages?.map((stage) => {
        const stageConfig = stagesConfig.find((item) => item.id === stage.id);
        const item: ApiStageInPipelineEdit = {
          ...stage,
          sla_limit: stageConfig?.sla_limit || "0",
          selected: stageConfig?.selected || false,
        };
        return item;
      }) || []
    );
  }, [stages, stagesConfig]);

  const onSubmit = handleSubmit((pipelineData) => {
    const stagesWithSorts: ApiPipelineStagePayload[] = stagesConfig
      .filter((stage) => stage.selected)
      .map((stage) => {
        const stagePayload: ApiPipelineStagePayload = {
          id: stage.id,
          sla_limit: parseInt(stage.sla_limit, 10) || 0,
        };
        return stagePayload;
      });

    const payload: ApiPipelineSavePayload = {
      title: pipelineData.title,
      is_default: pipelineData.is_default,
      stages: stagesWithSorts,
    };

    pipelineSave.mutate(
      { id: pipeline.id, payload },
      {
        onSuccess: (data) => {
          if (pipeline.id == 0) {
            analyticsService.trackEvent(analyticEvents.pipelines.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.pipelines.edited, {
              [analyticProperties.id]: pipeline.id,
            });
          }
          close(id);
        },
      }
    );
  });

  const handleRowSelectionChange = (selectedRows: RowSelectionState) => {
    const newStagesConfig = stagesInPipelines.map((stage, index) => ({
      ...stage,
      selected: selectedRows[index.toString()],
    }));
    setStagesConfig(newStagesConfig);
  };

  const handleChangeTimeLimit = (stageId: number, sla_limit: number) => {
    const newStagesConfig = stagesConfig.map((stage) => {
      if (stage.id === stageId) {
        return { ...stage, sla_limit: sla_limit.toString() };
      }
      return stage;
    });
    setStagesConfig(newStagesConfig);
  };

  // const columnHelper = createColumnHelper<ApiStageInPipeline>();
  const columns = useMemo<ColumnDef<ApiStageInPipelineEdit>[]>(
    () => [
      {
        id: "select-col",
        header: ({ table }) => (
          <CheckBox
            checked={table.getIsAllRowsSelected()}
            // indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
          />
        ),
        cell: ({ row }) => (
          <CheckBox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        size: 30,
      },
      {
        header: t("Title"),
        cell: (row) => <Text className={"text-title py-4 block"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("Time limit (days)"),
        cell: (row) =>
          row.row.original.selected && (
            <Select
              variant={"plain"}
              emptyTitle={""}
              options={PipelineStageTimeLimits}
              value={row.row.original.sla_limit}
              onChange={(value) => {
                handleChangeTimeLimit(row.row.original.id, value as number);
              }}
            ></Select>
          ),
        accessorKey: "employees_count",
        size: 200,
      },
    ],
    [stagesInPipelines]
  );
  return (
    <Modal
      {...props}
      title={t("Pipeline")}
      withCloser
      size={"lg"}
      layout={"stretch"}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} isLoading={pipelineSave.isPending} onClick={onSubmit}>
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Input
          label={t("Title")}
          required={true}
          autoFocus={true}
          type="text"
          {...register("title")}
          error={errors.title?.message}
        />
        <Stack direction={"horizontal"} className={"items-center h-14"} gap={"sm"}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Switch checked={value} onChange={onChange} title={t("Default pipeline")} />
            )}
            control={control}
            name={"is_default"}
          />
        </Stack>
      </form>

      <Title
        header={t("Select the stages to be included in the pipeline")}
        size={"md"}
        caption={t(
          "Stage order is managed in the 'Stages' section. To add a new stage, first add it to the 'Stages' section, then activate the checkbox."
        )}
        paddingBottom
        paddingTop
      />

      <Table
        data={stagesInPipelines || []}
        rowSelection={selectedStagesRows}
        onRowSelectionChange={handleRowSelectionChange}
        columns={columns}
        enableMultiRowSelection={true}
        emptyStateComponent={<EmptyState />}
      />
    </Modal>
  );
}
