import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiOrganization } from "../../api/organization.api.ts";
import { ApiErrorException } from "../../types/api.types.ts";
import {
  ApiOrganizationSettings,
  ApiOrganizationSettingsSavePayload,
} from "../../types/organization.types.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";

const organizationKeys = createQueryKeys("organization", {
  settings: null,
});

export const useOrganizationSettings = () => {
  return useQuery({
    ...organizationKeys.settings,
    queryFn: async () => {
      const result = await apiOrganization.getSettings();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useOrganizationUpdateSettings = <T extends FieldValues>(control: Control<T>) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    ...organizationKeys.settings,
    mutationFn: async (settings: ApiOrganizationSettingsSavePayload) => {
      const result = await apiOrganization.updateSettings(settings);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (newSettings: ApiOrganizationSettings) => {
      // queryClient.invalidateQueries({
      //   queryKey: organizationKeys.settings.queryKey,
      // });
      queryClient.setQueryData(organizationKeys.settings.queryKey, newSettings);
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useOrganizationSetLogo = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  const { employee, setCurrentEmployee } = useEmployeeStore();

  return useMutation({
    ...organizationKeys.settings,
    mutationFn: async (fileId: number) => {
      const result = await apiOrganization.setLogo(fileId);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (newSettings) => {
      queryClient.setQueryData(organizationKeys.settings.queryKey, newSettings);

      if (employee) {
        const organization: ApiOrganizationSettings = {
          ...employee.organization,
          logo_url: newSettings.logo_url,
        };
        setCurrentEmployee({ ...employee, organization: organization });
      }
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useOrganizationDeleteLogo = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  const { employee, setCurrentEmployee } = useEmployeeStore();

  return useMutation({
    ...organizationKeys.settings,
    mutationFn: async () => {
      const result = await apiOrganization.deleteLogo();
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (newSettings) => {
      queryClient.setQueryData(organizationKeys.settings.queryKey, newSettings);
      if (employee) {
        const organization: ApiOrganizationSettings = {
          ...employee.organization,
          logo_url: newSettings.logo_url,
        };
        setCurrentEmployee({ ...employee, organization: organization });
      }
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
