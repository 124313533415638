import { twMerge } from "tailwind-merge";
import { ElementType, forwardRef } from "react";
import Stack from "../../ui/stack/Stack.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
// import { useTheme } from "../themes/provider.tsx";

export type SearchProps<T extends ElementType = "input"> = PolymorphicComponentProp<
  T,
  {
    value?: string;
    placeholder?: string;
  }
>;

export const Search = forwardRef<HTMLInputElement, SearchProps>(function Input(
  { placeholder = "", disabled, readOnly, className, value, ...rest }: SearchProps,
  ref
) {
  return (
    <>
      <Stack direction={"horizontal"} gap={"sm"} items={"center"} className={"h-12 w-full"}>
        <FontAwesomeIcon icon={"fa-light fa-search"} className={"text-secondary"} />
        <input
          className={twMerge(
            "outline-0 bg-transparent focus:outline-0 w-full",
            "text-md",
            className
          )}
          ref={ref}
          type={"text"}
          value={value || ""}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          {...rest}
        />
      </Stack>
    </>
  );
});
