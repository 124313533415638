import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Modal from "../../ui/modal/modal";
import Button from "../../ui/button/Button";
import { Input } from "../../ui/input/Input.tsx";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import i18n from "../../i18n.ts";
import { ApiEventPayload, ApiEventWithOptionalId, getDurations } from "../../types/event.types.ts";
import { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Box from "../../ui/box/Box.tsx";
import Datepicker from "../../ui/datepicker/Datepicker.tsx";
import { Title } from "../title/Title.tsx";
import { SelectItem } from "../../ui/select/select.types.ts";
import Select from "../../ui/select/Select.tsx";
import { useMemo, useState } from "react";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import getTimezones, { DefaultTimezone } from "../../services/timezone-service.ts";
import { Textarea } from "../../ui/textarea/Textarea.tsx";
import useEmployeeSearchInline from "../../queries/use-employees.query.ts";
import Multiselect from "../../ui/select/Multiselect.tsx";
import Text from "../../ui/typography/Text.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { Time } from "../../types/common.types.ts";
import { getTimeId } from "../../helpers/dates.helper.ts";
import { useEventsSave } from "../../queries/use-events.query.ts";
import useModals from "../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";

export interface EventInEditModalProps extends ModalProps {
  event: ApiEventWithOptionalId;
  eventId?: number | undefined;
}

export function EventEditModal({ event, eventId, ...props }: EventInEditModalProps) {
  const { t } = useTranslation();
  const { renderTime } = useFormatter();
  const { renderEmployeeName } = useFormatter();
  const { close } = useModals();
  const { id } = { ...props };

  const EventSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Field is required")),
    date_event: yup.date().required(i18n.t("Field is required")),
    time_start: yup.date().required(i18n.t("Field is required")),
    duration_minutes: yup.number().required(i18n.t("Field is required")),
    comment: yup.string(),
    is_private: yup.boolean().required(i18n.t("Field is required")),
    guest_ids: yup.array().of(yup.number().required()).required(),
  });

  interface EventSchema extends yup.InferType<typeof EventSchemaObject> {}

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EventSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EventSchemaObject),
    defaultValues: {
      title: event.title,
      date_event: event.date_event,
      time_start: event.time_start,
      duration_minutes: event.duration_minutes,
      comment: event.comment,
      is_private: event.is_private,
      guest_ids: event.guests.map((guest) => guest.id),
    },
  });

  const guestsSearch = useEmployeeSearchInline();
  const eventActionSave = useEventsSave(control);

  const startTimes = useMemo(() => {
    const startTimes: Time[] = [];

    for (let i = 9; i < 20; i++) {
      const date1 = new Date(2000, 1, 1, i, 0);
      const date2 = new Date(2000, 1, 1, i, 30);

      startTimes.push({
        id: getTimeId(date1),
        title: renderTime(date1),
        date: date1,
      });

      startTimes.push({
        id: getTimeId(date2),
        title: renderTime(date2),
        date: date2,
      });
    }
    return startTimes;
  }, []);

  const startTimesFull = useMemo(() => {
    const startTimes: Time[] = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 5) {
        const date = new Date(2000, 1, 1, i, j);

        startTimes.push({
          id: getTimeId(date),
          title: renderTime(date),
          date: date,
        });
      }
    }

    return startTimes;
  }, []);

  const currentTimezone = useMemo(() => {
    return getTimezones().find((tz) => tz.id == event.timezone);
  }, [event.timezone]);

  const [isCustomStartTime, setIsCustomStartTime] = useState(false);

  const guestsOptions = useMemo(() => {
    if (!event.guests) return [];

    return event.guests.map((guest) => ({
      id: guest.id,
      title: renderEmployeeName(guest),
    })) as SelectItem[];
  }, [event]);

  const privacyOptions = useMemo(() => {
    return [
      { id: "public", title: t("Public (visible to everyone)") },
      { id: "private", title: t("Private (visible only to you)") },
    ];
  }, []);

  const onSubmit = handleSubmit((schema) => {
    const eventPayload: ApiEventPayload = {
      type: event.type,
      date_event: schema.date_event,
      time_start: schema.time_start,
      timezone: event.timezone || DefaultTimezone,
      duration_minutes: schema.duration_minutes,
      title: schema.title,
      comment: schema.comment,
      location: "",
      is_private: schema.is_private,
      guest_ids: schema.guest_ids,
      is_cancelled: false,
    };

    console.log("save", eventId);

    eventActionSave.mutateAsync(
      { id: eventId, payload: eventPayload },
      {
        onSuccess() {
          if (eventId) {
            analyticsService.trackEvent(analyticEvents.events.edited, {
              [analyticProperties.id]: eventId,
              [analyticProperties.actionType]: "Calendar",
            });
          } else {
            analyticsService.trackEvent(analyticEvents.events.created, {
              [analyticProperties.actionType]: "Calendar",
            });
          }

          close(id);
        },
      }
    );
  });

  return (
    <Modal
      {...props}
      layout="base"
      size={"md"}
      title={t("Event")}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            onClick={onSubmit}
            isLoading={eventActionSave.isPending}
            size={"lg"}
            className={"w-full"}
            type={"submit"}
          >
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit} className={"flex flex-col flex-grow"}>
        <Input
          label={t("Title")}
          type="text"
          {...register("title")}
          error={errors.title?.message}
        />
        <Controller
          render={({ field: { value, onChange } }) => (
            <Multiselect
              label={t("Guests")}
              emptyTitle={t("Select")}
              values={value}
              options={guestsOptions}
              searchable
              onSearchOptions={(text) => guestsSearch.search(text)}
              searchOptions={guestsSearch.options}
              loadOptionsOnOpen
              onChange={onChange}
              error={errors.guest_ids?.message}
            />
          )}
          control={control}
          name={"guest_ids"}
        />
        <Title size={"xs"} header={t("Date and time")} paddingTop paddingBottom />
        <Box className={"grid grid-cols-3 gap-sm overflow-hidden"}>
          <Box className={"col-span-2"}>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  label={t("Date")}
                  error={errors.date_event?.message}
                  className={"col-span-2"}
                />
              )}
              control={control}
              name={"date_event"}
            />
            {currentTimezone && (
              <Box className={"py-2 overflow-hidden"}>
                <Text
                  size={"md"}
                  className={"text-dark/60 text-ellipsis overflow-hidden text-nowrap block"}
                >
                  {`${t("Timezone")}: ${currentTimezone.title}`}
                </Text>
              </Box>
            )}
          </Box>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                emptyTitle={t("Duration")}
                options={getDurations()}
                onChange={onChange}
                error={errors.duration_minutes?.message}
              />
            )}
            control={control}
            name={"duration_minutes"}
          />
        </Box>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Box className={"grid grid-cols-4 gap-x-xs gap-y-sm py-2"}>
              {startTimes.map((time) => (
                <Button
                  variant={getTimeId(time.date) == getTimeId(value) ? "primary" : "secondary"}
                  size={"sm"}
                  key={time.id}
                  leftIcon={
                    getTimeId(time.date) == getTimeId(value) ? (
                      <FontAwesomeIcon icon={"fa-light fa-check"} />
                    ) : undefined
                  }
                  onClick={() => {
                    onChange(time.date);
                  }}
                >
                  {time.title}
                </Button>
              ))}
              <Button
                variant={isCustomStartTime ? "primary" : "secondary"}
                size={"sm"}
                className={"col-span-2"}
                onClick={() => {
                  setIsCustomStartTime(true);
                  setValue("time_start", new Date(2000, 1, 1, 12, 5));
                }}
              >
                {t("Custom")}
              </Button>
            </Box>
          )}
          control={control}
          name={"time_start"}
        />
        {isCustomStartTime && (
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={getTimeId(value)}
                emptyTitle={t("Time")}
                onChange={(newValue) => {
                  const time = startTimesFull.find((time) => time.id == newValue);
                  onChange(time?.date);
                }}
                options={startTimesFull}
                searchable
                error={errors.time_start?.message}
              />
            )}
            name={"time_start"}
          />
        )}
        <Title size={"xs"} header={t("Settings")} paddingTop paddingBottom />
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value ? "private" : "public"}
              emptyTitle={t("Event privacy")}
              onChange={(newValue) => {
                onChange(newValue == "private");
              }}
              options={privacyOptions}
              error={errors.is_private?.message}
              searchable
            />
          )}
          name={"is_private"}
        />
        <Textarea
          label={t("Description")}
          placeholder={t("Description")}
          {...register("comment")}
        />
      </form>
    </Modal>
  );
}
