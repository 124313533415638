import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import {
  ApiNoteEdit,
  ApiNoteVisibility,
  getNotesVisibilityOptions,
} from "../../../../types/notes.types.ts";
import { useNoteSave } from "../../../../queries/use-notes.query.ts";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import Select from "../../../../ui/select/Select.tsx";

export interface EmployeeNoteEditModalProps extends ModalProps {
  note: ApiNoteEdit;
  employeeId: number;
  noteId: number;
}

export function EmployeeNoteEditModal({
  noteId,
  note,
  employeeId,
  ...props
}: EmployeeNoteEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const noteSchema = yup.object({
    text: yup.string().required(i18n.t("Field is required")),
    visibility: yup.mixed<ApiNoteVisibility>().oneOf(Object.values(ApiNoteVisibility)).required(),
  });

  interface NoteSchema extends yup.InferType<typeof noteSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NoteSchema>({
    mode: "onSubmit",
    resolver: yupResolver(noteSchema),
    defaultValues: {
      text: note.text,
      visibility: note.visibility,
    },
  });

  const noteSave = useNoteSave(control);

  const onSubmit = handleSubmit((schemaData) => {
    noteSave.mutate(
      {
        employeeId: employeeId,
        id: noteId,
        payload: {
          text: schemaData.text,
          visibility: schemaData.visibility || ApiNoteVisibility.myself,
        },
      },
      {
        onSuccess: (data) => {
          if (noteId == 0) {
            analyticsService.trackEvent(analyticEvents.notes.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.notes.edited, {
              [analyticProperties.id]: noteId,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <>
      <Modal
        {...props}
        title={t("Note")}
        withCloser
        size={"lg"}
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button type={"submit"} size={"lg"} isLoading={noteSave.isPending} onClick={onSubmit}>
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Controller
            control={control}
            name="visibility"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t("Visibility")}
                options={getNotesVisibilityOptions()}
                emptyTitle={t("Select")}
                value={value}
                onChange={onChange}
                error={errors.visibility?.message}
              />
            )}
          />
          <Textarea
            required={true}
            label={t("Note")}
            autoFocus={true}
            {...register("text", {
              value: note.text,
            })}
            error={errors.text?.message}
          />
        </form>
      </Modal>
    </>
  );
}
