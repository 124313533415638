import { useTranslation } from "react-i18next";
import { EmptyStateBase } from "./EmptyStateBase.tsx";

export default function EmptyStatePage404() {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      emoji={"🦥"}
      title={t("The page you are looking for is not available")}
      description={t(
        "We are sorry for the inconvenience,The page you are trying to access has been removed or never been existed"
      )}
    />
  );
}
