import { useTranslation } from "react-i18next";
import { EmptyStateBase } from "./EmptyStateBase.tsx";

export default function EmptyStateNothingFound() {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      emoji={"🤷"}
      title={t("Nothing found")}
      description={t("No results were found")}
    />
  );
}
