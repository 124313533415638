import { useEmployeeStore } from "../stores/employee.store.ts";
import { enUS, ru } from "date-fns/locale";
import { ApiAnalyticsAggregationDate } from "../types/analytics/analytics.recruiting.types.ts";
import i18n from "../i18n.ts";
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import { Period } from "../types/common.types.ts";

export default function useDatePeriods() {
  const { employee } = useEmployeeStore();

  // const { t } = useTranslation();

  function _getLocale() {
    if (employee?.language == undefined) {
      return enUS;
    }

    if (employee.language == "ru") return ru;
    return enUS;
  }

  function getAvailableOptions(aggregationType: ApiAnalyticsAggregationDate): Period[] {
    const today = new Date();

    if (
      aggregationType == ApiAnalyticsAggregationDate.day ||
      aggregationType == ApiAnalyticsAggregationDate.week
    ) {
      return [
        {
          title: i18n.t("plural_last_weeks", { count: 2 }),
          start: startOfWeek(subWeeks(today, 2), { locale: _getLocale() }),
          end: endOfWeek(subWeeks(today, 1), { locale: _getLocale() }),
          key: "last_week_2",
        },
        {
          title: i18n.t("plural_last_weeks", { count: 4 }),
          start: startOfWeek(subWeeks(today, 4), { locale: _getLocale() }),
          end: endOfWeek(subWeeks(today, 1), { locale: _getLocale() }),
          key: "last_week_4",
        },
        {
          title: i18n.t("plural_last_weeks", { count: 8 }),
          start: startOfWeek(subWeeks(today, 8), { locale: _getLocale() }),
          end: endOfWeek(subWeeks(today, 1), { locale: _getLocale() }),
          key: "last_week_8",
        },
        {
          title: i18n.t("plural_last_weeks", { count: 12 }),
          start: startOfWeek(subWeeks(today, 12), { locale: _getLocale() }),
          end: endOfWeek(subWeeks(today, 1), { locale: _getLocale() }),
          key: "last_week_12",
        },
      ] as Period[];
    } else if (aggregationType == ApiAnalyticsAggregationDate.month) {
      return [
        {
          title: i18n.t("plural_last_months", { count: 0 }),
          start: startOfMonth(today),
          end: endOfMonth(today),
          key: "last_month_0",
        },
        {
          title: i18n.t("plural_last_months", { count: 1 }),
          start: startOfMonth(subMonths(today, 1)),
          end: endOfMonth(today),
          key: "last_month_1",
        },
        {
          title: i18n.t("plural_last_months", { count: 3 }),
          start: startOfMonth(subMonths(today, 3)),
          end: endOfMonth(today),
          key: "last_month_3",
        },
        {
          title: i18n.t("plural_last_months", { count: 6 }),
          start: startOfMonth(subMonths(today, 6)),
          end: endOfMonth(today),
          key: "last_month_6",
        },
      ] as Period[];
    } else if (aggregationType == ApiAnalyticsAggregationDate.quarter) {
      return [
        {
          title: i18n.t("plural_last_quarters", { count: 0 }),
          start: startOfQuarter(today),
          end: endOfQuarter(today),
          key: "last_quarter_0",
        },
        {
          title: i18n.t("plural_last_quarters", { count: 1 }),
          start: startOfQuarter(subQuarters(today, 1)),
          end: endOfQuarter(today),
          key: "last_quarter_1",
        },
        {
          title: i18n.t("plural_last_quarters", { count: 2 }),
          start: startOfQuarter(subQuarters(today, 2)),
          end: endOfQuarter(today),
          key: "last_quarter_2",
        },
        {
          title: i18n.t("plural_last_quarters", { count: 3 }),
          start: startOfQuarter(subQuarters(today, 3)),
          end: endOfQuarter(today),
          key: "last_quarter_3",
        },
        {
          title: i18n.t("plural_last_quarters", { count: 4 }),
          start: startOfQuarter(subQuarters(today, 4)),
          end: endOfQuarter(today),
          key: "last_quarter_4",
        },
      ] as Period[];
    } else {
      return [
        {
          title: i18n.t("plural_last_years", { count: 0 }),
          start: startOfYear(today),
          end: endOfYear(today),
          key: "last_year_0",
        },
        {
          title: i18n.t("plural_last_years", { count: 1 }),
          start: startOfYear(subYears(today, 1)),
          end: endOfYear(today),
          key: "last_year_1",
        },
        {
          title: i18n.t("plural_last_years", { count: 2 }),
          start: startOfYear(subYears(today, 2)),
          end: endOfYear(today),
          key: "last_year_2",
        },
        {
          title: i18n.t("plural_last_years", { count: 3 }),
          start: startOfYear(subYears(today, 3)),
          end: endOfYear(today),
          key: "last_year_3",
        },
      ] as Period[];
    }
  }

  return { getAvailableOptions };
}
