import { twMerge } from "tailwind-merge";
import { AvatarProps } from "./avatar.types.ts";
import { useTheme } from "../themes/provider.tsx";
import { forwardRef, useMemo } from "react";
import Text from "../typography/Text.tsx";

export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(function Avatar(
  { url, size = "md", onLoad, acronym, emoji, variant = "default" }: AvatarProps,
  ref
) {
  const theme = useTheme("avatar");

  const acronymShort = useMemo(() => {
    if (emoji) return emoji;
    if (!acronym) {
      return "😎";
    }

    const acronymValue = acronym
      .split(/\s/)
      .reduce((response: string, word: string) => (response += word.slice(0, 1)), "");

    if (acronymValue.length > 2) {
      return acronymValue.substring(0, 2);
    }

    return acronymValue;
  }, [acronym, emoji]);

  return (
    <>
      <div
        className={twMerge(theme.base, theme.variants.size[size], theme.variants.variant[variant])}
      >
        {url && (
          <img ref={ref} onLoad={onLoad} src={url} className={"w-full h-full object-cover"} />
        )}
        {!url && !acronym && (
          <Text className={twMerge(theme.variants.emojiSize[size], theme.emoji)}>
            {acronymShort}
          </Text>
        )}
        {!url && acronym && (
          <Text className={twMerge(theme.variants.acronymSize[size], theme.acronym)}>
            {acronymShort}
          </Text>
        )}
      </div>
    </>
  );
});
export default Avatar;
