import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApiAnalyticsAggregationDate,
  ApiAnalyticsRecruitingStageFlowFilter,
  ApiAnalyticsRecruitingStagesFlowReport,
  ApiAnalyticsRecruitingStagesFlowReportItem,
  ApiAnalyticsRecruitingStagesFlowReportItemStage,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import Table from "../../../../ui/table/Table.tsx";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import Text from "../../../../ui/typography/Text.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { useRecruitAnalyticsStagesFlow } from "../../../../queries/recruit/use-recruit-analytics.query.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Multiselect from "../../../../ui/select/Multiselect.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Select from "../../../../ui/select/Select.tsx";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import { ApiEmployeeFilter, EmployeeFilterDefault } from "../../../../types/employee.types.ts";
import { useEmployees } from "../../../../queries/use-employees.query.ts";
import { ApiJobsFilterDefault, getJobEmoji } from "../../../../types/recruit/job.types.ts";
import { useJobsSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import Button from "../../../../ui/button/Button.tsx";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import { EmployeeInlineCard } from "../../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import { useSearchParams } from "react-router-dom";
import { parseUrlHelper } from "../../../../helpers/parse-url.helper.ts";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { PreviewCandidatesModal } from "../widgets/PreviewCandidatesModal.tsx";
import ButtonLink from "../../../../ui/button/ButtonLink.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";

enum StageFlowReportGroupBy {
  job = "job",
  recruiter = "recruiter",
  none = "none",
}

export const RecruitingStagesFlowReport = () => {
  const { t } = useTranslation();
  const { renderEmployeeName } = useFormatter();
  const modalsService = useModals();

  const StageFlowGroupingsItems: SelectItem[] = [
    {
      id: StageFlowReportGroupBy.job,
      title: t("Job"),
    },
    {
      id: StageFlowReportGroupBy.recruiter,
      title: t("Recruiter"),
    },
    {
      id: StageFlowReportGroupBy.none,
      title: t("Without grouping"),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const aggregationDateMode = (searchParams.get("aggregation_date_mode") ||
      ApiAnalyticsAggregationDate.week) as ApiAnalyticsAggregationDate;
    const employee_id = parseUrlHelper.getNumberArray(searchParams, "employee_id");
    const dateStart =
      parseUrlHelper.getDate(searchParams, "date_start") ||
      new Date(new Date().setDate(new Date().getDate() - 7 - new Date().getDay() + 1));
    const dateEnd =
      parseUrlHelper.getDate(searchParams, "date_end") ||
      new Date(new Date().setDate(new Date().getDate() + 7 - new Date().getDay()));
    const job_id = parseUrlHelper.getNumberArray(searchParams, "job_id");

    setFilter({
      aggregation_date_mode: aggregationDateMode,
      employee_id: employee_id,
      date_start: dateStart,
      date_end: dateEnd,
      job_id: job_id,
      group_owner: searchParams.get("group") == "job",
      group_jobs: searchParams.get("group") == "recruiter",
    });
  }, [searchParams]);
  const [currentGrouping, setCurrentGrouping] = useState(StageFlowReportGroupBy.job);

  const [filter, setFilter] = useState<ApiAnalyticsRecruitingStageFlowFilter>({
    aggregation_date_mode: ApiAnalyticsAggregationDate.week,
    employee_id: [],
    date_start: new Date(new Date().setDate(new Date().getDate() - 7 - new Date().getDay() + 1)),
    date_end: new Date(new Date().setDate(new Date().getDate() + 7 - new Date().getDay())),
    job_id: [],
    group_owner: true,
  });
  const { data: report, isLoading } = useRecruitAnalyticsStagesFlow(filter);
  const { formatPeriodInReporting } = useFormatter();

  const [recruiterFilter, setRecruiterFilter] = useState<ApiEmployeeFilter>(EmployeeFilterDefault);
  const { data: recruiters } = useEmployees(recruiterFilter);
  const recruitersOptions = useMemo(() => {
    if (recruiters?.items) {
      return recruiters.items.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }
    return [] as SelectItem[];
  }, [recruiters]);

  const [jobFilter, setJobFilter] = useState(ApiJobsFilterDefault);
  const { data: jobs } = useJobsSearch(jobFilter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.reporting.viewed, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingStagesFlow,
    });
  });

  const aggregationDateModes = useMemo(() => {
    return [
      {
        id: ApiAnalyticsAggregationDate.day,
        title: t("Day"),
      },
      {
        id: ApiAnalyticsAggregationDate.week,
        title: t("Week"),
      },
      {
        id: ApiAnalyticsAggregationDate.month,
        title: t("Month"),
      },
      {
        id: ApiAnalyticsAggregationDate.quarter,
        title: t("Quarter"),
      },
      {
        id: ApiAnalyticsAggregationDate.year,
        title: t("Year"),
      },
    ] as SelectItem[];
  }, []);

  const handlePreviewApplicants = useCallback(
    (item: ApiAnalyticsRecruitingStagesFlowReportItemStage) => {
      analyticsService.trackEvent(analyticEvents.reporting.dataViewed, {
        [analyticProperties.title]: AnalyticReportTypes.recruitingStagesFlow,
      });

      modalsService.openModal(PreviewCandidatesModal, {
        applicantIds: item.applicants_ids,
      });
    },
    []
  );

  const columnHelper = createColumnHelper<ApiAnalyticsRecruitingStagesFlowReportItem>();
  const columns = useMemo<ColumnDef<ApiAnalyticsRecruitingStagesFlowReportItem>[]>(() => {
    const inner = [
      columnHelper.display({
        id: "employee",
        header: t("Employee"),
        cell: (cell) => <EmployeeInlineCard employee={cell.row.original.employee} />,
      }),
      columnHelper.display({
        id: "job",
        header: t("Job"),
        cell: (cell) => (
          <Stack gap={"sm"} direction={"horizontal"} items={"center"}>
            <Avatar url={undefined} emoji={getJobEmoji(cell.row.original.job)} size={"md"} />
            <Text className={"text-secondary"}>{cell.row.original.job.title}</Text>
          </Stack>
        ),
      }),
      columnHelper.display({
        id: "week",
        header: t("Period"),
        cell: (cell) => (
          <Text>
            {formatPeriodInReporting(
              cell.row.original.date_report_from,
              cell.row.original.date_report_to,
              filter.aggregation_date_mode
            )}
          </Text>
        ),
        size: 200,
      }),
    ];
    if (report?.config && report.config.stages.length > 0) {
      for (let i = 0; i < report.config.stages.length; i++) {
        const stage = report.config.stages[i];
        inner.push(
          columnHelper.display({
            id: `stage-${i}`,
            header: () => (
              <Box className={"text-right"}>
                <Text className={"capitalize"}>{stage.title}</Text>
              </Box>
            ),
            cell: (cell) => (
              <Box className={"text-right"}>
                <ButtonLink
                  variant={"secondary"}
                  onClick={() => {
                    const item = cell.row.original.stages.find(
                      (x) => x.pipeline_stage.id == stage.id
                    );
                    if (item) handlePreviewApplicants(item);
                  }}
                >
                  {cell.row.original.stages.find((x) => x.pipeline_stage.id == stage.id)
                    ?.count_in || ""}
                </ButtonLink>
              </Box>
            ),
            size: 70,
          })
        );
      }
    }
    return inner;
  }, [report]);

  const convertToCSV = (report: ApiAnalyticsRecruitingStagesFlowReport) => {
    let str = `${t("Employee")},${t("Job")},${t("Period")},`;
    if (report.config.stages.length > 0) {
      for (let i = 0; i < report.config.stages.length; i++) {
        const stage = report.config.stages[i];
        str += `${stage.title},`;
      }
    }
    str += "\r\n";

    for (let i = 0; i < report.report.length; i++) {
      let line = "";
      const reportLine = report.report[i];
      line += renderEmployeeName(reportLine.employee) + ",";
      line += reportLine.job.title + ",";
      line +=
        `"${formatPeriodInReporting(
          reportLine.date_report_from,
          reportLine.date_report_to,
          filter.aggregation_date_mode
        )}"` + ",";
      for (let j = 0; j < reportLine.stages.length; j++) {
        line += reportLine.stages[j].count_in + ",";
      }

      str += line + "\r\n";
    }
    return str;
  };

  const handleDownloadCSV = useCallback(() => {
    if (!report) return;

    analyticsService.trackEvent(analyticEvents.dataExported, {
      [analyticProperties.title]: "ATS: Stages flow report",
    });

    const universalBOM = "\uFEFF";

    const csvData = new Blob([universalBOM + convertToCSV(report)], {
      type: "text/csv; charset=utf-8",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `recruiting-stage-flow-report.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [report]);

  return (
    <>
      <Box className={"bg-light h-full overflow-y-auto"}>
        <Page.Header title={t("Reporting")}>
          <Button onClick={handleDownloadCSV} size={"sm"}>
            {t("Export to CSV")}
          </Button>
        </Page.Header>

        <Page.Content>
          <Stack gap={"lg"}>
            <Box className={"grid grid-cols-1 lg:grid-cols-6 gap-md"}>
              <Box>
                <Multiselect
                  label={t("Recruiters")}
                  emptyTitle={t("Select")}
                  values={filter.employee_id}
                  searchable
                  onSearchOptions={(value) => {
                    setRecruiterFilter({ ...recruiterFilter, text: value });
                  }}
                  searchOptions={recruitersOptions}
                  loadOptionsOnOpen
                  onChange={(newValues) => {
                    const update = newValues as number[];
                    setSearchParams((searchParams) => {
                      if (update.length == 0) {
                        searchParams.delete("employee_id");
                      } else {
                        searchParams.set("employee_id", update.join(","));
                      }
                      return searchParams;
                    });
                  }}
                />
              </Box>
              <Box>
                <Multiselect
                  label={t("Jobs")}
                  emptyTitle={t("Select")}
                  values={filter.job_id}
                  searchable
                  onSearchOptions={(value) => {
                    setJobFilter({ ...jobFilter, text: value });
                  }}
                  searchOptions={jobs?.items || []}
                  loadOptionsOnOpen
                  onChange={(newValues) => {
                    setFilter({ ...filter, job_id: newValues as number[] });
                  }}
                />
              </Box>

              <Box>
                <Datepicker
                  value={filter.date_start}
                  label={t("Date from")}
                  onChange={(date) => setFilter({ ...filter, date_start: date })}
                />
              </Box>
              <Box>
                <Datepicker
                  value={filter.date_end}
                  label={t("Date to")}
                  onChange={(date) => setFilter({ ...filter, date_end: date })}
                />
              </Box>
              <Box>
                <Select
                  options={aggregationDateModes}
                  value={filter.aggregation_date_mode}
                  emptyTitle={t("Grouping")}
                  onChange={(value) => {
                    setFilter({
                      ...filter,
                      aggregation_date_mode: value as ApiAnalyticsAggregationDate,
                    });
                  }}
                />
              </Box>
              <Box>
                <Select
                  options={StageFlowGroupingsItems}
                  value={currentGrouping}
                  emptyTitle={t("Grouping")}
                  onChange={(value) => {
                    const newValue = value as StageFlowReportGroupBy;
                    setCurrentGrouping(newValue);
                    setFilter({
                      ...filter,
                      group_owner: newValue == StageFlowReportGroupBy.job,
                      group_jobs: newValue == StageFlowReportGroupBy.recruiter,
                    });
                  }}
                />
              </Box>
            </Box>

            <Table
              columnVisibility={{
                employee: currentGrouping != StageFlowReportGroupBy.job,
                job: currentGrouping != StageFlowReportGroupBy.recruiter,
              }}
              data={report?.report || []}
              columns={columns}
              showSkeleton={isLoading}
            />
          </Stack>
        </Page.Content>
      </Box>
    </>
  );
};
