import { useTranslation } from "react-i18next";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { SidebarConfigItem } from "../sidebar-service.tsx";
import { PermissionType } from "../../../types/role.types.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import { SidebarItem } from "../components/SidebarItem.tsx";
import Box from "../../../ui/box/Box.tsx";
import { Title } from "../../title/Title.tsx";
import Text from "../../../ui/typography/Text.tsx";

export const SidebarRecruitmentBaseMenu = () => {
  const { t } = useTranslation();

  const { employee } = useEmployeeStore();
  const { pathname } = useLocation();

  const menuConfig = useMemo(() => {
    const items = [
      {
        title: t("Candidates"),
        to: "/recruitment/candidates",
        isActive: false,
      },
      {
        title: t("Jobs"),
        to: "/recruitment/jobs",
        isActive: false,
      },
    ] as SidebarConfigItem[];

    for (let i = 0; i < items.length; i++) {
      items[i].isActive = pathname.startsWith(items[i].to);
    }
    return items;
  }, [pathname, employee]);

  const reportsConfig = useMemo(() => {
    if (!employee) return [];

    const items: SidebarConfigItem[] = [];

    if (employee && employee.permissions.reporting_recruiting_stage_flow == PermissionType.edit) {
      items.push({
        title: t("Hiring pipeline"),
        to: "/recruitment/reporting/stages-flow-report",
        isActive: false,
      });
      items.push({
        title: t("Hiring pipeline"),
        to: "/recruitment/reporting/stage-flow-report",
        subtitle: "Beta",
        isActive: false,
      });
    }

    if (
      employee &&
      employee.permissions.reporting_recruiting_source_efficiency == PermissionType.edit
    ) {
      items.push({
        title: t("Source efficiency"),
        to: "/recruitment/reporting/source-efficiency-report",
        subtitle: "Beta",
        isActive: false,
      });
    }
    if (employee && employee.permissions.reporting_recruiting_closing_time == PermissionType.edit) {
      items.push({
        title: t("Closing time"),
        to: "/recruitment/reporting/closing-time-report",
        subtitle: "Beta",
        isActive: false,
      });
    }

    for (let i = 0; i < items.length; i++) {
      items[i].isActive = pathname.startsWith(items[i].to);
    }
    return items;
  }, [pathname, employee]);

  return (
    <Stack className={"px-4"}>
      <Stack gap={"xs"}>
        {menuConfig.map((item, index) => (
          <SidebarItem
            to={item.to || "/"}
            key={"sidebar-" + index}
            isActive={item.isActive}
            title={item.title}
          />
        ))}
      </Stack>
      {reportsConfig.length > 0 && (
        <>
          <Box className={"px-2"}>
            <Title size={"xs"} header={t("Reports")} paddingTop paddingBottom />
          </Box>
          <Stack gap={"xs"}>
            {reportsConfig.map((item, index) => (
              <SidebarItem
                to={item.to || "/"}
                key={"sidebar-report-" + index}
                isActive={item.isActive}
                title={item.title}
                subtitleSlot={
                  item.subtitle ? (
                    <Text className={"text-brand-dark"}>{item.subtitle}</Text>
                  ) : undefined
                }
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};
