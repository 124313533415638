import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException, ApiPassException } from "../types/api.types.ts";
import {
  ApiEmployeeAccountSettingsSavePayload,
  ApiEmployeeEditPayload,
  ApiEmployeeFilter,
  ApiEmployeeShort,
  EmployeeFilterDefault,
} from "../types/employee.types.ts";
import { apiEmployees } from "../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { useAuthStore, useEmployeeStore } from "../stores/employee.store.ts";
import useFormatter from "../hooks/use-formatter.hook.ts";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";
import { ChartNodeData } from "../components/chart/Chart.tsx";

export const employeesKeys = createQueryKeys("employees", {
  search: (filter: ApiEmployeeFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiEmployees.search(filter, signal);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
  searchWithRoles: (filter: ApiEmployeeFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiEmployees.searchWithRoles(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  detail: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Job id is required");
      const result = await apiEmployees.getById(id);

      // if (result.data) {
      //   for (const key in result.data.permissions) {
      //     result.data.permissions[key] = PermissionType.edit;
      //   }
      //   console.log("employee.permissions", result.data.permissions);
      // }

      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  chart: (managerId: number | undefined) => ({
    queryKey: [managerId],
    queryFn: async () => {
      const result = await apiEmployees.chart(managerId);

      if (result.error) throw new ApiErrorException(result.error);

      const nodes: ChartNodeData[] =
        result.data?.map<ChartNodeData>((employee) => ({
          parentId: `${managerId ?? "root"}`,
          id: `${employee.id}`,
          title: employee.first_name,
          img_url: employee.avatar_url,
          count: employee.direct_subordinates_count,
          isRoot: false,
        })) ?? [];

      if (!managerId) {
        nodes.push({
          title: "Head",
          id: "root",
          parentId: null,
          count: nodes.length,
          isRoot: true,
        });
      }

      return nodes;
    },
  }),
  me: null,
  byListOfId: (ids: number[]) => ({
    queryKey: [ids],
    queryFn: async () => {
      const result = await apiEmployees.getByListOfId(ids);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useEmployeeMe = (enabled: boolean = true) => {
  const { setCurrentEmployee } = useEmployeeStore();
  const { token } = useAuthStore();
  return useQuery({
    queryKey: employeesKeys.me.queryKey,
    queryFn: async () => {
      const result = await apiEmployees.getMe();

      if (result.error) throw new ApiErrorException(result.error);

      // if (result.data) {
      //   if (result.data.id == 41) {
      //     for (const key in result.data.permissions) {
      //       result.data.permissions[key] = PermissionType.edit;
      //     }
      //     console.log("result.data.permissions", result.data.permissions);
      //   }
      // }

      if (result.data) setCurrentEmployee(result.data);

      return result.data;
    },
    staleTime: 10 * 60 * 1000,
    enabled: enabled && !!token,
  });
};

export const useEmployeeChart = (managerId: number | undefined, enabled: boolean = true) => {
  return useQuery({
    ...employeesKeys.chart(managerId),
    enabled: enabled,
  });
};

export const useEmployees = (filter: ApiEmployeeFilter, enabled: boolean = true) => {
  return useQuery({
    ...employeesKeys.search(filter),
    enabled: enabled,
  });
};

export const useEmployeesSearchWithRoles = (filter: ApiEmployeeFilter) => {
  return useQuery({
    ...employeesKeys.searchWithRoles(filter),
  });
};

export const useEmployeeDetails = (id: number | undefined) => {
  return useQuery(employeesKeys.detail(id));
};

export interface SaveParams {
  id: number;
  payload: ApiEmployeeEditPayload;
}

export const useEmployeeSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ id, payload }: SaveParams) {
      const result =
        id == 0 ? await apiEmployees.create(payload) : await apiEmployees.update(id, payload);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      queryClient.invalidateQueries({
        queryKey: employeesKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(employee.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export interface EmployeeSetAvatarParams {
  fileId: number;
  id: number;
}

export const useEmployeeSetAvatar = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, fileId }: EmployeeSetAvatarParams) => {
      const result = await apiEmployees.setAvatar(id, fileId);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      queryClient.invalidateQueries({
        queryKey: employeesKeys.search._def,
      });
      queryClient.setQueryData(employeesKeys.detail(employee.id).queryKey, (oldData) => {
        if (oldData) return { ...oldData, avatar_url: employee.avatar_url };
      });
      //TODO get rid of invalidation and update not only the avatar_url but color_palette as well
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(employee.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useEmployeeDeleteAvatar = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (employeeId: number) => {
      const result = await apiEmployees.deleteAvatar(employeeId);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      queryClient.invalidateQueries({
        queryKey: employeesKeys.search._def,
      });
      queryClient.setQueryData(employeesKeys.detail(employee.id).queryKey, (oldData) => {
        if (oldData) return { ...oldData, avatar_url: null };
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useEmployeeResetConfirmEmail = () => {
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async () => {
      const result = await apiEmployees.resetConfirmEmail();

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useEmployeeSendInviteConfirmEmail = () => {
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async (employee: ApiEmployeeShort) => {
      const { success, error } = await apiEmployees.resentInviteEmail(employee.id);

      if (error) throw new ApiErrorException(error);

      return success;
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useEmployeeUpdateAccountSettings = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  const { setCurrentEmployee } = useEmployeeStore();

  return useMutation({
    mutationFn: async (payload: ApiEmployeeAccountSettingsSavePayload) => {
      const result = await apiEmployees.updateAccountSettings(payload);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      if (employee) {
        setCurrentEmployee(employee);

        queryClient.setQueryData(employeesKeys.me.queryKey, () => {
          return employee;
        });
      }
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export default function useEmployeeSearchInline(enabled: boolean = true) {
  const { renderEmployeeName } = useFormatter();

  const [filter, setFilter] = useState<ApiEmployeeFilter>(EmployeeFilterDefault);
  const { data, isLoading } = useEmployees(filter, enabled);
  const options = useMemo(() => {
    if (data?.items) {
      return data.items.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }

    return [] as SelectItem[];
  }, [data]);

  function search(text: string | undefined) {
    setFilter({ ...filter, text: text });
  }

  return {
    data,
    isLoading,
    options,
    search,
    filter,
    setFilter,
  };
}

export const useEmployeeMeInvalidate = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: employeesKeys.me.queryKey,
    });
  };
};

export const useEmployeesByIds = (ids: number[]) => {
  return useQuery({
    ...employeesKeys.byListOfId(ids),
    enabled: ids.length > 0,
  });
};

export interface EmployeeSetBackgroundParams {
  fileId: number;
  id: number;
}

export const useEmployeeSetBackground = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, fileId }: EmployeeSetBackgroundParams) => {
      const result = await apiEmployees.setBackground(id, fileId);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      queryClient.setQueryData(employeesKeys.detail(employee.id).queryKey, (oldData) => {
        if (oldData) return { ...oldData, background_url: employee.background_url };
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useEmployeeDeleteBackground = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (employeeId: number) => {
      const result = await apiEmployees.deleteBackground(employeeId);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (employee) => {
      queryClient.setQueryData(employeesKeys.detail(employee.id).queryKey, (oldData) => {
        if (oldData) return { ...oldData, background_url: null };
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
