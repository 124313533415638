import { ApiEmployeeShort } from "./employee.types.ts";
import { ApiFile } from "./common.types.ts";
import { ApiCandidateDisqualificationReason } from "./recruit/disqualification-reason.types.ts";
import { ApiEmail, ApiEmailSendPayload } from "./email.types.ts";
import { ApiEvent, ApiEventPayload } from "./event.types.ts";
import { ApiApplicantShort } from "./recruit/applicant.types.ts";

export enum ApiActionVisibilities {
  myself = "myself",
  public = "public",
  recruiters = "recruiters",
  stakeholders = "stakeholders",
}

export enum ApiActionTypes {
  created = "created", // FE only
  stage_changed = "stage_changed",
  comment_added = "comment_added",
  disqualified = "disqualified",
  merged = "merged",
  assigned = "assigned",
  qualified = "qualified",
  email_sent = "email_sent",
  event = "event",
}

export enum ApiActionLogEntityTypes {
  stage = "stage",
  comment = "comment",
  file = "file",
  email = "email",
}

export interface ApiActionPipelineStage {
  id: number;
  title: string;
}

export enum ApiActionItemTypes {
  applicant = "applicant",
  candidate = "candidate",
}

export interface ApiAction {
  id: number;
  type: ApiActionTypes;
  item_id: number;
  item_type: ApiActionItemTypes;
  log_entity_type?: ApiActionLogEntityTypes;
  datetime_created_at: Date;
  author: ApiEmployeeShort;
  visibility: ApiActionVisibilities;
  files: ApiFile[];

  comment?: string;
  pipeline_stage?: ApiActionPipelineStage;
  disqualification_reason?: ApiCandidateDisqualificationReason;
  reserved?: boolean;
  message?: ApiEmail;
  event?: ApiEvent;
  merged_applicant?: ApiApplicantShort;
}

export interface ApiActionPayload {
  pipeline_stage_id?: number;
  disqualification_reason_id?: number;
  reserved?: boolean;
  comment?: string;
  file_ids?: number[];

  email?: ApiEmailSendPayload;
  event?: ApiEventPayload;
  visibility: ApiActionVisibilities;
}

export interface ApiActionCreateByFilter {
  job_id: number;
  current_pipeline_stage_ids?: number[];
  applicants_ids?: number[];
  exclude_applicants_ids?: number[];
  reserved?: boolean;
  disqualified?: boolean;

  // temp runtime hack
  analyticsTotalUpdatedCount?: number;
}

export interface ApiActionCreateByFilterPayload {
  filter: ApiActionCreateByFilter;
  action: ApiActionPayload;
}
