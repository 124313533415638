import React, { ComponentProps } from "react";
import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface LinkToProps extends ComponentProps<"a"> {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  variant?: keyof LinkVariant;
}

export interface UIKitLinkToTheme extends UIKitThemeBase {
  variants: {
    variant: {
      primary: string;
      secondary: string;
      // plain: string;
    };
  };
}

export interface LinkVariant {
  primary: string;
  secondary: string;
  // plain: string;
  // menu: string;
  // tertiary: string;
}

export const linkToTheme: UIKitLinkToTheme = {
  base: "inline-block underline-offset-2",
  variants: {
    variant: {
      primary: "underline",
      secondary: "hover:underline",
      //     plain: "border-current ",
    },
  },
};
