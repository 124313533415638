import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ApiApplicantFilter } from "../../../../types/recruit/applicant.types.ts";
import { Search } from "../../../../components/search/Search.tsx";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Multiselect from "../../../../ui/select/Multiselect.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { useSources } from "../../../../queries/use-sources.query.ts";
import { useDisqualifications } from "../../../../queries/recruit/use-disqualifications.query.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import analyticsService, { analyticEvents } from "../../../../services/analytics-service.ts";
import { parseUrlHelper } from "../../../../helpers/parse-url.helper.ts";
import { useJobDetails, useJobsSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import { useState } from "react";
import { ApiJobsFilter, ApiJobsFilterDefault } from "../../../../types/recruit/job.types.ts";
import { useStages } from "../../../../queries/recruit/use-stages.query.ts";

export interface ApplicantsFilterModalProps extends ModalProps {
  filter: ApiApplicantFilter;
}

export function ApplicantsFilterModal({ filter, ...props }: ApplicantsFilterModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: sources } = useSources();
  const { data: disqualificationReasons } = useDisqualifications();

  const [jobsFilter, setJobsFilter] = useState<ApiJobsFilter>(ApiJobsFilterDefault);
  const { data: jobsSearched, isLoading: jobsIsLoading } = useJobsSearch(jobsFilter);

  const ApplicantsFilterSchemaObject = yup.object({
    text: yup.string(),
    location: yup.string(),
    job_ids: yup.array().of(yup.number().required()).required(),
    stage_ids: yup.array().of(yup.number().required()).required(),
    source_ids: yup.array().of(yup.number().required()).required(),
    disqualification_reason_ids: yup.array().of(yup.number().required()).required(),
    position: yup.string(),
  });

  interface ApplicantsFilterSchema extends yup.InferType<typeof ApplicantsFilterSchemaObject> {}

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<ApplicantsFilterSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ApplicantsFilterSchemaObject),
    defaultValues: {
      text: filter.text || "",
      job_ids: filter.job_ids || [],
      stage_ids: filter.pipeline_stage_ids || [],
      location: filter.location || "",
      source_ids: filter.source_ids || [],
      disqualification_reason_ids: filter.disqualification_reason_ids || [],
      position: filter.position || "",
    },
  });

  const onSubmit = handleSubmit((filterSchema) => {
    const newParams: URLSearchParams = new URLSearchParams();

    if (filterSchema.text) newParams.append("text", filterSchema.text);
    if (filterSchema.job_ids.length > 0) newParams.append("job_id", filterSchema.job_ids.join(","));
    if (filterSchema.location) newParams.append("location", filterSchema.location);
    if (filterSchema.stage_ids.length > 0)
      newParams.append("stage_id", filterSchema.stage_ids.join(","));
    if (filterSchema.source_ids.length > 0)
      newParams.append("source_id", filterSchema.source_ids.join(","));
    if (filterSchema.disqualification_reason_ids.length > 0)
      newParams.append(
        "disqualification_reason_id",
        filterSchema.disqualification_reason_ids.join(",")
      );
    if (filterSchema.position) newParams.append("position", filterSchema.position);

    analyticsService.trackEvent(analyticEvents.applicants.filtered);

    if (newParams) {
      navigate(`/recruitment/candidates/?${newParams.toString()}`);
    } else {
      navigate(`/recruitment/candidates`);
    }

    close(id);
  });

  function handleClearFilters() {
    const newParams: URLSearchParams = new URLSearchParams();

    if (filter.job_ids && filter.job_ids.length == 1) {
      newParams.append("job_id", filter.job_ids.join(","));
    }

    navigate(`/recruitment/candidates/?${newParams.toString()}`);

    close(id);
  }

  const [searchParams] = useSearchParams();
  const jobIds = parseUrlHelper.getNumberArray(searchParams, "job_id");
  const stageIds = parseUrlHelper.getNumberArray(searchParams, "stage_id");
  const { data: stages, isLoading: isLoadingStages } = useStages();

  async function handleSearchJobs(text: string | undefined) {
    setJobsFilter({
      ...jobsFilter,
      text: text,
    });
  }

  const { data: currentJob } = useJobDetails(jobIds && jobIds.length == 1 ? jobIds[0] : undefined);

  return (
    <>
      <Modal
        {...props}
        variant={"side"}
        title={t("Filter")}
        layout={"stretch"}
        withCloser
        closeByEscEnabled
        actions={
          <Stack gap={"sm"}>
            <Button size={"lg"} className={"w-full"} type={"submit"} onClick={onSubmit}>
              {t("Apply filter")}
            </Button>
            <Button
              size={"lg"}
              variant={"plain"}
              onClick={() => {
                handleClearFilters();
              }}
            >
              {t("Clear all filters")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Search
                className={"w-full"}
                placeholder={t("Name, phone, links, email...")}
                value={value}
                autoFocus={true}
                onInput={onChange}
              />
            )}
            control={control}
            name={"text"}
          />

          <Input
            label={t("Position")}
            type="text"
            {...register("position", {
              value: "",
            })}
          />

          <Input
            label={t("Location")}
            type="text"
            {...register("location", {
              value: "",
            })}
          />

          <Controller
            render={({ field: { value, onChange } }) => (
              <Multiselect
                label={t("Disqualification reason")}
                emptyTitle={t("Select")}
                values={value}
                searchable
                onChange={onChange}
                options={disqualificationReasons}
                error={errors.disqualification_reason_ids?.message}
              />
            )}
            control={control}
            name={"disqualification_reason_ids"}
          />

          <Controller
            render={({ field: { value, onChange } }) => (
              <Multiselect
                label={t("Source")}
                emptyTitle={t("Select")}
                values={value}
                searchable
                onChange={onChange}
                options={sources}
                error={errors.source_ids?.message}
              />
            )}
            control={control}
            name={"source_ids"}
          />

          {jobIds.length != 1 && (
            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label={t("Job")}
                  emptyTitle={t("Select")}
                  isLoading={jobsIsLoading}
                  values={value}
                  searchable
                  onSearchOptions={handleSearchJobs}
                  loadOptionsOnOpen
                  searchOptions={jobsSearched?.items}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  error={errors.job_ids?.message}
                />
              )}
              control={control}
              name={"job_ids"}
            />
          )}

          {stageIds.length != 1 && (
            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label={t("Stages")}
                  emptyTitle={t("Select")}
                  isLoading={isLoadingStages}
                  values={value}
                  options={currentJob?.pipeline.stages ?? stages}
                  onChange={(value) => {
                    onChange(value);
                  }}
                />
              )}
              control={control}
              name={"stage_ids"}
            />
          )}
        </form>
      </Modal>
    </>
  );
}
