import Stack from "../../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import { RoleGroup, RoleGroupType, RoleType } from "../../../../../types/role.types.ts";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import Skeleton from "../../../../../ui/skeleton/Skeleton.tsx";
import { Card } from "../../../../../ui/card/Card.tsx";
import { useRoles } from "../../../../../queries/settings/use-roles.query.ts";
import { useEffectOnce } from "react-use";
import { Title } from "../../../../../components/title/Title.tsx";
import { getRoleDescription, getRoleTitle } from "../../role.types.ts";

export default function RolesList() {
  const { t } = useTranslation();

  const [groups, setGroups] = useState<Record<RoleGroupType, RoleGroup>>({
    [RoleGroupType.base]: {
      title: t("Basic roles"),
      description: t(
        "Roles applied to all members of your team by default, define how they see each others profiles and can edit their own profiles."
      ),
      roles: [],
      type: RoleGroupType.base,
    },
    [RoleGroupType.system]: {
      title: t("System roles"),
      description: t("Roles, automatically assigned by system, based on position of employee"),
      roles: [],
      type: RoleGroupType.system,
    },
    [RoleGroupType.custom]: {
      title: t("Custom roles"),
      description: t("Create custom roles and apply them based on defined rules"),
      roles: [],
      type: RoleGroupType.custom,
    },
  });
  const { data: roles, isLoading: rolesIsLoading } = useRoles();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.roles.viewed);
  });

  useEffect(() => {
    if (!roles) return;

    const cloneMockRoleGroups = { ...groups };

    Object.keys(cloneMockRoleGroups).forEach(
      (key) => (cloneMockRoleGroups[key as RoleGroupType].roles = [])
    );

    const mapTypeRoleToRoleGroup: Record<RoleType, RoleGroupType> = {
      [RoleType.custom]: RoleGroupType.custom,
      [RoleType.admin]: RoleGroupType.system,
      [RoleType.team_lead]: RoleGroupType.system,
      [RoleType.manager]: RoleGroupType.system,
      [RoleType.myself]: RoleGroupType.base,
      [RoleType.all_employees]: RoleGroupType.base,
    };

    roles.forEach((role) => {
      const groupType = mapTypeRoleToRoleGroup[role.type];

      cloneMockRoleGroups[groupType].roles.push(role);
    });

    setGroups(cloneMockRoleGroups);
  }, [roles]);

  return (
    <Stack direction={"vertical"} className={"py-6"}>
      {Object.entries(groups).map(([key, group]) => (
        <Stack direction={"vertical"} gap={"md"} key={`group-${key}`}>
          <Title
            header={group.title}
            caption={group.description}
            size={"lg"}
            paddingBottom
            paddingTop
          />

          <Stack
            direction={"horizontal"}
            gap={"md"}
            className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
          >
            {rolesIsLoading && (
              <Card className="cursor-pointer min-h-48 flex flex-col justify-between items-start">
                <Stack gap="sm" className={"w-full"}>
                  <Skeleton className={"w-1/2 h-6"}></Skeleton>
                  <Skeleton className={"w-full h-5"}></Skeleton>
                </Stack>
                <Skeleton className={"w-1/2 h-6"}></Skeleton>
              </Card>
            )}

            {group.roles.map((role) => (
              <SettingsCard
                title={getRoleTitle(role)}
                description={getRoleDescription(role)}
                to={`/settings/roles-and-permissions/${role.id}`}
                extra={t("employees_count", { count: role.employees_count })}
                key={role.id}
              />
            ))}

            {key == RoleGroupType.custom && (
              <SettingsCard
                icon={"fa-plus"}
                title={t("Create new")}
                description={""}
                to={`/settings/roles-and-permissions/new`}
                variant={"action"}
              />
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
