import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../../../ui/box/Box.tsx";
import { useRecruitAnalyticsStageFlow } from "../../../../queries/recruit/use-recruit-analytics.query.ts";
import { ApiAnalyticsAggregationDate } from "../../../../types/analytics/analytics.recruiting.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { RecruitingReportingButtonChooseAggregationDate } from "../components/RecruitingReportingButtonChooseAggregationDate.tsx";
import { RecruitingReportingButtonChoosePeriod } from "../components/RecruitingReportingButtonChoosePeriod.tsx";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { RecruitingReportingRecruitersSelector } from "../components/RecruitingReportingRecruitersSelector.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { RecruitingStageFlowReportTable } from "./RecruitingStageFlowReportTable.tsx";
import { RecruitingReportingButtonChooseGroupBy } from "../components/RecruitingReportingButtonChooseGroupBy.tsx";
import { RecruitingReportingButtonFilters } from "../components/RecruitingReportingButtonFilters.tsx";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";

export const RecruitingStageFlowReport = () => {
  const { t } = useTranslation();

  // const { getAvailableOptions } = useDatePeriods();

  const [, setSearchParams] = useSearchParams();

  const { filter } = useRecruitingReports(AnalyticReportTypes.recruitingStageFlow);
  const { data: report, isLoading } = useRecruitAnalyticsStageFlow(filter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.reporting.viewed, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingStageFlow,
    });
  });

  //
  // const convertToCSV = (report: ApiAnalyticsRecruitingStageFlowReport) => {
  //   let str = `${t("Employee")},${t("Job")},${t("Period")},`;
  //   if (report.config.stages.length > 0) {
  //     for (let i = 0; i < report.config.stages.length; i++) {
  //       const stage = report.config.stages[i];
  //       str += `${stage.title},`;
  //     }
  //   }
  //   str += "\r\n";
  //
  //   for (let i = 0; i < report.report.length; i++) {
  //     let line = "";
  //     const reportLine = report.report[i];
  //     line += renderEmployeeName(reportLine.employee) + ",";
  //     line += reportLine.job.title + ",";
  //     line +=
  //       `"${formatPeriodInReporting(
  //         reportLine.date_report_from,
  //         reportLine.date_report_to,
  //         filter.aggregation_date_mode
  //       )}"` + ",";
  //     for (let j = 0; j < reportLine.stages.length; j++) {
  //       line += reportLine.stages[j].count_in + ",";
  //     }
  //
  //     str += line + "\r\n";
  //   }
  //   return str;
  // };
  //
  // const handleDownloadCSV = useCallback(() => {
  //   if (!report) return;
  //
  //   analyticsService.trackEvent(analyticEvents.dataExported, {
  //     [analyticProperties.title]: "ATS: Stages flow report",
  //   });
  //
  //   const fileName = "recruiting-stage-flow-report";
  //   const csvData = new Blob([convertToCSV(report)], { type: "text/csv" });
  //   const csvURL = URL.createObjectURL(csvData);
  //   const link = document.createElement("a");
  //   link.href = csvURL;
  //   link.download = `${fileName}.csv`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }, [report]);

  return (
    <>
      <Box className={"bg-light h-full overflow-y-auto"}>
        <Page.Header title={t("Hiring pipeline")}>
          {/*<Button onClick={handleDownloadCSV} size={"sm"}>*/}
          {/*  {t("Export to CSV")}*/}
          {/*</Button>*/}

          <Stack direction={"horizontal"} gap={"sm"} className={"overflow-hidden"}>
            <RecruitingReportingButtonChooseGroupBy
              value={filter.group_by}
              onChange={(group) => {
                setSearchParams((searchParams) => {
                  searchParams.set("group_by", group);
                  return searchParams;
                });
              }}
            />
            <RecruitingReportingButtonChooseAggregationDate
              value={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.week}
              onChange={(e: ApiAnalyticsAggregationDate) => {
                setSearchParams((searchParams) => {
                  searchParams.set("aggregation_date_mode", e.toString());
                  return searchParams;
                });
              }}
            />
            <RecruitingReportingButtonChoosePeriod
              aggregationType={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.week}
              dateFrom={filter.date_start}
              dateTo={filter.date_end}
            />

            <RecruitingReportingButtonFilters
              filter={filter}
              reportType={AnalyticReportTypes.recruitingStageFlow}
            />
          </Stack>
        </Page.Header>

        <Page.Content>
          <Box>
            <RecruitingReportingRecruitersSelector
              recruiterIds={filter.recruiter_ids || []}
              onChange={(recruiterIds) => {
                setSearchParams((searchParams) => {
                  searchParams.set("recruiter_ids", recruiterIds.join(","));
                  return searchParams;
                });
              }}
            />
          </Box>

          <RecruitingStageFlowReportTable report={report} filter={filter} isLoading={isLoading} />
        </Page.Content>
      </Box>
    </>
  );
};
