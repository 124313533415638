import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import React, { ElementType, ReactElement } from "react";
import { PolymorphicComponentPropWithRef } from "../../helpers/generic-as-prop.ts";

export interface ButtonSizes extends Pick<ThemeSizes, "sm" | "md" | "lg"> {
  [key: string]: string;
}

export interface ButtonVariant {
  primary: string;
  secondary: string;
  plain: string;
  menu: string;
  danger: string;
  menuDanger: string;
  // tertiary: string;
}

export interface UIKitButtonTheme extends UIKitThemeBase {
  variants: {
    size: ButtonSizes;
    variant: ButtonVariant;
  };
}

//rounded-md font-medium outline-0 gap-2 items-center flex
export const buttonTheme: UIKitButtonTheme = {
  base: "rounded-md outline-0 gap-2 items-center flex transition-all ease-in ",
  variants: {
    variant: {
      primary: "bg-brand-dark text-light justify-center shadow-md hover:shadow-lg",
      secondary: "bg-brand-dark/5 text-brand-dark hover:bg-brand-dark/10 justify-center ",
      danger: "bg-brand-dark/5 hover:bg-brand-dark/10 justify-center text-danger",
      plain: "justify-center text-brand-dark hover:bg-dark/5",
      menu: "text-brand-dark bg-light hover:bg-dark/5 rounded-lg justify-start w-full",
      menuDanger: "text-danger bg-light hover:bg-dark/5 rounded-lg justify-start w-full",
    },
    size: {
      sm: "text-sm px-2 h-6 font-semibold",
      md: "text-base px-2 h-7 font-semibold",
      lg: "text-lg px-4 h-12 font-semibold",
    },
  },
};

export type ButtonProps<T extends ElementType = "button"> = PolymorphicComponentPropWithRef<
  T,
  {
    children?: React.ReactNode;
    href?: string;
    blank?: boolean;
    variant?: keyof ButtonVariant;
    size?: keyof ButtonSizes;
    isLoading?: boolean;
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
  }
>;
export type ButtonIconProps<T extends ElementType = "button"> = PolymorphicComponentPropWithRef<
  T,
  {
    variant?: keyof ButtonVariant;
    icon: ReactElement;
    size?: keyof ButtonSizes;
  }
>;

export type ButtonComponentType = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactNode | null;

export type ButtonIconComponentType = <C extends React.ElementType = "button">(
  props: ButtonIconProps<C>
) => React.ReactNode | null;
