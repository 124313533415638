import { Page } from "../../components/page/Page.tsx";
import FullCalendar from "@fullcalendar/react";
import { useMemo, useRef, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import {
  ApiEvent,
  ApiEventFilter,
  ApiEventTypes,
  ApiEventWithOptionalId,
} from "../../types/event.types.ts";
import { DatesSetArg, EventInput } from "@fullcalendar/core";
import ruLocale from "@fullcalendar/core/locales/ru";
import enLocale from "@fullcalendar/core/locales/en-gb";
import Button from "../../ui/button/Button.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { useEvents } from "../../queries/use-events.query.ts";
import { EventInCalendar } from "./components/EventInCalendar.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { AnalyticReportTypes } from "../../types/analytics/analytics.types.ts";
import { useTranslation } from "react-i18next";
import useModals from "../../ui/modal/modal.store.ts";
import { EventEditModal } from "../../components/event/EventEditModal.tsx";
import { DefaultTimezone } from "../../services/timezone-service.ts";

export function Calendar() {
  const [filter, setFilter] = useState<ApiEventFilter>({
    date_start: undefined,
    date_end: undefined,
  });
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const calendarRef = useRef<FullCalendar>(null);
  const { t } = useTranslation();
  const modalsService = useModals();
  const { data: apiEvents } = useEvents(filter);
  const { employee } = useEmployeeStore();
  const fullCalendarLocale = useMemo(() => {
    if (!employee) return enLocale;
    if (employee.language == "ru") return ruLocale;

    return enLocale;
  }, [employee]);

  const transformEventForCalendar = (event: ApiEvent): EventInput => {
    const hours = event.time_start.getHours();
    const minutes = event.time_start.getMinutes();
    const seconds = event.time_start.getSeconds();
    const secondsSinceStartOfDay = hours * 3600 + minutes * 60 + seconds;

    return {
      id: `${new Date().getTime()}`, // если есть уникальный идентификатор
      title: event.title,
      start: new Date(event.date_event.getTime() + secondsSinceStartOfDay * 1000),
      end: new Date(
        event.date_event.getTime() + secondsSinceStartOfDay * 1000 + event.duration_minutes * 60000
      ),
      allDay: false,
      extendedProps: event,
    };
  };

  const handleDatesSet = (arg: DatesSetArg) => {
    const calendarApi = arg.view.calendar;

    setFilter({
      date_start: arg.start,
      date_end: arg.end,
    });

    setCurrentTitle(calendarApi.view.title);
  };

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.prev();
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.next();
  };

  const handleTodayClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.today();
  };

  const handleEditOrAddEvent = (
    event: ApiEvent | undefined = undefined,
    dateArgs: DateClickArg | undefined = undefined
  ) => {
    const eventToEdit: ApiEventWithOptionalId = event || {
      title: "",
      type: ApiEventTypes.other,
      date_event: dateArgs ? dateArgs.date : new Date(),
      time_start: dateArgs ? dateArgs.date : new Date(2000, 1, 1, 12, 0),
      timezone: employee?.timezone || DefaultTimezone,
      duration_minutes: 30,
      comment: "",
      location: "",
      is_private: false,
      guests: [],
      is_cancelled: false,
    };

    modalsService.openModal(EventEditModal, {
      event: eventToEdit,
      eventId: event?.id ?? undefined,
    });
  };

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.events.calendarViewed, {
      [analyticProperties.id]: AnalyticReportTypes.recruitingStagesFlow,
    });
  });

  return (
    <Page className="h-full overflow-y-auto">
      <Page.Header
        title={currentTitle}
        titleSlot={
          <Stack gap={"xs"} direction={"horizontal"} className={"ml-2"}>
            <Button
              variant={"secondary"}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-chevron-left" />}
              onClick={handlePrevClick}
            />
            <Button variant={"secondary"} onClick={handleTodayClick}>
              {t("Today")}
            </Button>
            <Button
              variant={"secondary"}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-chevron-right" />}
              onClick={handleNextClick}
            />
          </Stack>
        }
      >
        <Button
          leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
          onClick={() => {
            handleEditOrAddEvent();
          }}
        >
          {t("Add event")}
        </Button>
      </Page.Header>
      <Page.Content className={"h-full overflow-y-auto"}>
        <FullCalendar
          height={"auto"}
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          locale={fullCalendarLocale}
          dateClick={(arg: DateClickArg) => {
            handleEditOrAddEvent(undefined, arg);
          }}
          initialView="timeGridWeek"
          datesSet={handleDatesSet}
          views={{
            timeGridDay: {
              slotLabelFormat: { hour: "2-digit", minute: "2-digit", hour12: false },
            },
            timeGridWeek: {
              slotLabelFormat: { hour: "2-digit", minute: "2-digit", hour12: false },
            },
          }}
          dayHeaderFormat={{
            weekday: "short", // example: "Sat", "Mon"
            day: "numeric", // example: "10"
          }}
          allDaySlot={false}
          headerToolbar={false}
          events={apiEvents?.map((event) => transformEventForCalendar(event)) ?? []}
          nowIndicator={true}
          eventContent={(eventInfo) => <EventInCalendar eventInfo={eventInfo} />}
        />
      </Page.Content>
    </Page>
  );
}
