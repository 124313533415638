import { ModalHeaderProps } from "./modal.types.ts";
import useModals from "./modal.store.ts";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import Stack from "../stack/Stack.tsx";
import Button from "../button/Button.tsx";
import { useTheme } from "../themes/provider.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx";
import { Title } from "../../components/title/Title.tsx";

export function ModalHeader({ title, withCloser, id, onBack }: ModalHeaderProps) {
  const { close } = useModals();
  const theme = useTheme("modal");

  return (
    <Stack
      className={theme.header}
      gap={"sm"}
      direction={"horizontal"}
      justify={"between"}
      items={"center"}
    >
      <Stack direction={"horizontal"} gap={"sm"}>
        {onBack && (
          <ButtonIcon
            onClick={onBack}
            variant={"secondary"}
            icon={<FontAwesomeIcon icon="fa-light fa-arrow-left" />}
          />
        )}
        <Title header={title || ""} size={"lg"} />
      </Stack>

      {withCloser && (
        <Button
          className={theme.closer}
          variant={"plain"}
          leftIcon={<FontAwesomeIcon icon={"fa-light fa-close"} />}
          onClick={() => {
            close(id);
          }}
        />
      )}
    </Stack>
  );
}
