import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import Select from "../../../ui/select/Select.tsx";
import Button from "../../../ui/button/Button.tsx";
import getTimezones from "../../../services/timezone-service.ts";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import {
  ApiOrganizationSettingsSavePayload,
  DateFormatOptions,
  Dates,
  LanguageOptions,
  Languages,
  NameFormatOptions,
} from "../../../types/organization.types.ts";
import { Page } from "../../../components/page/Page.tsx";
import { Input } from "../../../ui/input/Input.tsx";
import Avatar from "../../../ui/avatar/Avatar.tsx";
import Box from "../../../ui/box/Box.tsx";
import * as yup from "yup";
import i18n from "../../../i18n.ts";
import { EmployeeFullNameRenderType } from "../../../types/employee.types.ts";
import { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { ImageUploadModal } from "../../../components/avatar-upload/ImageUploadModal.tsx";
import { ApiFilePathTypes } from "../../../types/file.types.ts";
import { ApiFile } from "../../../types/common.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import {
  useOrganizationDeleteLogo,
  useOrganizationSetLogo,
  useOrganizationSettings,
  useOrganizationUpdateSettings,
} from "../../../queries/settings/use-organization.query.ts";
import { useEffectOnce } from "react-use";
import { Title } from "../../../components/title/Title.tsx";

export default function SettingsOrganization() {
  const { t } = useTranslation();
  const { showInfo } = useToasts();
  const { employee } = useEmployeeStore();
  const modalsService = useModals();

  const SettingsSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Field is required")),
    language: yup.mixed<Languages>().oneOf(Object.values(Languages)),
    timezone: yup.string(),
    employee_name_format: yup
      .mixed<EmployeeFullNameRenderType>()
      .oneOf(Object.values(EmployeeFullNameRenderType)),
    default_date_format: yup.mixed<Dates>().oneOf(Object.values(Dates)),
    subdomain: yup.string().required(i18n.t("Field is required")),
  });

  interface SettingsSchema extends yup.InferType<typeof SettingsSchemaObject> {}

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<SettingsSchema>({
    mode: "onSubmit",
    resolver: yupResolver(SettingsSchemaObject),
  });

  const { data: organizationSettings } = useOrganizationSettings();
  const organizationUpdate = useOrganizationUpdateSettings(control);
  const organizationLogoDelete = useOrganizationDeleteLogo();
  const organizationLogoSet = useOrganizationSetLogo();

  useEffect(() => {
    if (organizationSettings) {
      reset(organizationSettings);
    }
  }, [organizationSettings]);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.organization.settingsViewed);
  });

  const onSubmit = handleSubmit(async (settingsData) => {
    const settingsToUpdate: ApiOrganizationSettingsSavePayload = {
      title: settingsData.title,
      language: settingsData.language,
      timezone: settingsData.timezone,
      employee_name_format: settingsData.employee_name_format,
      default_date_format: settingsData.default_date_format,
      subdomain: settingsData.subdomain,
    };

    organizationUpdate.mutate(settingsToUpdate, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
          [analyticProperties.actionType]: "Details Edited",
        });
        showInfo(t("Your changes were saved"));
      },
    });
  });

  const availableOptions = useMemo(() => {
    if (employee)
      return LanguageOptions.filter((option) =>
        employee.available_languages.some((language) => language == option.id)
      );
    return [];
  }, [employee]);

  const handleAvatarUpload = () => {
    if (!employee) return;
    modalsService.openModal(ImageUploadModal, {
      modalTitle: t("Logo"),
      path: ApiFilePathTypes.organization_logo,
      onSave: async (file: ApiFile) => {
        try {
          await organizationLogoSet.mutateAsync(file.id);
          analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
            [analyticProperties.actionType]: "Photo Updated",
          });
          return true;
        } catch {
          return false;
        }
      },
    });
  };

  const handleAvatarDelete = async () => {
    if (!employee) return;

    try {
      await organizationLogoDelete.mutateAsync();
      analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
        [analyticProperties.actionType]: "Photo Deleted",
      });
    } catch {
      return;
    }
  };

  return (
    <>
      <Page>
        <Page.Header title={t("Organization Settings")} showBack />
        <Page.Content>
          <Stack gap={"xl"} className={"container-sm mx-auto"}>
            <form onSubmit={onSubmit}>
              <Title
                size={"lg"}
                paddingBottom
                paddingTop
                header={t("Company Details")}
                caption={t(
                  "In the Organization settings you can fill in the necessary information about your company"
                )}
              />

              <Input
                label={t("Company name")}
                required={true}
                type={"text"}
                {...register("title", {
                  value: "",
                })}
                error={errors.title?.message}
              />

              <Controller
                control={control}
                name="language"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Default Language")}
                    options={availableOptions}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    error={errors.language?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="timezone"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Default Timezone")}
                    options={getTimezones()}
                    emptyTitle={t("Select")}
                    searchable
                    value={value}
                    onChange={onChange}
                    error={errors.timezone?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="employee_name_format"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Name display")}
                    options={NameFormatOptions}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    error={errors.employee_name_format?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="default_date_format"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Date format")}
                    options={DateFormatOptions}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    error={errors.default_date_format?.message}
                  />
                )}
              />
              <Title size={"xs"} paddingBottom paddingTop header={t("Account Details")} />
              <Input
                label={t("Company domain")}
                required={true}
                type={"text"}
                {...register("subdomain", {
                  value: "",
                })}
                error={errors.subdomain?.message}
              />

              <Title size={"xs"} paddingBottom paddingTop header={t("Logo")} />

              <Stack className={"bg-dark/5 p-5 rounded-md"} justify={"center"} items={"center"}>
                <DropdownMenu
                  trigger={
                    employee && (
                      <Box className={"relative w-40 h-40 group cursor-pointer "}>
                        <Avatar
                          url={organizationSettings?.logo_url}
                          size={"2xl"}
                          acronym={getValues("title")}
                        />
                        <Box
                          className={
                            "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-lg flex items-center justify-center"
                          }
                        >
                          <FontAwesomeIcon
                            icon={"fa-light fa-camera"}
                            className={"text-[40px] text-light "}
                          />
                        </Box>
                      </Box>
                    )
                  }
                >
                  <Stack gap={"sm"}>
                    <Button
                      variant={"menu"}
                      leftIcon={<FontAwesomeIcon icon={"fa-light fa-image"} />}
                      onClick={handleAvatarUpload}
                    >
                      {t("Upload new")}
                    </Button>
                    <Button
                      variant={"menu"}
                      leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                      onClick={handleAvatarDelete}
                      className={"text-danger"}
                    >
                      {t("Delete")}
                    </Button>
                  </Stack>
                </DropdownMenu>
              </Stack>
              <ServerErrorField errors={errors} />
            </form>
            <Button isLoading={organizationUpdate.isPending} onClick={onSubmit} size={"lg"}>
              {t("Save")}
            </Button>
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
