import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { apiDepartments } from "../../../api/department.api.ts";
import { Chart, ChartNodeData } from "../../../components/chart/Chart.tsx";
import { ApiDepartmentChart } from "./departments-chart.types.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";

export function DepartmentsChart() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName } = useFormatter();
  const [chart, setChart] = useState<ChartNodeData[]>([]);

  const loadDepartments = useCallback(async (departmentId: number | undefined) => {
    const { data: departments } = await apiDepartments.chart(departmentId);

    return departments;
  }, []);

  useEffect(() => {
    loadDepartments(undefined).then((departments) => {
      departments && appendDepartmentsToChart(departments, undefined);
    });
  }, []);

  const renderCation = (department: ApiDepartmentChart): string => {
    const nameManager = department.manager ? renderEmployeeName(department.manager) : "";
    const otherCount =
      department.employees_count > 1
        ? ` ${t("and_other_count", { count: department.employees_count - 1 })}`
        : "";

    return nameManager
      ? `${nameManager}${otherCount}`
      : t("employees_count", { count: department.employees_count });
  };

  const appendDepartmentsToChart = useCallback(
    (departments: ApiDepartmentChart[], parentId: number | undefined) => {
      const nodes: ChartNodeData[] = [];

      if (!parentId) {
        nodes.push({
          title: employee?.organization?.title ?? t("Organisation"),
          img_url: employee?.organization?.logo_url ?? "/skailer-dark.svg",
          id: "root",
          parentId: null,
          count: departments.length,
          isRoot: true,
        });
      }

      departments.forEach((department) => {
        nodes.push({
          title: department.title,
          caption: renderCation(department),
          img_url: department.manager?.avatar_url ?? null,
          id: `${department.id}`,
          parentId: department.parent_id ? `${department.parent_id}` : "root",
          count: department.direct_subordinates_count,
          isRoot: false,
        });
      });

      setChart([...chart, ...nodes]);
    },
    [chart]
  );

  const onTitleClick = useCallback(
    async (nodeId: string) => {
      console.log(nodeId);
    },
    [chart]
  );

  const onExpandClick = useCallback(
    (nodeId: string, needLoadData: boolean) => {
      if (nodeId == "root") return;
      if (!needLoadData) return;

      const id = Number(nodeId);

      nodeId &&
        loadDepartments(id).then((departments) => {
          departments && appendDepartmentsToChart(departments, id);
        });
    },
    [chart]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.departments.viewedChart);
  });

  return (
    <Page className={"bg-light/80 h-full"}>
      <Page.Header showBack={true} title={t("Departments")} />
      {chart.length > 0 && (
        <Chart data={chart} onExpandClick={onExpandClick} onTitleClick={onTitleClick} />
      )}
    </Page>
  );
}
