import Box from "../../../../../ui/box/Box.tsx";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import DropdownMenu from "../../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import { EditRoleSchema } from "../role-edit.types.ts";
import useRoleEditService from "../role-edit-service.ts";
import { EmployeeFieldPermissions, PermissionType } from "../../../../../types/role.types.ts";

export interface RoleEditEmployeePermissionFieldProps {
  item: FieldPermissionConfigItem;
  register: UseFormRegister<EditRoleSchema>;
  control: Control<EditRoleSchema>;
}

export interface FieldPermissionConfigItem {
  title: string;
  field: `employee_permissions.${keyof typeof EmployeeFieldPermissions}`;
  availableOptions: PermissionType[];
}

export function RoleEditPermissionField({
  item,
  register,
  control,
}: RoleEditEmployeePermissionFieldProps) {
  const { permissionTypesTexts } = useRoleEditService();

  return (
    <ListItem
      preTitle={item.title}
      valueSlot={
        <Box className={"w-1/2"}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <DropdownMenu
                trigger={
                  <Text
                    className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                    tabIndex={0}
                  >
                    {permissionTypesTexts[value]}
                  </Text>
                }
              >
                {item.availableOptions.map((option, optionIndex) => (
                  <Button
                    variant={"menu"}
                    key={`employee-field-permission-option-${optionIndex}`}
                    value={option}
                    onClick={() => {
                      onChange(option);
                    }}
                    {...register(item.field)}
                  >
                    <Stack
                      direction={"horizontal"}
                      justify={"between"}
                      className={"w-full"}
                      items={"center"}
                    >
                      {permissionTypesTexts[option]}
                      {option == value && <FontAwesomeIcon icon="fa-light fa-check" />}
                    </Stack>
                  </Button>
                ))}
              </DropdownMenu>
            )}
            control={control}
            name={item.field}
          />
        </Box>
      }
    ></ListItem>
  );
}
