import Box from "../../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";
import Text from "../../../../ui/typography/Text.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Fragment, useEffect, useState } from "react";
import { apiFiles } from "../../../../api/file.api.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { apiApplicants } from "../../../../api/recruit/applicant.api.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import {
  ApiApplicantCV,
  ApiApplicantCVEducation,
  ApiApplicantCVExperience,
  ApiApplicantCVLanguage,
  ApiApplicantCVSkill,
} from "../../../../types/recruit/applicant.cv.types.ts";
import useErrorHandle from "../../../../hooks/use-error-handle.hook.tsx";
import { ApiApplicant } from "../../../../types/recruit/applicant.types.ts";
import { Title } from "../../../../components/title/Title.tsx";

export interface ApplicantProfileCVProps {
  applicant: ApiApplicant;
  cv: ApiApplicantCV;
}

export default function ApplicantProfileCV({ applicant, cv }: ApplicantProfileCVProps) {
  const { t } = useTranslation();
  const { formatExperiencePeriod, renderRelativeDate } = useFormatter();
  const { handleErrors } = useErrorHandle();

  const [pdfLink, setPdfLink] = useState<string | null>(null);

  useEffect(() => {
    const checkFileCV = async () => {
      if (cv.source_file.mime_type == "application/pdf") {
        const { data: signedLink, error } = await apiFiles.getLink(cv.source_file.uuid, 1);
        if (signedLink) {
          setPdfLink(signedLink.download_url);
        } else {
          handleErrors(error);
        }
      }
    };
    checkFileCV();
  }, []);

  const [isExporting, setIsExporting] = useState(false);

  const downloadCV = async (type: string) => {
    setIsExporting(true);
    const { data, headers, error } = await apiApplicants.downloadCV(applicant.id, cv.id, type);
    setIsExporting(false);
    if (data && headers) {
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } else {
      handleErrors(error);
    }

    analyticsService.trackEvent(analyticEvents.applicants.cvDownloaded, {
      [analyticProperties.id]: applicant.id,
      [analyticProperties.format]: type,
    });
  };

  const calculateEducationPeriod = (education: ApiApplicantCVEducation) => {
    if (!education.education_date_from) return null;

    if (
      !education.education_date_from.year &&
      !education.education_date_from.month &&
      !education.education_date_from.day
    )
      return null;

    const from = education.education_date_from;
    const to = education.education_date_to;
    return formatExperiencePeriod(from, to);
  };

  return (
    <>
      <Stack direction={"vertical"} className={"mt-xl"} gap={"xl"}>
        <Stack gap={"sm"}>
          <Title
            header={`${cv.personal_info.first_name} ${cv.personal_info.last_name}`}
            caption={`${t("Updated")} ${renderRelativeDate(cv.datetime_updated_at)}`}
          />
          <Stack direction={"horizontal"} items={"center"} gap={"sm"}>
            {cv.source_url && (
              <Button
                variant={"secondary"}
                href={cv.source_url}
                size={"sm"}
                blank
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-arrow-up-right-from-square"} />}
              >
                {t("View original")}
              </Button>
            )}
            <DropdownMenu
              trigger={
                <>
                  <Button
                    variant={"secondary"}
                    isLoading={isExporting}
                    size={"sm"}
                    blank
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-download"} />}
                  >
                    {t("Download")}
                  </Button>
                </>
              }
            >
              <Stack gap={"sm"}>
                <Button onClick={() => downloadCV("pdf")} variant={"menu"}>
                  {t("PDF")}
                </Button>
                <Button onClick={() => downloadCV("docx")} variant={"menu"}>
                  {t("Docx")}
                </Button>
              </Stack>
            </DropdownMenu>
          </Stack>
        </Stack>
        <hr />
        {cv.cv_text && (
          <>
            <Text className={"text-secondary whitespace-pre-wrap"}>{cv.cv_text}</Text>
            <hr />
          </>
        )}
        {cv.experience && cv.experience.length > 0 && (
          <>
            <Stack>
              <Title header={t("Experience")} size={"xs"} />

              <Stack gap={"lg"} className={"pt-md"}>
                {cv.experience.map((experience: ApiApplicantCVExperience, index: number) => (
                  <Fragment key={`experience-${index}`}>
                    <Stack>
                      <Text className={"text-title text-lg"}>{experience.company}</Text>
                      <Text className={"text-default"}>{experience.position}</Text>

                      <Text className={"text-secondary"}>
                        {experience.position_date_from &&
                          formatExperiencePeriod(
                            experience.position_date_from,
                            experience.position_date_to
                          )}
                      </Text>

                      <Box className={"pt-md"}>
                        <Text className={"text-default whitespace-pre-wrap"}>
                          {experience.description}
                        </Text>
                      </Box>
                    </Stack>

                    <hr />
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          </>
        )}
        {cv.education && cv.education.length > 0 && (
          <>
            <Stack>
              <Title header={t("Education")} size={"xs"} />
              <Stack gap={"lg"} className={"pt-md"}>
                {cv.education.map((education: ApiApplicantCVEducation, index: number) => (
                  <Fragment key={`education-${index}`}>
                    <Stack>
                      <Text className={"text-title text-lg"}>{education.title}</Text>
                      <Text className={"text-default"}>
                        {education.level}
                        {education.field_of_study && `, ${education.field_of_study}`}
                      </Text>
                      {calculateEducationPeriod(education) && (
                        <Text className={"text-secondary"}>
                          {calculateEducationPeriod(education)}
                        </Text>
                      )}

                      <Text className={"text-default"}>
                        {education.location.city}
                        {education.location.address && `. ${education.location.address}`}
                        {education.location.country && ` (${education.location.country})`}
                      </Text>
                    </Stack>
                    <hr />
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          </>
        )}

        {cv.skills && cv.skills.length > 0 && (
          <>
            <Stack>
              <Title header={t("Skills")} size={"xs"} />
              <Stack gap={"xs"} direction={"horizontal"} className={"flex-wrap pt-md"}>
                {cv.skills.map((skill: ApiApplicantCVSkill, index: number) => (
                  <Text
                    className={"py-1 px-2 bg-dark/5 text-secondary rounded-md"}
                    key={`skill${index}`}
                  >
                    {skill.title}
                  </Text>
                ))}
              </Stack>
            </Stack>
            <hr />
          </>
        )}
        {cv.languages && cv.languages.length > 0 && (
          <>
            <Stack>
              <Title header={t("Languages")} size={"xs"} />
              <Stack gap={"sm"} className={"mt-md"}>
                {cv.languages.map((language: ApiApplicantCVLanguage) => (
                  <Stack key={language.title}>
                    <Text className={"text-title text-lg"}>{language.title}</Text>
                    {language.level && <Text className={"text-default"}>{language.level}</Text>}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </>
        )}
        {pdfLink && (
          <Box className={"border-black border-2"}>
            <iframe
              src={pdfLink}
              width="100%"
              height="600px"
              loading="lazy"
              title="PDF-file"
            ></iframe>
          </Box>
        )}
      </Stack>
    </>
  );
}
