import { Permissions } from "../../types/role.types.ts";
import i18n from "i18next";
import { SidebarCompany } from "./components/SidebarCompany.tsx";
import { SidebarRecruitment } from "./recruit/SidebarRecruitment.tsx";

export interface MenuConfigItem {
  title: string;
  icon: string;
  to: string;
  moduleUrlPrefixes: string[];
  component: JSX.Element;
  permissions?: Permissions[];
}

export const MenuConfigTop = () => {
  return [
    {
      title: i18n.t("Team"),
      icon: "fa-users-rectangle",
      to: "/employees",
      moduleUrlPrefixes: ["/employees", "/account-settings", "/email-confirmed"],
      component: <SidebarCompany />,
    },
    {
      title: i18n.t("Recruit"),
      icon: "fa-folder-magnifying-glass",
      component: <SidebarRecruitment />,
      to: "/recruitment/candidates",
      moduleUrlPrefixes: ["/recruitment"],
      permissions: [Permissions.recruiting_module],
    },
    {
      title: i18n.t("Calendar"),
      icon: "fa-calendar-week",
      to: "/calendar",
      moduleUrlPrefixes: ["/calendar"],
    },
  ] as MenuConfigItem[];
};

export interface SidebarConfigItem {
  to: string;
  title: string;
  isActive: boolean;
  count?: number;
  emoji?: string;
  subtitle?: string;
}
