import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
  GenericAbortSignal,
  InternalAxiosRequestConfig,
} from "axios";
import useToasts from "../hooks/use-toasts.hook.tsx";
import { useAuthStore, useEmployeeStore } from "../stores/employee.store.ts";
import { handleDatesRequest, handleDatesResponse } from "../helpers/handle-date.ts";
import { ApiError, ApiErrors, ApiValidationError } from "../types/api.types.ts";
import i18n from "../i18n.ts";

export interface ApiResponse<T> {
  data?: T;
  success: boolean;
  error?: ApiError;
  headers?: AxiosResponseHeaders;
}

//https://www.educative.io/courses/vue-the-road-to-enterprise/api-layer
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  headers: {
    Accept: "application/json",
    // "Content-Type": "application/json",
    responseType: "json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = useAuthStore.getState().token;

  if (!token) {
    // console.log('No token')
    return config;
  }

  const userId = useEmployeeStore.getState().employee?.id;
  config.headers.Authorization = `Bearer ${token}`;

  if (userId) {
    config.headers["X-User-Id"] = userId.toString();
  }

  return config;
});

//https://bilot.group/articles/using-react-router-inside-axios-interceptors/
function handleSuccessResponse(response: AxiosResponse) {
  // console.log('response', response)
  handleDatesResponse(response.data);

  return { data: response.data, success: true, headers: response.headers } as any;
}

function handleErrorResponse(error: unknown) {
  if (axios.isAxiosError(error)) {
    console.log("axios.isAxiosError(error)", error);
    // https://gist.github.com/bmnepali/fa0603d97e7fa0eb2d7ac9be7e50224b
    if (error.response?.status == 401) {
      // const newToken = await refreshToken();
      // localStorage.setItem('authToken', newToken);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      // useAuthStore().setToken(null);
      if (window.location.pathname !== "/login") window.location.href = "/login";
    } else if (error.response?.status == 403) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToasts().showError(i18n.t("Access denied"));
    } else if (error.response?.status == 404) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToasts().showError(i18n.t("Oops! We couldn't find requested document"));
    } else if (error.response?.status == 500) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToasts().showError("Oops! Something went wrong on our end. Please try again later.");
    } else if (error.response?.status == 422) {
      return {
        success: false,
        error: {
          status: ApiErrors.validationError,
          validationErrors: error.response?.data.error.validation_errors || [],
        } as ApiValidationError,
      };
    }
  } else {
    // console.log('unexpected error: ', error);
    //     // return 'An unexpected error occurred';
    //     return {error: 'An unexpected error occurred', status: 'error'}
  }

  // return {error: 'An unexpected error occurred', status: 'error'}
  return { success: false, error: error };
}

// Request Interceptor
const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { method } = config;
  if (method == "post" || method == "put") {
    handleDatesRequest(config.data);
  }

  return config;
};

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(handleSuccessResponse, handleErrorResponse);

// Main api function
const apiInstanceTyped = (instance: AxiosInstance) => {
  return {
    get: <T>(
      url: string,
      params: object = {},
      signal: GenericAbortSignal | undefined = undefined,
      config: AxiosRequestConfig<T> | undefined = undefined
    ): Promise<ApiResponse<T>> =>
      instance.get<never, ApiResponse<T>>(url, {
        signal,
        params,
        ...config,
      }),
    post: <T>(
      url: string,
      body: object,
      signal: GenericAbortSignal | undefined = undefined
    ): Promise<ApiResponse<T>> => instance.post<never, ApiResponse<T>>(url, body, { signal }),
    put: <T>(url: string, body: object): Promise<ApiResponse<T>> =>
      instance.put<never, ApiResponse<T>>(url, body),
    delete: <T>(url: string): Promise<ApiResponse<T>> =>
      instance.delete<never, ApiResponse<T>>(url),
  };
};

export const api = apiInstanceTyped(axiosInstance);

// patch: (url, body, config) => instance.patch(url, body, config),
