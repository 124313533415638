import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { ApiPipeline } from "../../../../types/recruit/pipeline.types.ts";
import { PipelineEditModal } from "./widgets/PipelineEditModal.tsx";
import { Page } from "../../../../components/page/Page.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { Card } from "../../../../ui/card/Card.tsx";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import { SettingsCard } from "../../components/SettingsCard.tsx";
import { usePipelines } from "../../../../queries/recruit/use-pipelines.query.ts";
import { useEffectOnce } from "react-use";
import {
  useStageDelete,
  useStages,
  useStageSort,
} from "../../../../queries/recruit/use-stages.query.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { StageEditModal } from "./widgets/StageEditModal.tsx";
import {
  ApiPipelineStage,
  getStageEmoji,
  PipelineStageTypes,
  StageEmojiDefault,
} from "../../../../types/recruit/stages.types.ts";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { Title } from "../../../../components/title/Title.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";

export default function Pipelines() {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { data: pipelines, isLoading } = usePipelines();
  const { data: stages, isLoading: isLoadingStages } = useStages();
  const stageDelete = useStageDelete();
  const stagesSort = useStageSort();

  const handleEditOrCreatePipeline = useCallback(
    (pipeline: ApiPipeline | undefined = undefined) => {
      const pipelineToEdit = pipeline || {
        id: 0,
        title: "",
        is_default: false,
        stages: [],
        disqualified_candidates_count: 0,
        reserved_candidates_count: 0,
      };

      modalsService.openModal(PipelineEditModal, {
        pipeline: pipelineToEdit,
      });
    },
    []
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.pipelines.viewed);
  });

  const handleEditOrCreateStage = useCallback((stage: ApiPipelineStage | undefined = undefined) => {
    const stageToEdit = stage || {
      id: 0,
      title: "",
      sort: 0,
      applicants_count: 0,
      type: PipelineStageTypes.custom,
      emoji: StageEmojiDefault,
    };
    modalsService.openModal(StageEditModal, {
      stage: stageToEdit,
    });
  }, []);

  const handleDeleteStage = useCallback((stage: ApiPipelineStage) => {
    stageDelete.mutate(stage.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.pipelines.stageDeleted, {
          [analyticProperties.id]: stage.id,
        });
      },
    });
  }, []);

  const onDragEnd = (result: DropResult) => {
    if (!stages) return;

    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const array = [...stages];
    const [removed] = array.splice(source.index, 1);
    array.splice(destination.index, 0, removed);

    stagesSort.mutate(
      array.map((stage) => stage.id),
      {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.pipelines.stagesSorted);
        },
      }
    );
  };

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Pipelines")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreatePipeline();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Stack direction={"vertical"} className={"py-6 gap-10"}>
            <Stack direction={"vertical"} gap={"md"}>
              <Title
                header={t("Pipelines")}
                size={"lg"}
                caption={t(
                  "Manage the stages of your recruitment process from initial application to final hire"
                )}
              />

              <Stack
                direction={"horizontal"}
                gap={"md"}
                className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
              >
                {isLoading && (
                  <Card className="cursor-pointer min-h-48 flex flex-col justify-between items-start">
                    <Stack gap="sm" className={"w-full"}>
                      <Skeleton className={"w-1/2 h-6"}></Skeleton>
                      <Skeleton className={"w-full h-5"}></Skeleton>
                    </Stack>
                    <Skeleton className={"w-1/2 h-6"}></Skeleton>
                  </Card>
                )}

                {pipelines?.map((pipeline) => (
                  <SettingsCard
                    title={pipeline.title}
                    description={pipeline.is_default ? t("Default pipeline") : ""}
                    extra={t("plural_candidates", {
                      count:
                        pipeline.stages.length > 0
                          ? pipeline.stages
                              .map((stage) => stage.applicants_count)
                              .reduce((sum, current) => sum + current)
                          : 0,
                    })}
                    onClick={() => {
                      handleEditOrCreatePipeline(pipeline);
                    }}
                    key={`pipeline-${pipeline.id}`}
                  />
                ))}

                <SettingsCard
                  icon={"fa-plus"}
                  title={t("Create new")}
                  description={""}
                  variant={"action"}
                  onClick={() => {
                    handleEditOrCreatePipeline();
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={"vertical"} gap={"md"}>
              <Title
                header={t("Stages")}
                size={"lg"}
                caption={t(
                  "Ensure that the funnel stages reflect the actual business processes in the company"
                )}
              />
              <Stack gap={"md"} className={stagesSort.isPending ? "opacity-50" : undefined}>
                {isLoadingStages && (
                  <Stack gap="sm" className={"w-full"}>
                    <Skeleton className={"w-1/2 h-6"}></Skeleton>
                    <Skeleton className={"w-full h-5"}></Skeleton>
                  </Stack>
                )}

                {stages && stages.length > 0 && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"droppable"}>
                      {(provided) => (
                        <div
                          className={"flex flex-col divide-y divide-inherit border-dark/5"}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {stages?.map((stage, index) => (
                            <Draggable
                              key={stage.id}
                              draggableId={`item-${stage.id}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={"flex justify-between min-h-14 items-center py-2"}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <Stack direction={"horizontal"} gap={"sm"}>
                                    <div
                                      className={"w-10 flex items-center justify-center shrink-0"}
                                      {...provided.dragHandleProps}
                                    >
                                      <FontAwesomeIcon icon="fa-light fa-grip-dots-vertical" />
                                    </div>
                                    <Stack className={"w-10 shrink-0"} items={"center"}>
                                      <Avatar
                                        url={undefined}
                                        emoji={getStageEmoji(stage)}
                                        size={"md"}
                                      />
                                    </Stack>
                                    <Stack className={"w-[450px]"}>
                                      <Text className={"text-title"}>{stage.title}</Text>
                                      {stage.type === PipelineStageTypes.applied && (
                                        <Text className={"text-secondary"}>
                                          {t(
                                            "All new candidates start here. This is a system status and cannot be deleted."
                                          )}
                                        </Text>
                                      )}
                                      {stage.type === PipelineStageTypes.offer && (
                                        <Text className={"text-secondary"}>
                                          {t(
                                            "The candidate has passed all interviews and assessments and has been given a job offer. This is a system status and cannot be deleted."
                                          )}
                                        </Text>
                                      )}
                                      {stage.type === PipelineStageTypes.hired && (
                                        <Text className={"text-secondary"}>
                                          {t(
                                            "The candidate has completed all stages of the recruitment process and accepted the job offer. The onboarding process begins. This is a system status and cannot be deleted."
                                          )}
                                        </Text>
                                      )}
                                    </Stack>
                                    <Stack className={"px-2"}>
                                      <Text className={"text-secondary"}>
                                        {t("plural_candidates", { count: stage.applicants_count })}
                                      </Text>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    className={"justify-end"}
                                    gap={"sm"}
                                    direction={"horizontal"}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleEditOrCreateStage(stage);
                                      }}
                                      variant={"secondary"}
                                    >
                                      {t("Edit")}
                                    </Button>

                                    <DropdownMenu>
                                      <Button
                                        onClick={() => {
                                          handleDeleteStage(stage);
                                        }}
                                        leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                                        variant={"menu"}
                                      >
                                        {t("Delete")}
                                      </Button>
                                    </DropdownMenu>
                                  </Stack>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <Button
                  variant={"secondary"}
                  size={"lg"}
                  onClick={() => {
                    handleEditOrCreateStage();
                  }}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                >
                  {t("Add stage")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
