import {
  EmployeeFieldPermissions,
  PermissionType,
  RoleApplySignType,
  RoleAssignToConditionType,
} from "../../../../types/role.types.ts";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import { useTranslation } from "react-i18next";
import {
  EmployeeFieldPermissionConfigItem,
  PermissionsSection,
  RoleApplyToConditionsItems,
  RoleAssignToConditionsItems,
  RoleSignItems,
} from "./role-edit.types.ts";

export default function useRoleEditService() {
  const { t } = useTranslation();

  const getEmployeeFieldPermissionsConfig = () => {
    return [
      {
        title: t("Full name"),
        type: EmployeeFieldPermissions.employee_name,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_name",
      },
      {
        title: t("Employee #"),
        type: EmployeeFieldPermissions.employee_number,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_number",
      },
      {
        title: t("Hired on"),
        type: EmployeeFieldPermissions.employee_hired_on,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_hired_on",
      },
      {
        title: t("Email"),
        type: EmployeeFieldPermissions.employee_email,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_email",
      },
      {
        title: t("Email personal"),
        type: EmployeeFieldPermissions.employee_email_personal,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_email_personal",
      },
      {
        title: t("Phone"),
        type: EmployeeFieldPermissions.employee_phone,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_phone",
      },
      {
        title: t("Phone personal"),
        type: EmployeeFieldPermissions.employee_phone_personal,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_phone_personal",
      },
      {
        title: t("Gender"),
        type: EmployeeFieldPermissions.employee_gender,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_gender",
      },
      {
        title: t("Date birth"),
        type: EmployeeFieldPermissions.employee_date_birth,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_date_birth",
      },
      {
        title: t("Avatar"),
        type: EmployeeFieldPermissions.employee_avatar,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_avatar",
      },
      {
        title: t("Background image"),
        type: EmployeeFieldPermissions.employee_avatar,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_background",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const getEmployeeFieldPermissionsConfigAddress = () => {
    return [
      {
        title: t("Address line 1"),
        type: EmployeeFieldPermissions.employee_address_address_line_1,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_address_line_1",
      },
      {
        title: t("Address line 2"),
        type: EmployeeFieldPermissions.employee_address_address_line_2,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_address_line_2",
      },
      {
        title: t("City"),
        type: EmployeeFieldPermissions.employee_address_city,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_city",
      },
      {
        title: t("State"),
        type: EmployeeFieldPermissions.employee_address_state,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_state",
      },
      {
        title: t("Zip code"),
        type: EmployeeFieldPermissions.employee_address_zip_code,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_zip_code",
      },
      {
        title: t("Country"),
        type: EmployeeFieldPermissions.employee_address_country,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_country",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const getEmployeeFieldPermissionsConfigJob = () => {
    return [
      {
        title: t("Position"),
        type: EmployeeFieldPermissions.employee_position_job_title,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_job_title",
      },
      {
        title: t("Date effective from"),
        type: EmployeeFieldPermissions.employee_position_date,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_date",
      },
      {
        title: t("Manager"),
        type: EmployeeFieldPermissions.employee_position_reporting_to,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_reporting_to",
      },
      {
        title: t("Division"),
        type: EmployeeFieldPermissions.employee_position_division,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_division",
      },
      {
        title: t("Department"),
        type: EmployeeFieldPermissions.employee_position_department,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_department",
      },
      {
        title: t("Location"),
        type: EmployeeFieldPermissions.employee_position_location,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_location",
      },
      {
        title: t("Legal entity"),
        type: EmployeeFieldPermissions.employee_position_legal_entity,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_legal_entity",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const permissionTypesTexts: Record<PermissionType, string> = {
    [PermissionType.none]: t("permission_type_none"),
    [PermissionType.view]: t("permission_type_view"),
    [PermissionType.edit]: t("permission_type_edit"),
  };

  const getPermissionRecruitmentSection = () => {
    return {
      title: t("Recruit"),
      groups: [
        {
          title: t("Recruit"),
          items: [
            {
              title: t("Recruit module"),
              description: t(
                "Grant access to recruit section of platform. Employees without this permission will see only jobs and candidates assigned to them"
              ),
              field: "api_permissions.recruiting_module",
            },
          ],
        },
        {
          title: t("Jobs"),
          items: [
            {
              title: t("Show all jobs"),
              description: t("If disabled - only assigned jobs will be visible"),
              field: "api_permissions.show_all_jobs",
            },
            {
              title: t("Manage jobs"),
              description: t("Create and edit any visible job"),
              field: "api_permissions.manage_jobs",
            },
            {
              title: t("Show job salary"),
              description: t("If selected job salary will be shown for all visible jobs"),
              field: "api_permissions.show_jobs_salaries",
            },
          ],
        },
        {
          title: t("Candidates"),
          items: [
            {
              title: t("Show all candidates"),
              description: t("Access to all candidates and their profiles"),
              field: "api_permissions.show_all_candidates",
            },
            {
              title: t("Manage candidates"),
              description: t("Assign candidates to jobs, change stages etc."),
              field: "api_permissions.manage_candidates",
            },
            {
              title: t("Delete candidates"),
              description: t("Allows to delete candidates from database"),
              field: "api_permissions.delete_candidates",
            },
            {
              title: t("Show candidate salary"),
              description: t("Viewing salary information of candidates"),
              field: "api_permissions.show_candidates_salaries",
            },
            {
              title: t("Delete all comments"),
              description: t("Allows to delete comments of others"),
              field: "api_permissions.delete_all_comments",
            },
          ],
        },
        {
          title: t("Reporting"),
          items: [
            {
              title: t("Stage flow report"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_stage_flow",
            },
            {
              title: t("Source efficiency report"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_source_efficiency",
            },
            {
              title: t("Closing time report"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_closing_time",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionSettingsSection = () => {
    return {
      title: t("Settings"),
      groups: [
        {
          title: t("General"),
          items: [
            {
              title: t("Organization"),
              description: t("Manage organization settings — name, logo, language etc."),
              field: "api_permissions.settings_organization",
            },
            {
              title: t("Divisions"),
              description: t("View, create, or modify divisions within the organization"),
              field: "api_permissions.settings_divisions",
            },
            {
              title: t("Departments"),
              description: t(
                "Create and manage departments, department heads and organisational structure"
              ),
              field: "api_permissions.settings_departments",
            },
            {
              title: t("Positions & Levels"),
              description: "",
              field: "api_permissions.settings_positions_levels",
            },
            {
              title: t("Locations"),
              description: t("Manage the locations associated with the organization"),
              field: "api_permissions.settings_locations",
            },
            {
              title: t("Legal entities"),
              description: t("Manage legal entities"),
              field: "api_permissions.settings_legal_entities",
            },
          ],
        },
        {
          title: t("Recruit"),
          items: [
            {
              title: t("Pipelines"),
              description: t("Manage recruitment pipelines, including stages"),
              field: "api_permissions.settings_pipelines",
            },
            {
              title: t("Sources"),
              description: t("Manage and edit recruitment sources for job candidates"),
              field: "api_permissions.settings_sources",
            },
            {
              title: t("Tags"),
              description: "",
              field: "api_permissions.manage_candidates",
            },
            {
              title: t("Email templates"),
              description: "",
              field: "api_permissions.settings_email_templates",
            },
            {
              title: t("Candidate disqualification reasons"),
              description: "",
              field: "api_permissions.settings_candidate_disqualification_reasons",
            },
          ],
        },
        {
          title: t("Security"),
          items: [
            {
              title: t("Roles & Permissions"),
              description: "",
              field: "api_permissions.settings_roles_permissions",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionGeneralSection = () => {
    return {
      title: t("General"),
      groups: [
        {
          title: t("Employees"),
          items: [
            {
              title: t("Add new employees"),
              description: "",
              field: "api_permissions.add_employees",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionSections = () => {
    return [
      getPermissionGeneralSection(),
      getPermissionSettingsSection(),
      getPermissionRecruitmentSection(),
    ] as PermissionsSection[];
  };

  const getAssignCardTitle = (type: RoleAssignToConditionType): string => {
    let title = `${type}`;

    RoleAssignToConditionsItems().forEach((item) => {
      if (item.id === type) title = item.title;
    });

    return `${title}`;
  };

  const getSignTitle = (sign: RoleApplySignType): string => {
    if (sign == RoleApplySignType.include) return "";
    let title = `${sign}`;

    RoleSignItems().forEach((item) => {
      if (item.id === sign) title = item.title;
    });

    return title + ": ";
  };

  const getAssignCardDescription = (
    sign: RoleApplySignType,
    entities?: SelectItem[] | undefined
  ): string => {
    return getSignTitle(sign) + entities?.map((entity) => entity.title).join(", ");
  };
  const getApplyCardTitle = (type: RoleAssignToConditionType): string => {
    let title = `${type}`;

    RoleApplyToConditionsItems().forEach((item) => {
      if (item.id === type) title = item.title;
    });

    return `${title}`;
  };

  return {
    getEmployeeFieldPermissionsConfig,
    getEmployeeFieldPermissionsConfigAddress,
    getEmployeeFieldPermissionsConfigJob,
    permissionTypesTexts,
    getPermissionSections,
    getAssignCardTitle,
    getApplyCardTitle,
    getSignTitle,
    getAssignCardDescription,
    getPermissionSettingsSection,
    getPermissionRecruitmentSection,
    getPermissionGeneralSection,
  };
}
