import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { apiDepartments } from "../api/department.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { ApiDepartmentFilter, ApiDepartmentSavePayload } from "../types/department.types.ts";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";

const departmentsKeys = createQueryKeys("departments", {
  all: null,
  search: (filter: ApiDepartmentFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiDepartments.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useDepartments = () => {
  return useQuery({
    ...departmentsKeys.all,
    queryFn: async () => {
      const result = await apiDepartments.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export interface DepartmentSaveParams {
  id: number;
  payload: ApiDepartmentSavePayload;
}

export const useDepartmentSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ id, payload }: DepartmentSaveParams) {
      const result =
        id == 0 ? await apiDepartments.create(payload) : await apiDepartments.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: departmentsKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useDepartmentDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiDepartments.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: departmentsKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useDepartmentsSearch = (filter: ApiDepartmentFilter, enabled = true) => {
  return useQuery({
    ...departmentsKeys.search(filter),
    enabled: enabled,
  });
};

export const useDepartmentsSearchInline = (enabled = true) => {
  const [filter, setFilter] = useState<ApiDepartmentFilter>({
    page_number: 1,
    text: undefined,
  });
  const { data, isLoading } = useDepartmentsSearch(filter, enabled);
  const options = useMemo(() => {
    return data?.items || ([] as SelectItem[]);
  }, [data]);

  function search(text: string | undefined) {
    setFilter({ ...filter, text: text });
  }

  return {
    data,
    isLoading,
    options,
    search,
  };
};
