import { useTranslation } from "react-i18next";
import {
  ApiDivision,
  ApiDivisionFilter,
  ApiDivisionsFilterDefault,
} from "../../../types/division.types.ts";
import { useCallback, useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui/table/Table.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { DivisionEditModal } from "./widgets/DivisionEditModal.tsx";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { Search } from "../../../components/search/Search.tsx";
import { useDebounce, useEffectOnce } from "react-use";
import { useDivisionDelete, useDivisionsSearch } from "../../../queries/use-divisitions.query.ts";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";

export default function Divisions() {
  const { t } = useTranslation();

  const modalsService = useModals();

  const [searchTerm, setSearchTerm] = useState("");
  useDebounce(
    () => {
      setFilter({
        page_number: 1,
        text: searchTerm,
      });
    },
    300,
    [searchTerm]
  );
  const [filter, setFilter] = useState<ApiDivisionFilter>(ApiDivisionsFilterDefault);
  const { data: divisions, isLoading: divisionsIsLoading, isFetching } = useDivisionsSearch(filter);
  const divisionDelete = useDivisionDelete();

  const columnHelper = createColumnHelper<ApiDivision>();
  const columns = useMemo<ColumnDef<ApiDivision>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("Employees"),
        cell: (row) => <div className={"text-right px-2"}>{row.row.original.employees_count}</div>,
        accessorKey: "employees_count",
        size: 100,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [divisions]
  );

  const handleEditOrCreate = useCallback((division: ApiDivision | undefined = undefined) => {
    const divisionEdit = division || {
      id: 0,
      title: "",
      employees_count: 0,
    };
    modalsService.openModal(DivisionEditModal, {
      division: divisionEdit,
    });
  }, []);

  const handleDeleteAction = useCallback((division: ApiDivision) => {
    divisionDelete.mutate(division.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.divisions.deleted, {
          [analyticProperties.id]: division.id,
        });
      },
    });
  }, []);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.divisions.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack title={t("Divisions")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Stack direction={"horizontal"}>
            <Search
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              type={"text"}
              placeholder={t("Search by name")}
              className={"w-96"}
            />
          </Stack>

          <Table
            data={divisions?.items || []}
            columns={columns}
            isFetching={isFetching || divisionDelete.isPending}
            showSkeleton={divisionsIsLoading}
            showNotFoundError={filter.text != "" && divisions?.metadata.total_items == 0}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />

          <PaginationBlock
            metadata={divisions?.metadata}
            onPageChange={(page) => {
              setFilter({ ...filter, page_number: page });
            }}
          />
        </Page.Content>
      </Page>
    </>
  );
}
