import ReactDOM from "react-dom/client";
import App from "./App.tsx";

import "./index.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react"; // import i18n (needs to be bundled ;))
import "./i18n";
import { AppProvider } from "./themes/provider.tsx";
import "allotment/dist/style.css";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ApiPassException } from "./types/api.types.ts";

Sentry.init({
  dsn: "https://5e7e4dd9045e6615e46c6f9e4c5ce865@o4506875365556224.ingest.us.sentry.io/4506875369029632",
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  release: import.meta.env.VITE_APP_VERSION,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.5,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.skailer\.com\/api/],
});

//const { showError } = useToasts();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof ApiPassException) {
        return;
      }
      // todo error handling
      // console.log("error in Query Cache", error);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      // useToasts().showError("Error in request");
    },
  }),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppProvider>
  </BrowserRouter>
);
