import { ApiFile } from "../../types/common.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import ButtonLink from "../../ui/button/ButtonLink.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import Text from "../../ui/typography/Text.tsx";
import useFile from "../../hooks/use-file.hook.ts";
import { useCallback, useState } from "react";
import ButtonIcon from "../../ui/button/ButtonIcon.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";

export interface FileItemProps {
  file: ApiFile;
  onRemove?: () => void;
}

export const FileItem = ({ file, onRemove }: FileItemProps) => {
  const { openFileByUUID } = useFile();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const { renderFileSize } = useFormatter();

  const handleOpenFile = useCallback(async () => {
    setIsFileLoading(true);
    await openFileByUUID(file.uuid);
    setIsFileLoading(false);
  }, [file.uuid, openFileByUUID]);
  return (
    <Stack
      direction={"horizontal"}
      gap={"sm"}
      justify={"between"}
      items={"center"}
      className={"bg-dark/5 rounded-md px-2 py-1 overflow-hidden grow-0"}
    >
      <ButtonLink
        isLoading={isFileLoading}
        variant={"secondary"}
        className={"overflow-hidden overflow-ellipsis whitespace-nowrap text-brand-dark"}
        onClick={handleOpenFile}
      >
        {file.name}
      </ButtonLink>
      <Stack direction={"horizontal"} items={"center"} gap={"sm"} className={"shrink-0"}>
        <Text className={"shrink-0 text-secondary"}>{renderFileSize(file.size)}</Text>
        {onRemove && (
          <ButtonIcon
            onClick={onRemove}
            className={"shrink-0"}
            variant={"plain"}
            size={"sm"}
            icon={<FontAwesomeIcon icon={"fa-light fa-times"} />}
          />
        )}
      </Stack>
    </Stack>
  );
};
