import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { ApiEmployee } from "../../../types/employee.types.ts";
import { ColumnDef } from "@tanstack/react-table";
import Stack from "../../../ui/stack/Stack.tsx";
import Table from "../../../ui/table/Table.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { EmployeeInlineCard } from "../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { EmployeeFieldPermissions, PermissionType } from "../../../types/role.types.ts";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { EmployeeEditModal } from "../widgets/EmployeeEditModal.tsx";
import useEmployeeSearchInline from "../../../queries/use-employees.query.ts";
import { useDebounce, useEffectOnce } from "react-use";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";
import { Search } from "../../../components/search/Search.tsx";
import Box from "../../../ui/box/Box.tsx";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import { SelectOption } from "../../../ui/select/SelectOption.tsx";
import { useNavigate } from "react-router-dom";

export default function Employees() {
  const { t } = useTranslation();

  const { employee } = useEmployeeStore();
  const modalsService = useModals();

  const [searchTerm, setSearchTerm] = useState("");

  useDebounce(
    () => {
      employees.setFilter({
        ...employees.filter,
        text: searchTerm,
        page_number: 1,
      });
    },
    300,
    [searchTerm]
  );

  const employees = useEmployeeSearchInline();
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<ApiEmployee>[]>(
    () => [
      {
        header: t("Full name"),
        cell: (row) => <EmployeeInlineCard employee={row.row.original} primary />,
        accessorKey: "title",
      },
      {
        header: t("Position"),
        cell: (row) => row.row.original.employee_position.position?.title,
        accessorKey: "employee_position",
        // size: 150,
      },
      {
        header: t("Department"),
        cell: (row) => row.row.original.employee_position.department?.title,
        accessorKey: "department",
        // size: 150,
      },
      {
        header: t("Manager"),
        cell: (row) =>
          row.row.original.employee_position.manager && (
            <EmployeeInlineCard employee={row.row.original.employee_position.manager} />
          ),
        accessorKey: "manager",
        // size: 150,
      },
    ],
    [employees.data]
  );
  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.viewed);
  });

  const canAddEmployee: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.add_employees == PermissionType.edit;
  }, [employee]);

  const handleNewEmployee = useCallback(() => {
    modalsService.openModal(EmployeeEditModal, {
      employee: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        date_hired_on: new Date(),
        employee_position: {
          id: 0,
          date_effective_from: new Date(),
        },
        permissions: {
          [EmployeeFieldPermissions.employee_name]: PermissionType.view,
          [EmployeeFieldPermissions.employee_hired_on]: PermissionType.view,
          [EmployeeFieldPermissions.employee_email]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_email_personal]: PermissionType.none,
          [EmployeeFieldPermissions.employee_phone]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_phone_personal]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_gender]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_date_birth]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_avatar]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_background]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_documents]: PermissionType.edit,

          [EmployeeFieldPermissions.employee_number]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_address_line_1]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_address_line_2]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_country]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_state]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_city]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_zip_code]: PermissionType.edit,

          // [EmployeeFieldPermissions.employee_position]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_job_title]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_date]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_reporting_to]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_division]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_department]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_location]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_legal_entity]: PermissionType.edit,
        },
      },
    });
  }, [employee]);

  return (
    <>
      <Page>
        <Page.Header title={t("Employees")}>
          <Stack direction={"horizontal"} gap={"sm"} items={"center"}>
            <DropdownMenu
              trigger={
                <Button
                  variant={"secondary"}
                  rightIcon={<FontAwesomeIcon icon={"fa-light fa-chevron-down shrink-0"} />}
                >
                  {t("List")}
                </Button>
              }
            >
              <SelectOption
                item={{
                  id: "1",
                  title: t("List"),
                  selected: true,
                  item: {},
                }}
                isMulti={false}
                handleSelect={() => {
                  //onChange(option.id.toString());
                }}
              />
              <SelectOption
                item={{
                  id: "2",
                  title: t("Organizational chart"),
                  selected: false,
                  item: {},
                }}
                isMulti={false}
                handleSelect={() => {
                  navigate("/employees/chart");
                }}
              />
            </DropdownMenu>
            {canAddEmployee && (
              <Button
                onClick={handleNewEmployee}
                leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
              >
                {t("Create new")}
              </Button>
            )}
          </Stack>
        </Page.Header>
        <Page.Content>
          <Box>
            <Search
              placeholder={t("Name")}
              value={searchTerm}
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              type={"text"}
            />
          </Box>
          <Table
            data={employees.data?.items || []}
            columns={columns}
            showSkeleton={employees.isLoading}
          />
          <PaginationBlock
            metadata={employees.data?.metadata}
            onPageChange={(page) => {
              employees.setFilter({ ...employees.filter, page_number: page });
            }}
          />
        </Page.Content>
      </Page>
    </>
  );
}
