import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Modal from "../../ui/modal/modal";
import Button from "../../ui/button/Button";
import { Input } from "../../ui/input/Input.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import useModals from "../../ui/modal/modal.store.ts";
import Select from "../../ui/select/Select.tsx";
import Box from "../../ui/box/Box.tsx";
import { ApiEntityTypes, ApiFile } from "../../types/common.types.ts";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import i18n from "../../i18n.ts";
import { RichEditor } from "../../ui/rich-editor/RichEditor.tsx";
import { Switch } from "../../ui/switch/Switch.tsx";
import { useIntegrations } from "../../queries/use-emails.query.ts";
import { FileUpload } from "../file-upload/FileUpload.tsx";
import { FileItem } from "../file-item/FileItem.tsx";
import { apiFiles } from "../../api/file.api.ts";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { SelectItem } from "../../ui/select/select.types.ts";
import { ApiEmailSendPayload } from "../../types/email.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { useCandidateActionCreate } from "../../queries/recruit/use-candidate-actions.query.ts";
import { ApiActionPayload, ApiActionVisibilities } from "../../types/action.types.ts";

export interface EmailSendModalProps extends ModalProps {
  applicantEmail: string;
  candidateId: number;
  subject?: string;
  threadId?: string;
}

export function EmailSendModal({
  applicantEmail,
  candidateId,
  subject,
  threadId,
  ...props
}: EmailSendModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { handleErrors } = useErrorHandle();
  const { data: emailIntegrations, isLoading: isEmailIntegrationsLoading } = useIntegrations();

  const [files, setFiles] = useState<ApiFile[]>([]);
  const [showCopyFields, setShowCopyFields] = useState(false);

  const EmailSendSchemaObject = yup.object({
    from_email: yup.string().required(i18n.t("Field is required")),
    to_email: yup.string().email("Invalid email").required(i18n.t("Field is required")),
    subject: yup.string().required(i18n.t("Field is required")),
    body: yup.string().required(i18n.t("Field is required")),
    candidate_id: yup.number().required().required(),
    cc_email: yup.string().nullable(),
    bcc_email: yup.string().nullable(),
    thread_id: yup.string().nullable(),
    file_ids: yup.array().of(yup.number()),
  });

  interface EmailSendSchema extends yup.InferType<typeof EmailSendSchemaObject> {}

  const {
    setValue,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailSendSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EmailSendSchemaObject),
    defaultValues: {
      to_email: applicantEmail || "",
      candidate_id: candidateId,
      subject: subject || "",
      thread_id: threadId,
    },
  });

  useEffect(() => {
    if (emailIntegrations && emailIntegrations.length > 0) {
      setValue("from_email", emailIntegrations[0].email);
    }
  }, [emailIntegrations]);

  const emailIntegrationOptions = useMemo(() => {
    return (
      emailIntegrations?.map((integration) => ({
        id: integration.email,
        title: integration.email,
      })) || ([] as SelectItem[])
    );
  }, [emailIntegrations]);

  const candidateActionCreate = useCandidateActionCreate(control);

  const onSubmit = handleSubmit((schema) => {
    const email: ApiEmailSendPayload = {
      candidate_id: schema.candidate_id,
      from_email: schema.from_email,
      to_email: schema.to_email,
      subject: schema.subject,
      body: schema.body,
      cc_email: schema.cc_email || "",
      bcc_email: schema.bcc_email || "",
      file_ids: files.map((f) => f.id),
      thread_id: schema.thread_id || "",
    };

    const payload: ApiActionPayload = {
      email: email,
      visibility: ApiActionVisibilities.public,
    };

    candidateActionCreate.mutate(
      { id: candidateId || schema.candidate_id, payload: payload },
      {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.email.sent, {
            [analyticProperties.source]: "Gmail",
            [analyticProperties.actionType]: "Candidate",
            [analyticProperties.title]: threadId ? "Reply" : "Initial Email",
          });

          close(id);
        },
      }
    );
  });

  async function removeFile(file: ApiFile) {
    setFiles(files.filter((f) => f.id !== file.id));

    const { error } = await apiFiles.delete(file.uuid);
    if (error) handleErrors(error);
  }

  return (
    <Modal
      {...props}
      layout="stretch"
      size={"lg"}
      title={t("Send email")}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            onClick={onSubmit}
            isLoading={candidateActionCreate.isPending}
            size={"lg"}
            className={"w-full"}
            type={"submit"}
          >
            {t("Send")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit} className={"flex flex-col flex-grow"}>
        <Stack direction={"horizontal"} gap={"sm"} items={"center"} justify={"between"}>
          <Input
            placeholder={t("Enter recipient email")}
            label={t("To")}
            type="email"
            className="w-full"
            {...register("to_email")}
            error={errors.to_email?.message}
          />
          <Stack direction={"horizontal"} gap={"md"} items={"center"}>
            <Switch checked={showCopyFields} onChange={setShowCopyFields} title={t("Copy")} />
          </Stack>
        </Stack>

        <Input
          label={t("Subject")}
          type="text"
          {...register("subject")}
          error={errors.subject?.message}
        />

        {showCopyFields && (
          <div className={"grid lg:grid-cols-2 gap-md"}>
            <Input
              label={t("Copy")}
              type="email"
              {...register("cc_email", {
                value: undefined,
              })}
              error={errors.cc_email?.message}
            />

            <Input
              label={t("Hidden Copy")}
              type="email"
              {...register("bcc_email", {
                value: undefined,
              })}
              error={errors.bcc_email?.message}
            />
          </div>
        )}

        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              label={t("From")}
              isLoading={isEmailIntegrationsLoading}
              options={emailIntegrationOptions}
              emptyTitle={t("Select")}
              value={value}
              onChange={onChange}
              required={true}
              error={errors.from_email?.message}
            />
          )}
          control={control}
          name={"from_email"}
        />

        <Stack className={"py-md"} gap={"md"}>
          {files.length > 0 && (
            <Stack gap="xs" items={"start"}>
              {files.map((f) => (
                <FileItem
                  file={f}
                  onRemove={() => {
                    removeFile(f);
                  }}
                  key={`file${f.id}`}
                />
              ))}
            </Stack>
          )}
          <Box className={"flex"}>
            <Box className={"inline-block"}>
              <FileUpload
                entityId={candidateId}
                entityType={ApiEntityTypes.candidate}
                afterSend={(file) => {
                  file && setFiles([...files, file]);
                }}
              />
            </Box>
          </Box>
        </Stack>
        <Controller
          render={({ field: { value, onChange } }) => (
            <RichEditor
              value={value || ""}
              onChange={onChange}
              placeholder={t("Enter email text...")}
            />
          )}
          control={control}
          name={"body"}
        />
      </form>
    </Modal>
  );
}
