import { SelectItem } from "../../../../../ui/select/select.types.ts";
import i18n from "../../../../../i18n.ts";

export const PipelineStageTimeLimits: SelectItem[] = Array.from({ length: 31 }, (_, index) => {
  return index === 0
    ? { id: index.toString(), title: i18n.t("No time limit") }
    : { id: index.toString(), title: i18n.t("plural_day", { count: index }) };
});

export interface ApiStageInPipelineEdit {
  id: number;
  title: string;
  sla_limit: string;
  selected: boolean;
}
