import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function useSidebar() {
  const whiteListURLs: string[] = ["/recruitment", "/settings"];
  const location = useLocation();

  const sidebarIsShowing: boolean = useMemo(() => {
    return whiteListURLs.some((url) => new RegExp(url, "gi").test(location.pathname));
  }, [location]);

  return { sidebarIsShowing };
}
