import { twMerge } from "tailwind-merge";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { SelectOptionProps } from "./select.types.ts";
import { useTheme } from "../themes/provider.tsx";

export function SelectOption<T>({ item, handleSelect, isMulti, ...props }: SelectOptionProps<T>) {
  const theme = useTheme("select");

  return (
    <div
      className={twMerge(
        theme.dropdown.item.base,
        item.selected ? theme.dropdown.item.active.on : theme.dropdown.item.active.off
      )}
      {...props}
      onClick={() => {
        handleSelect(item);
      }}
    >
      <div className={"flex items-center gap-2"}>
        {isMulti && (
          <div
            className={twMerge(
              "relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-dark/10 shadow-md",
              item.selected ? "bg-dark text-light" : undefined
            )}
          >
            {item.selected && (
              <div
                className={
                  "absolute w-[11px] h-[7px] text-center align-middle text-light shadow-sm left-[2px] top-[3px]"
                }
              >
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={"w-full h-full align-middle text-light"}
                >
                  <path
                    d="M3.87424 6.53892L3.87457 6.53924C4.15279 6.8132 4.59793 6.81173 4.87436 6.53595L4.87439 6.53592L9.93895 1.48069L9.93949 1.48015C10.2172 1.20017 10.2172 0.746738 9.93949 0.466756C9.66125 0.18619 9.20968 0.186081 8.93131 0.466429C8.9312 0.466538 8.93109 0.466647 8.93098 0.466756L4.37472 5.00648L2.06607 2.73476C1.78767 2.45477 1.33637 2.455 1.05825 2.73545C0.780585 3.01543 0.780585 3.46886 1.05825 3.74884L1.05824 3.74884L1.05918 3.74977L3.87424 6.53892Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                  />
                </svg>
              </div>
            )}
          </div>
        )}
        {item.title}
      </div>
      {!isMulti && item.selected && (
        <FontAwesomeIcon
          icon={"fa-light fa-check text-lg"}
          className={twMerge(
            theme.dropdown.icon.base,
            item.selected ? theme.dropdown.icon.active.on : theme.dropdown.icon.active.off
          )}
        />
      )}
    </div>
  );
}
