import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import {
  EmployeeFieldPermissions,
  EmployeeFieldPermissionsKeys,
  PermissionType,
} from "../../../../types/role.types.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { EmployeeAvatar } from "../../../../components/employee-avatar/EmployeeAvatar.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ImageUploadModal } from "../../../../components/avatar-upload/ImageUploadModal.tsx";
import { ApiFilePathTypes } from "../../../../types/file.types.ts";
import { ApiFile } from "../../../../types/common.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import {
  useEmployeeDeleteAvatar,
  useEmployeeSetAvatar,
} from "../../../../queries/use-employees.query.ts";
import { useTranslation } from "react-i18next";
import { Title } from "../../../../components/title/Title.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";

import { useCallback } from "react";
import { EmployeePersonalCard } from "./EmployeePersonalCard.tsx";

export function EmployeePersonalInfo({ employee }: EmployeePageComponentProps) {
  const modalsService = useModals();
  const { t } = useTranslation();
  const { renderEmployeeName } = useFormatter();

  const employeeSetAvatar = useEmployeeSetAvatar();
  const employeeDeleteAvatar = useEmployeeDeleteAvatar();

  const canEdit = useCallback(
    (field: EmployeeFieldPermissionsKeys): boolean => {
      if (!employee) return false;

      return employee.permissions[field] == PermissionType.edit;
    },
    [employee]
  );

  const handleAvatarUpload = useCallback(() => {
    if (!employee) return;

    modalsService.openModal(ImageUploadModal, {
      modalTitle: t("Avatar"),
      path: ApiFilePathTypes.employee,
      onSave: async (file: ApiFile) => {
        try {
          await employeeSetAvatar.mutateAsync({
            id: employee.id,
            fileId: file.id,
          });

          analyticsService.trackEvent(analyticEvents.employees.edited, {
            [analyticProperties.id]: employee.id,
            [analyticProperties.actionType]: "Photo Updated",
          });

          return true;
        } catch {
          return false;
        }
      },
    });
  }, [employee]);

  const handleAvatarDelete = async () => {
    if (!employee) return;

    try {
      await employeeDeleteAvatar.mutateAsync(employee.id);

      analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
        [analyticProperties.actionType]: "Photo Deleted",
      });
    } catch {
      return;
    }
  };

  return (
    <Stack direction={"vertical"} gap={"lg"}>
      <Stack direction={"vertical"} items={"center"} gap={"md"} className={"text-center"}>
        <Box className={"relative w-56 h-56 group cursor-pointer bg-light rounded-2xl"}>
          <EmployeeAvatar size={"4xl"} employee={employee} />
          {canEdit(EmployeeFieldPermissions.employee_avatar) && (
            <DropdownMenu
              trigger={
                employee && (
                  <Box
                    className={
                      "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-2xl flex items-center justify-center"
                    }
                  >
                    <FontAwesomeIcon
                      icon={"fa-light fa-camera"}
                      className={"text-[40px] text-light "}
                    />
                  </Box>
                )
              }
            >
              <Stack gap={"sm"}>
                <Button
                  variant={"menu"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-image"} />}
                  onClick={handleAvatarUpload}
                >
                  {t("Upload new")}
                </Button>
                <Button
                  variant={"menu"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                  onClick={handleAvatarDelete}
                  className={"text-danger"}
                >
                  {t("Delete")}
                </Button>
              </Stack>
            </DropdownMenu>
          )}
        </Box>

        <Title
          header={renderEmployeeName(employee)}
          size={"lg"}
          caption={employee.employee_position.position && employee.employee_position.position.title}
        />
      </Stack>

      <EmployeePersonalCard employee={employee} />
    </Stack>
  );
}
