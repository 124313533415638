import { useTranslation } from "react-i18next";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import Text from "../../../../ui/typography/Text.tsx";
import Box from "../../../../ui/box/Box.tsx";
import {
  ApiApplicantContacts,
  ApiApplicantShort,
} from "../../../../types/recruit/applicant.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import ContactButton from "../../../../components/contact-button/ContactButton.tsx";
import { ContactTypes } from "../../../../types/common.types.ts";

export interface ApplicantProfileContactsProps {
  applicant: ApiApplicantShort;
  contacts: ApiApplicantContacts;
}

export const ApplicantProfileContacts = ({
  applicant,
  contacts,
}: ApplicantProfileContactsProps) => {
  const { t } = useTranslation();
  const { calculateAge, renderPartialDate } = useFormatter();
  const age = calculateAge(applicant.birth_date);
  const ageTitle = renderPartialDate(applicant.birth_date);

  function getTelegramUrl(username: string) {
    const cleanUsername = username.replace(/^@/, "").replace(/^https?:\/\//, "");
    return cleanUsername.includes("t.me/")
      ? `https://${cleanUsername}`
      : `https://t.me/${cleanUsername}`;
  }

  // const hasAnyContact = useMemo(() => {
  //   return (
  //     applicant.salary ||
  //     contacts.phone ||
  //     contacts.email ||
  //     contacts.skype_username ||
  //     contacts.telegram_username ||
  //     ageTitle ||
  //     applicant.location ||
  //     applicant.resume
  //   );
  // }, [applicant, contacts]);

  return (
    <>
      {contacts.phone && (
        <ListItem
          title={t("Phone")}
          caption={contacts.phone}
          to={`tel:+${contacts.phone.replace(/\D/g, "")}`}
          valueSlot={
            <Stack direction={"horizontal"} gap={"xs"}>
              <ContactButton type={ContactTypes.telegram} contact={contacts.phone} />
              <ContactButton type={ContactTypes.whatsapp} contact={contacts.phone} />
              <ContactButton type={ContactTypes.viber} contact={contacts.phone} />
            </Stack>
          }
        />
      )}
      {contacts.email && (
        <ListItem title={t("Email")} caption={contacts.email} to={`mailto:${contacts.email}`} />
      )}
      {contacts.skype_username && (
        <ListItem
          title={t("Skype")}
          caption={contacts.skype_username}
          to={`skype:${contacts.skype_username}?chat`}
        />
      )}
      {contacts.telegram_username && (
        <ListItem
          title={t("Telegram")}
          caption={contacts.telegram_username}
          to={getTelegramUrl(contacts.telegram_username)}
        />
      )}
      {ageTitle && (
        <ListItem
          title={t("Birth date")}
          caption={renderPartialDate(applicant.birth_date)}
          value={age > 0 ? t("plural_year", { count: age }) : undefined}
        />
      )}
      {applicant.location && <ListItem title={t("Location")} caption={applicant.location} />}
      {applicant.resume && (
        <Box className={"pt-sm"}>
          <Text className={"text-secondary"}>{applicant.resume}</Text>
        </Box>
      )}
      {applicant.salary && (
        <>
          <Title header={t("Compensation")} paddingTop paddingBottom />
          <ListItem title={t("Salary")} caption={applicant.salary} />
        </>
      )}
    </>
  );
};
