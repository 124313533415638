import { Title } from "../../../../components/title/Title.tsx";
import { useTranslation } from "react-i18next";
import { useApplicantsSearch } from "../../../../queries/recruit/use-applicants.query.ts";
import { useEffect, useMemo, useState } from "react";
import {
  ApiApplicantFilter,
  ApiApplicantShort,
} from "../../../../types/recruit/applicant.types.ts";
import { useStages } from "../../../../queries/recruit/use-stages.query.ts";
import { PipelineStageTypes } from "../../../../types/recruit/stages.types.ts";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../../../../ui/table/Table.tsx";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import LinkTo from "../../../../ui/link/LinkTo.tsx";

export const RecruitingClosingTimeReportHiredTable = () => {
  const { t } = useTranslation();

  const { data: pipelineStages } = useStages();

  const hiredPipelineStage = useMemo(() => {
    return pipelineStages?.find((stage) => stage.type == PipelineStageTypes.hired);
  }, [pipelineStages]);

  const [filter, setFilter] = useState<ApiApplicantFilter>({
    page_number: 1,
  });
  useEffect(() => {
    if (hiredPipelineStage) {
      setFilter({
        ...filter,
        pipeline_stage_ids: [hiredPipelineStage.id],
      });
    }
  }, [hiredPipelineStage]);

  const { data: applicants, isLoading } = useApplicantsSearch(filter, !!hiredPipelineStage);

  const columns = useMemo<ColumnDef<ApiApplicantShort>[]>(() => {
    return [
      {
        header: t("Job"),
        cell: (row) => (
          <>
            {row.row.original.last_candidate && (
              <Stack direction={"horizontal"} gap={"md"} items={"center"}>
                <Avatar
                  size={"md"}
                  url={row.row.original.photo_url}
                  acronym={`${row.row.original.first_name} ${row.row.original.last_name}`}
                />
                <Stack>
                  <LinkTo
                    to={`/recruitment/candidates/${row.row.original.id}?job_id=${row.row.original.last_candidate.job.id}`}
                    variant={"secondary"}
                    target={"_blank"}
                    className={"text-title overflow-hidden overflow-ellipsis whitespace-nowrap"}
                  >
                    {`${row.row.original.first_name} ${row.row.original.last_name}`}
                  </LinkTo>
                  <Text
                    className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}
                  >
                    {row.row.original.last_candidate.job.title}
                  </Text>
                </Stack>
              </Stack>
            )}
          </>
        ),
        accessorKey: "title",
      },
    ];
  }, [applicants]);
  return (
    <>
      <Title header={t("Hired candidates")} paddingTop paddingBottom />
      <Table data={applicants?.items || []} columns={columns} showSkeleton={isLoading} />
      <PaginationBlock
        metadata={applicants?.metadata}
        onPageChange={(page) => {
          setFilter({ ...filter, page_number: page });
        }}
      />
    </>
  );
};
