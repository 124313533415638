import { ModalLayoutProps } from "../modal.types.ts";
import { useTheme } from "../../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { ModalHeader } from "../ModalHeader.tsx";
import { BoxWithScroll } from "../../box/BoxWithScroll.tsx";
import Stack from "../../stack/Stack.tsx";
import Box from "../../box/Box.tsx";
import Button from "../../button/Button.tsx";
import { useTranslation } from "react-i18next";
import useModals from "../modal.store.ts";

export function ModalLayoutLightbox({
  children,
  id,
  title,
  style,
  refFromModal,
  floatingProps,
  // className,
  withCloser,
  layout = "lightbox",
  variant,
  size = "sm",
  actions,
  onBack,
  onModalContentScroll,
}: ModalLayoutProps) {
  const theme = useTheme("modal");
  const { t } = useTranslation();
  const { close } = useModals();

  return (
    <Stack
      direction={"vertical"}
      gap={"md"}
      className={twMerge(theme.variants.size[size], "px-0 py-0 h-full content-between")}
    >
      <Box className={"absolute left-0 top-0 right-0 h-[500px]"}>
        <img
          src={"/backgrounds/clouds.jpeg"}
          alt={"background"}
          className={"w-full h-full object-cover"}
        />
      </Box>
      <div></div>
      {withCloser && variant != "side" && (
        <Button
          onClick={() => {
            close(id);
          }}
          variant={"secondary"}
          className={"absolute top-4 right-4"}
        >
          {t("Close")}
        </Button>
      )}
      <div
        ref={refFromModal}
        style={style}
        {...floatingProps}
        className={twMerge(theme.base, theme.variants.layout[layout].content)}
      >
        {title && (
          <ModalHeader
            id={id}
            title={title}
            withCloser={withCloser && variant == "side"}
            onBack={onBack}
          />
        )}
        <BoxWithScroll
          className={"overflow-y-auto flex-grow h-full"}
          onBoxScroll={onModalContentScroll}
        >
          <Box className={theme.container[size]}>{children}</Box>
        </BoxWithScroll>
      </div>
      <div></div>
      <Box className={theme.variants.layout[layout].actions}>{actions}</Box>
    </Stack>
  );
}
