import { create } from "zustand";
import { persist } from "zustand/middleware";

import { ApiMeEmployee } from "../types/employee.types.ts";
import i18n from "../i18n.ts";
import analyticsService from "../services/analytics-service.ts";

export interface EmployeeStore {
  employee: ApiMeEmployee | null;
  setCurrentEmployee: (employee: ApiMeEmployee) => void;
}

export const useEmployeeStore = create<EmployeeStore>((set) => ({
  employee: null,
  setCurrentEmployee: (employee: ApiMeEmployee) => {
    set(() => ({ employee: employee }));
    i18n.changeLanguage(employee.language);
    analyticsService.setUser(employee);
  },
}));

export interface AuthStore {
  token: string | null;
  setToken: (newToken: string | null) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      token: null,
      setToken: (newToken: string | null) =>
        set(() => ({
          token: newToken,
        })),
    }),
    {
      name: "auth",
    }
  )
);

export interface EmployeeAppConfigStore {
  recruitRightPaneSize: number;
  recruitLeftPaneSize: number;
  sidebarIsCollapsed: boolean;
  setRecruitRightPaneSize: (size: number) => void;
  setRecruitLeftPaneSize: (size: number) => void;
  setSidebarIsCollapsed: (isCollapsed: boolean) => void;
}

export const useEmployeeAppConfigStore = create<EmployeeAppConfigStore>()(
  persist(
    (set) => ({
      recruitRightPaneSize: 250,
      recruitLeftPaneSize: 250,
      sidebarIsCollapsed: false,
      setRecruitRightPaneSize: (size: number) => {
        set(() => ({ recruitRightPaneSize: size }));
      },
      setRecruitLeftPaneSize: (size: number) => {
        set(() => ({ recruitLeftPaneSize: size }));
      },
      setSidebarIsCollapsed: (isCollapsed: boolean) => {
        set(() => ({ sidebarIsCollapsed: isCollapsed }));
      },
    }),
    {
      name: "employeeAppConfig",
    }
  )
);
