import useModals from "../../../../../ui/modal/modal.store";
import Stack from "../../../../../ui/stack/Stack";
import Button from "../../../../../ui/button/Button";
import Modal from "../../../../../ui/modal/modal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiLabel, ApiLabelSavePayload } from "../../../../../types/recruit/label.types";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types";
import * as yup from "yup";
import i18n from "../../../../../i18n";
import ColorBlock from "../../../../../ui/colorblock/ColorBlock.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import { useLabelSave } from "../../../../../queries/use-labels.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import Text from "../../../../../ui/typography/Text.tsx";

export interface LabelEditModalProps extends ModalProps {
  label: ApiLabel;
}

const limitedColors = ["#1B1D21", "#32309C", "#7124A1", "#AB2C78", "#2F9AB2", "#AFB21D", "#B22F1D"];

export function LabelEditModal({ label, ...props }: LabelEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const LabelSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
    color: yup.string().required(i18n.t("Tag color is required")),
  });

  interface LabelSchema extends yup.InferType<typeof LabelSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LabelSchema>({
    mode: "onSubmit",
    resolver: yupResolver(LabelSchemaObject),
    defaultValues: {
      title: label.title,
      color: label.color,
    },
  });

  const labelSave = useLabelSave(control);

  const onSubmit = handleSubmit((labelData) => {
    const payload: ApiLabelSavePayload = {
      title: labelData.title,
      color: labelData.color,
    };

    labelSave.mutate(
      { id: label.id, payload },
      {
        onSuccess: (data) => {
          if (label.id == 0) {
            analyticsService.trackEvent(analyticEvents.labels.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.labels.edited, {
              [analyticProperties.id]: label.id,
            });
          }

          close(id);
        },
      }
    );
  });

  return (
    <Modal
      {...props}
      title={t("Tag")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} isLoading={labelSave.isPending} onClick={onSubmit}>
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Stack>
          <Input
            label={t("Title")}
            required={true}
            autoFocus={true}
            type="text"
            {...register("title")}
            error={errors.title?.message}
          />

          <Title header={t("Choose color")} size={"xs"} paddingTop paddingBottom />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Stack direction={"horizontal"} gap={"sm"}>
                {limitedColors.map((colorCode) => (
                  <ColorBlock
                    key={colorCode}
                    color={colorCode}
                    size="md"
                    selected={value === colorCode}
                    onClick={() => onChange(colorCode)}
                  />
                ))}
              </Stack>
            )}
            control={control}
            name={"color"}
          />
          {errors.color?.message && (
            <Box className={"pt-2 pb-4"}>
              <Text className={"text-danger"}>{errors.color?.message}</Text>
            </Box>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
