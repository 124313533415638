import { Permissions, PermissionType } from "../../../types/role.types.ts";
import i18n from "../../../i18n.ts";
import { ApiMeEmployee } from "../../../types/employee.types.ts";

export interface SettingGroup {
  title: string;
  // show: boolean;
  items: SettingItem[];
}

interface SettingItem {
  title: string;
  description: string;
  to: string;
  icon: string;
  permission: Permissions;
  menuTitle: string;
}

export const SettingsConfig = () => {
  return [
    {
      title: i18n.t("General"),
      items: [
        {
          title: i18n.t("Organization"),
          menuTitle: i18n.t("Organization"),
          description: i18n.t(
            "Set default language, timezone, display name, and date format for all company users."
          ),
          to: "/settings/organization",
          permission: Permissions.settings_organization,
          icon: "fa-gear",
        },
        {
          title: i18n.t("Divisions"),
          menuTitle: i18n.t("Divisions"),
          description: i18n.t(
            "Organize your company into distinct divisions for improved oversight and resource management"
          ),
          to: "/settings/divisions",
          permission: Permissions.settings_divisions,
          icon: "fa-folder-tree",
        },
        {
          title: i18n.t("Departments"),
          menuTitle: i18n.t("Departments"),
          description: i18n.t(
            "Organize and manage the various departments within your organization"
          ),
          to: "/settings/departments",
          permission: Permissions.settings_departments,
          icon: "fa-list-tree",
        },
        {
          title: i18n.t("Positions"),
          menuTitle: i18n.t("Positions"),
          description: i18n.t(
            "Specify job positions to clarify roles and responsibilities within the company"
          ),
          to: "/settings/positions",
          permission: Permissions.settings_positions_levels,
          icon: "fa-circle-user",
        },
        {
          title: i18n.t("Locations"),
          menuTitle: i18n.t("Locations"),
          description: i18n.t(
            "Configure and manage the locations associated with your organization"
          ),
          to: "/settings/locations",
          permission: Permissions.settings_locations,
          icon: "fa-location-dot",
        },
        {
          title: i18n.t("Legal entities"),
          menuTitle: i18n.t("Legal entities"),
          description: i18n.t(
            "Manage legal entities as necessary to ensure accurate records and compliance with regulatory requirements"
          ),
          to: "/settings/legal-entities",
          permission: Permissions.settings_legal_entities,
          icon: "fa-building",
        },
      ],
    },
    {
      title: i18n.t("Recruit"),

      items: [
        {
          title: i18n.t("Pipelines"),
          menuTitle: i18n.t("Pipelines"),
          description: i18n.t(
            "Manage the stages of your recruitment process from initial application to final hire"
          ),
          to: "/settings/pipelines",
          permission: Permissions.settings_pipelines,
          icon: "fa-timeline",
        },
        {
          title: i18n.t("Sources"),
          menuTitle: i18n.t("Sources"),
          description: i18n.t("Manage where and how you collect candidate resumes"),
          to: "/settings/sources",
          permission: Permissions.settings_sources,

          icon: "fa-file-import",
        },
        {
          title: i18n.t("Tags"),
          menuTitle: i18n.t("Tags"),
          description: i18n.t("Manage tags to categorize candidates based on skills and expertise"),
          to: "/settings/labels",
          permission: Permissions.manage_candidates,

          icon: "fa-tags",
        },
        {
          title: i18n.t("Disqualification Reasons"),
          menuTitle: i18n.t("Disqualification"),
          description: i18n.t("Manage the reasons why you can disqualify candidates"),
          to: "/settings/disqualification-reasons",
          permission: Permissions.settings_candidate_disqualification_reasons,

          icon: "fa-user-xmark",
        },
      ],
    },
    {
      title: i18n.t("Security"),

      items: [
        {
          title: i18n.t("Roles & Permissions"),
          menuTitle: i18n.t("Roles & Permissions"),
          description: i18n.t(
            "Control access and responsibilities by assigning roles with specific permissions"
          ),
          to: "/settings/roles-and-permissions",
          permission: Permissions.settings_roles_permissions,

          icon: "fa-shield-check",
        },
      ],
    },
  ] as SettingGroup[];
};

export const getSettingsVisibleToEmployee = (employee: ApiMeEmployee): SettingGroup[] => {
  const result: SettingGroup[] = [];
  const settingsConfig = SettingsConfig();
  for (let groupIndex = 0; groupIndex < settingsConfig.length; groupIndex++) {
    const showItems: SettingItem[] = [];

    for (let itemIndex = 0; itemIndex < settingsConfig[groupIndex].items.length; itemIndex++) {
      const show =
        employee.permissions[settingsConfig[groupIndex].items[itemIndex].permission] ==
        PermissionType.edit;

      if (show) showItems.push(settingsConfig[groupIndex].items[itemIndex]);
    }

    if (showItems.length > 0) {
      result.push({
        title: settingsConfig[groupIndex].title,
        items: showItems,
      });
    }
  }
  return result;
};
