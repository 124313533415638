import { ComponentPropsWithoutRef, ElementType, forwardRef } from "react";
import { PolymorphicRef } from "../../helpers/generic-as-prop.ts";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ButtonComponentType, ButtonProps } from "./button.types.ts";

const ButtonComponentFN: ButtonComponentType = forwardRef(
  <T extends ElementType = "button">(
    {
      children,
      href,
      blank,
      variant = "primary",
      size = "md",
      isLoading = false,
      className,
      leftIcon,
      rightIcon,
      ...props
    }: ButtonProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theirProps = { type: "button", ...props } as ComponentPropsWithoutRef<T>;

    const theme = useTheme("button");

    const renderInner = () => {
      return (
        <>
          {isLoading && <FontAwesomeIcon icon="fa-light fa-spinner fa-spin" />}
          {leftIcon}
          {children}
          {rightIcon}
        </>
      );
    };

    return (
      <>
        {href ? (
          <Link
            to={href}
            ref={ref}
            target={blank ? "_blank" : undefined}
            className={twMerge(
              theme.base,
              theme.variants.variant[variant],
              theme.variants.size[size],
              className
            )}
            disabled={isLoading}
            {...theirProps}
          >
            {renderInner()}
          </Link>
        ) : (
          <button
            ref={ref}
            className={twMerge(
              theme.base,
              theme.variants.variant[variant],
              theme.variants.size[size],
              className
            )}
            disabled={isLoading}
            {...theirProps}
          >
            {renderInner()}
          </button>
        )}
      </>
    );
  }
);

const Button = ButtonComponentFN;
export default Button;
