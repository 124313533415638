import { useMemo } from "react";
import { ApiJobShort, getJobEmoji } from "../../../../types/recruit/job.types.ts";
import { ApiEmployeeShort } from "../../../../types/employee.types.ts";
import {
  ApiAnalyticsGroupBy,
  ApiAnalyticsRecruitingFilter,
  ApiAnalyticsRecruitingStageFlowReport,
  ApiAnalyticsRecruitingStageFlowReportRowItem,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import { Title } from "../../../../components/title/Title.tsx";
import { EmployeeInlineCard } from "../../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import ButtonLink from "../../../../ui/button/ButtonLink.tsx";
import Table from "../../../../ui/table/Table.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { useTranslation } from "react-i18next";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";

export interface RecruitingStageFlowReportTableProps {
  report: ApiAnalyticsRecruitingStageFlowReport | undefined;
  filter: ApiAnalyticsRecruitingFilter;
  isLoading: boolean;
}

interface VMRecruitingStageFlowReportRow {
  title?: string;
  caption?: string;
  isGroup: boolean;
  stages: ApiAnalyticsRecruitingStageFlowReportRowItem[];
  employees: ApiEmployeeShort[];
  jobs: ApiJobShort[];
  period?: string;
}

export const RecruitingStageFlowReportTable = ({
  report,
  filter,
  isLoading,
}: RecruitingStageFlowReportTableProps) => {
  const { renderEmployeeName, formatPeriodInReportingGroups } = useFormatter();
  const { t } = useTranslation();
  const { handlePreviewApplicants } = useRecruitingReports();

  const reportCalculated = useMemo(() => {
    if (!report || report.report.rows.length == 0) return [];

    const jobs: ApiJobShort[] = [];
    for (let i = 0; i < report.report.rows.length; i++) {
      const item = report.report.rows[i];

      const job = jobs.find((x) => x.id === item.job?.id);
      if (!job && item.job) {
        jobs.push(item.job);
      }
    }

    const recruiters: ApiEmployeeShort[] = [];
    for (let i = 0; i < report.report.rows.length; i++) {
      const item = report.report.rows[i];

      const recruiter = recruiters.find((x) => x.id === item.employee?.id);
      if (!recruiter && item.employee) {
        recruiters.push(item.employee);
      }
    }

    const allStagesFlatMap = report.report.rows.flatMap((x) => x.stages);
    const result: VMRecruitingStageFlowReportRow[] = [];

    // Без группировки
    if (filter.group_by == null) {
      for (let i = 0; i < report.report.rows.length; i++) {
        const item = report.report.rows[i];

        result.push({
          // title: renderEmployeeName(item.employee),
          // caption: formatPeriodInReporting(item.date_report_from, item.date_report_to, filter.aggregation_date_mode),
          isGroup: false,
          employees: item.employee ? [item.employee] : [],
          jobs: item.job ? [item.job] : [],
          stages: item.stages,
        });
      }
    } else if (filter.group_by == ApiAnalyticsGroupBy.job) {
      for (let i = 0; i < jobs.length; i++) {
        const job = jobs[i];

        result.push({
          title: job.title,
          caption: ``,
          isGroup: true,
          stages: [],
          employees: [],
          jobs: [],
        });

        const rowsByJob = report.report.rows.filter((x) => x.job?.id === jobs[i].id);
        for (let j = 0; j < rowsByJob.length; j++) {
          const item = rowsByJob[j];
          const [title, caption] = formatPeriodInReportingGroups(
            item.date_report_from,
            item.date_report_to,
            filter.aggregation_date_mode
          );
          result.push({
            // title: `${t("Week")} ${j + 1}`, //renderEmployeeName(item.employee),
            title: title, //renderEmployeeName(item.employee),
            caption: caption,
            isGroup: false,
            stages: item.stages,
            employees: [],
            jobs: [],
          });
        }
      }
    } else {
      for (let i = 0; i < recruiters.length; i++) {
        const recruiter = recruiters[i];

        result.push({
          title: renderEmployeeName(recruiter),
          caption: ``,
          isGroup: true,
          stages: [],
          employees: [],
          jobs: [],
        });

        const rowsByRecruiter = report.report.rows.filter(
          (x) => x.employee?.id === recruiters[i].id
        );
        for (let j = 0; j < rowsByRecruiter.length; j++) {
          const item = rowsByRecruiter[j];
          const [title, caption] = formatPeriodInReportingGroups(
            item.date_report_from,
            item.date_report_to,
            filter.aggregation_date_mode
          );
          result.push({
            title: title, //renderEmployeeName(item.employee),
            caption: caption,
            isGroup: false,
            stages: item.stages,
            employees: [],
            jobs: [],
          });
        }
      }
    }

    result.push({
      title: t("Total"),
      caption: " ",
      stages: report.config.stages.map((x) => ({
        pipeline_stage: x,
        value: allStagesFlatMap
          .filter((y) => y.pipeline_stage.id == x.id)
          .reduce((acc, y) => acc + y.value, 0),
        value2: 0,
        applicants_ids: allStagesFlatMap
          .filter((y) => y.pipeline_stage.id == x.id)
          .flatMap((y) => y.applicants_ids),
      })),
      isGroup: true,
      employees: [],
      jobs: [],
    });

    return result;
  }, [report, filter.group_by]);

  const columnHelper = createColumnHelper<VMRecruitingStageFlowReportRow>();
  const columns = useMemo<ColumnDef<VMRecruitingStageFlowReportRow>[]>(() => {
    const inner: DisplayColumnDef<VMRecruitingStageFlowReportRow, unknown>[] = [];
    inner.push(
      columnHelper.display({
        id: "week",
        header: t("Period"),
        cell: (cell) => (
          <Title
            size={cell.row.original.isGroup ? "md" : "sm"}
            header={cell.row.original.title || ""}
            caption={cell.row.original.caption}
            paddingBottom={cell.row.original.isGroup}
            paddingTop={cell.row.original.isGroup}
          />
        ),
        size: "auto" as unknown as number,
      })
    );
    if (filter.group_by == null) {
      inner.push(
        columnHelper.display({
          id: "employee",
          header: t("Employee"),
          cell: (cell) => (
            <>
              {cell.row.original.employees.length > 0 && (
                <EmployeeInlineCard employee={cell.row.original.employees[0]} />
              )}
            </>
          ),
          size: "auto" as unknown as number,
        })
      );
      inner.push(
        columnHelper.display({
          id: "job",
          header: t("Job"),
          cell: (cell) => (
            <>
              {cell.row.original.jobs.length > 0 && (
                <Stack gap={"sm"} direction={"horizontal"} items={"center"}>
                  <Avatar
                    url={undefined}
                    emoji={getJobEmoji(cell.row.original.jobs[0])}
                    size={"md"}
                  />
                  <Text className={"text-secondary"}>{cell.row.original.jobs[0].title}</Text>
                </Stack>
              )}
            </>
          ),
          size: "auto" as unknown as number,
        })
      );
    }
    if (report?.config && report.config.stages.length > 0) {
      for (let i = 0; i < report.config.stages.length; i++) {
        const stage = report.config.stages[i];
        inner.push(
          columnHelper.display({
            id: `stage-${i}`,
            header: () => <Text className={"capitalize"}>{stage.title}</Text>,
            cell: (cell) => (
              <ButtonLink
                variant={"secondary"}
                onClick={() => {
                  const item = cell.row.original.stages.find(
                    (x) => x.pipeline_stage.id == stage.id
                  );
                  if (item)
                    handlePreviewApplicants(
                      item.applicants_ids,
                      AnalyticReportTypes.recruitingStageFlow
                    );
                }}
              >
                {cell.row.original.stages.find((x) => x.pipeline_stage.id == stage.id)?.value || ""}
              </ButtonLink>
            ),
            enableResizing: false,
            size: 80,
          })
        );
      }
    }
    return inner;
  }, [reportCalculated, filter.group_by]);

  return <Table data={reportCalculated || []} columns={columns} showSkeleton={isLoading} />;
};
