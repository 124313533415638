import { useTranslation } from "react-i18next";
import useToasts from "../../hooks/use-toasts.hook.tsx";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { ApiEmployeeAccountSettingsSavePayload } from "../../types/employee.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import Button from "../../ui/button/Button.tsx";
import Select from "../../ui/select/Select.tsx";
import { LanguageOptions, Languages } from "../../types/organization.types.ts";
import getTimezones from "../../services/timezone-service.ts";
import { Page } from "../../components/page/Page.tsx";
import { useThemeStore } from "../../stores/theme.store.ts";
import useModals from "../../ui/modal/modal.store.ts";
import { ChangePasswordModal } from "../security/widgets/ChangePasswordModal.tsx";
import Text from "../../ui/typography/Text.tsx";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import * as yup from "yup";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { useEmailDisconnect, useIntegrations } from "../../queries/use-emails.query.ts";
import { ListItem } from "../../components/list-item/ListItem.tsx";
import ButtonLink from "../../ui/button/ButtonLink.tsx";
import { apiEmails } from "../../api/email.api.ts";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";
import { SelectItem } from "../../ui/select/select.types.ts";
import {
  useEmployeeMe,
  useEmployeeResetConfirmEmail,
  useEmployeeUpdateAccountSettings,
} from "../../queries/use-employees.query.ts";
import { useEffectOnce } from "react-use";
import { Title } from "../../components/title/Title.tsx";

const AccountSettingsSchemaObject = yup.object({
  language: yup.mixed<Languages>().oneOf(Object.values(Languages)),
  timezone: yup.string(),
});

export interface AccountSettingsSchema extends yup.InferType<typeof AccountSettingsSchemaObject> {}

export const AccountSettings = () => {
  const { t } = useTranslation();
  const { showInfo } = useToasts();
  const { handleErrors } = useErrorHandle();
  const modalsService = useModals();
  const { data: integrations } = useIntegrations();
  const emailDisconnect = useEmailDisconnect();
  const { renderDateAndTime } = useFormatter();

  const { data: employee } = useEmployeeMe();

  const resetConfirmEmail = useEmployeeResetConfirmEmail();
  const updateAccountSettings = useEmployeeUpdateAccountSettings();

  const handleResendVerificationEmail = async () => {
    resetConfirmEmail.mutate(undefined, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.pageAction, {
          [analyticProperties.actionType]: "Email Confirmation Sent",
        });
        showInfo(
          t("Email Confirmation Sent! Check your inbox at {email} to confirm", {
            email: employee?.email,
          })
        );
      },
    });
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountSettingsSchema>({
    mode: "onSubmit",
    resolver: yupResolver(AccountSettingsSchemaObject),
  });

  useEffect(() => {
    if (employee) reset(employee);
  }, [employee]);

  const onSubmit = handleSubmit(async (settingsData) => {
    const payload: ApiEmployeeAccountSettingsSavePayload = {
      language: settingsData.language,
      timezone: settingsData.timezone,
    };

    updateAccountSettings.mutate(payload, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.employees.accountSettingsEdited);
        showInfo(t("Your changes were saved"));
      },
    });
  });
  const [isConnecting, setIsConnecting] = useState(false);
  const handleEmailConnect = useCallback(async () => {
    analyticsService.trackEvent(analyticEvents.pageAction, {
      [analyticProperties.actionType]: "Connect Email Started",
      [analyticProperties.source]: "Gmail",
    });

    setIsConnecting(true);
    const { data, error } = await apiEmails.getIntegrationLoginUrl();
    setIsConnecting(false);
    if (data) {
      window.location.href = data.authorization_url;
    } else {
      handleErrors(error);
    }
  }, []);

  const handleEmailDisconnect = useCallback(async (email: string) => {
    emailDisconnect.mutate(email, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.email.disconnected, {
          [analyticProperties.id]: email,
        });
        showInfo(t("Account disconnected successfully"));
      },
    });
  }, []);

  const { theme, setTheme } = useThemeStore();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.accountSettingsViewed);
  });

  const availableOptions = useMemo(() => {
    if (employee)
      return LanguageOptions.filter((option) =>
        employee.available_languages.some((language) => language == option.id)
      );
    return [];
  }, [employee]);

  const availableThemes = useMemo(() => {
    return [
      { id: null, title: "Default" },
      { id: "neo", title: "Hoth" },
      { id: "skyline", title: "CMYK" },
      { id: "max", title: "MAX" },
    ] as SelectItem[];
  }, []);

  return (
    <>
      <Page>
        <Page.Header title={t("Account Settings")} />
        <Page.Content>
          <Stack className={"container-sm mx-auto"}>
            <Stack className={"h-[244px]"} items={"center"} justify={"center"}>
              <Avatar url={employee?.avatar_url} size={"2xl"} />
            </Stack>
            <ListItem
              preTitle={t("Account email")}
              title={employee?.email}
              captionSlot={
                employee &&
                !employee.email_confirmed && (
                  <Text className={"text-warning"}>{t("Email not confirmed")}</Text>
                )
              }
              valueSlot={
                employee &&
                !employee.email_confirmed && (
                  <ButtonLink
                    onClick={handleResendVerificationEmail}
                    isLoading={resetConfirmEmail.isPending}
                  >
                    {t("Resend verification email")}
                  </ButtonLink>
                )
              }
            />
            <ListItem
              preTitle={t("Password")}
              title={"**********"}
              valueSlot={
                <ButtonLink
                  onClick={() => {
                    modalsService.openModal(ChangePasswordModal, {});
                  }}
                >
                  {t("Change password")}
                </ButtonLink>
              }
            />
            <Title header={t("General")} paddingBottom paddingTop />

            <form onSubmit={onSubmit}>
              <Controller
                control={control}
                name="language"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Language")}
                    options={availableOptions}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    error={errors.language?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="timezone"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("Timezone")}
                    options={getTimezones()}
                    emptyTitle={t("Select")}
                    value={value}
                    onChange={onChange}
                    searchable
                    error={errors.timezone?.message}
                  />
                )}
              />
              <Select
                label={t("Theme")}
                options={availableThemes}
                emptyTitle={t("Select")}
                value={theme}
                onChange={(value) => {
                  setTheme(value as string);
                }}
              />
            </form>

            <Title header={t("Connected emails")} paddingBottom paddingTop />

            {integrations && integrations.length > 0 ? (
              <>
                {integrations.map((integration) => (
                  <ListItem
                    key={`integration-email-${integration.email}`}
                    title={integration.email}
                    caption={`${t("Gmail account")}${t("Last synced")}: ${renderDateAndTime(integration.datetime_last_check)}`}
                    valueSlot={
                      <ButtonLink
                        onClick={() => {
                          handleEmailDisconnect(integration.email);
                        }}
                      >
                        {t("Disconnect")}
                      </ButtonLink>
                    }
                  />
                ))}
                <Button
                  variant="secondary"
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                  className={"mt-4"}
                  onClick={handleEmailConnect}
                >
                  {t("Connect")}
                </Button>
              </>
            ) : (
              <ListItem
                title={t("Gmail account")}
                captionSlot={
                  <Text className={"font-semibold text-warning"}>{t("Not connected")}</Text>
                }
                valueSlot={
                  <ButtonLink onClick={handleEmailConnect} disabled={isConnecting}>
                    {t("Connect")}
                  </ButtonLink>
                }
              />
            )}
            <Stack className={"pt-10"}>
              <ServerErrorField errors={errors} />
              <Button onClick={onSubmit} size={"lg"} isLoading={updateAccountSettings.isPending}>
                {t("Save")}
              </Button>
            </Stack>
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
};
